import React from 'react'
import HeaderTitleActionComponent from '../../Components/Component.HeaderTitleAction/HeaderTitleActionComponent'
import Offset from '../../View/Offset'
import WrapContainerBlockBorder from '../../View/Blocks/WrapContainerBlockBorder'
import WrapContainer from '../../View/WrapContainer/WrapContainer'
import WrapTwoColumnGrid from '../../View/Blocks/WrapTwoColumnGrid'
import WrapIconBlock from '../../View/Blocks/WrapIconBlock'
import Icon from '../../View/Icon/Icon'
import { arrowRightRed, attention, penRed, subscribe } from '../../images'
import BlockTitle from '../../View/Blocks/BlockTitle'
import WrapTitleDescBlockOpacity from '../../View/Blocks/WrapTitleDescBlockOpacity'
import WrapRowGrid from '../../View/Blocks/WrapRowGrid'
import Line from '../../View/Line/Line'
import Button from '../../View/Button/Button'
import InfoIconBlock from '../../View/InfoBlock/InfoIconBlock'
import InfoTextBlock from '../../View/InfoBlock/InfoTextBlock'
import InfoContainer from '../../View/InfoBlock/InfoContainer'
import CheckBox from '../../View/CheckBox'
import WrapContainerBlock from '../../View/Blocks/WrapContainerBlock'
import MainFeedbackComponent from '../FeedbackPage/FeedbackPage'
import { PROFILE_EDIT, PROFILE_INFO, PROFILE_SUBSCRIBE, linksFeedback } from '../../helpers/config'
import Menu from '../../View/Menu/Menu'
import Label from '../../View/Label/Label'
import { getDataInfoWhereWorkUser, selectLinkFromText } from '../../helpers/helpers'
import UserName from '../../View/User/UserName'
import UserCity from '../../View/User/UserCity'
import UserPhone from '../../View/User/UserPhone'
import UserInfoLink from '../../View/User/UserInfoLink'


export default function Profile({
  listMenu,
  infoPayment,
  dataEarnings,
  statusPayment,
  handlerSetData,
  handlerCopyText,
  profileInfoData,
  handleChangeScreen,
  headerTitleUsefulInfo,
  handlerShowInfoEarnings,
  headerTitleInfoAboutProduct,
}) {
  console.log({ statusPayment });
  return (
    <WrapContainer>
      <Menu list={listMenu} iconSize={18} handlerScreen={handleChangeScreen} />
      <Offset mb={13} />
      <WrapContainerBlockBorder>
        <Offset mb={4} />
        <UserName profileInfoData={profileInfoData} />
        <Offset mb={12} />
        <Line />
        <Offset mb={15} />
        <WrapTwoColumnGrid
          style={{
            gridTemplateColumns: `35% 65%`,
          }}
        >
          <UserCity profileInfoData={profileInfoData} />
          <UserPhone profileInfoData={profileInfoData} />
        </WrapTwoColumnGrid>
        <Offset mb={8} />

        <UserInfoLink profileInfoData={profileInfoData} />

        

        <Offset mb={10} />
        <WrapRowGrid>
          <WrapTitleDescBlockOpacity>
            Доп. информация:
          </WrapTitleDescBlockOpacity>
          <BlockTitle style={{ fontWeight: 500 }}>
            {profileInfoData.add_info}
          </BlockTitle>
        </WrapRowGrid>
        <Offset mb={18} />

        <Button
          addClass={"button__edit-my-profile"}
          iconLeft={penRed}
          styleIconsLeft={{
            width: 12,
            height: 12,
          }}
          onClick={() => handleChangeScreen({ path: PROFILE_EDIT })}
        >
          Редактировать профиль
        </Button>
        
        <Offset mb={20} />
        {!!Object.keys(dataEarnings).length && (
          <>
            <BlockTitle style={{ whiteSpace: "pre-line" }}>
              <span
                dangerouslySetInnerHTML={{
                  __html: selectLinkFromText(dataEarnings.info.message),
                }}
              ></span>
            </BlockTitle>
            <WrapTwoColumnGrid
              style={{
                gridTemplateColumns: `80% 20%`,
                justifyItems: "start",
                gap: 10,
              }}
            >
              <BlockTitle>Количество приглашенных</BlockTitle>
              <WrapTitleDescBlockOpacity>
                {dataEarnings.count_invited}
              </WrapTitleDescBlockOpacity>
            </WrapTwoColumnGrid>
            <WrapTwoColumnGrid
              style={{
                gridTemplateColumns: `80% 20%`,
                justifyItems: "start",
                gap: 10,
              }}
            >
              <BlockTitle>Сумма</BlockTitle>
              <WrapTitleDescBlockOpacity>
                {dataEarnings.total_sum}
              </WrapTitleDescBlockOpacity>
            </WrapTwoColumnGrid>

            {dataEarnings.link}
            <Button
              addClass={"button__copy-text"}
              // iconLeft={penRed}
              onClick={() => handlerCopyText(dataEarnings.link)}
            >
              Скопировть
            </Button>
            <Offset mb={20} />
          </>
        )}
        <Button
          addClass={"button__edit-my-profile"}
          // iconLeft={penRed}
          styleIconsLeft={{
            width: 12,
            height: 12,
          }}
          onClick={handlerShowInfoEarnings}
        >
          Зарабатывай
        </Button>
      </WrapContainerBlockBorder>
      <Offset mb={36} />
      <HeaderTitleActionComponent list={headerTitleInfoAboutProduct} />
      <Offset mb={14} />
      <WrapContainerBlockBorder>
        <WrapRowGrid>
          <Offset mb={6} />
          <WrapTitleDescBlockOpacity>Текущий доступ:</WrapTitleDescBlockOpacity>
          <BlockTitle style={{ fontWeight: 500 }}>
            {infoPayment.user_status}
          </BlockTitle>
          <Offset mb={3} />
          <WrapTitleDescBlockOpacity>Действует до:</WrapTitleDescBlockOpacity>
          <BlockTitle style={{ fontWeight: 500 }}>
            {infoPayment.user_date_end}
          </BlockTitle>
          <Offset mb={8} />
          <InfoContainer>
            <InfoIconBlock>
              <Icon width={14} height={14} image={attention} />
            </InfoIconBlock>
            <InfoTextBlock
              style={{ fontWeight: 500, color: "var(--text-color-red)" }}
            >
              {statusPayment
                ? "У вас подключен автоплатеж"
                : "У вас отключен автоплатеж"}
            </InfoTextBlock>
          </InfoContainer>
        </WrapRowGrid>
        <Offset mb={24} />

        <WrapTwoColumnGrid>
          <Button
            addClass={"button__controll--red"}
            onClick={() => handleChangeScreen({ path: PROFILE_SUBSCRIBE })}
            style={{
              justifyContent: "center",
              paddingTop: 4,
            }}
          >
            Продлить подписку
          </Button>
          {statusPayment && (
            <CheckBox
              checked={statusPayment}
              variant={"auto-payment"}
              onChange={(res) =>
                handlerSetData({ key: "auto_payment", value: false })
              }
              name={"payment"}
              helptext={"Автоплатеж"}
              helpTextStyle={{
                fontSize: 14,
                fontWeight: 500,
                lineHeight: "20px",
              }}
            />
          )}
        </WrapTwoColumnGrid>
      </WrapContainerBlockBorder>
      <Offset mb={34} />
      <HeaderTitleActionComponent list={headerTitleUsefulInfo} />
      <WrapContainerBlock>
        <Offset mb={18} />
        <WrapContainerBlockBorder
          onClick={() => {}}
          style={{
            padding: `13px 15px`,
          }}
        >
          <WrapTwoColumnGrid
            style={{
              gridTemplateColumns: `95% 5%`,
            }}
            onClick={() => handleChangeScreen({ path: PROFILE_INFO })}
          >
            <BlockTitle style={{ fontWeight: 500 }}>
              Инструкция по работе с ботом
            </BlockTitle>
            <WrapIconBlock>
              <Icon width={14} height={14} image={arrowRightRed} />
            </WrapIconBlock>
          </WrapTwoColumnGrid>
        </WrapContainerBlockBorder>
        <Offset mb={8} />
        <WrapContainerBlockBorder
          onClick={() => {}}
          style={{
            padding: `13px 15px`,
          }}
        >
          <WrapTwoColumnGrid
            style={{
              gridTemplateColumns: `95% 5%`,
            }}
            onClick={() =>
              handleChangeScreen({
                url: "https://youtube.com/shorts/MBrA-iGZvWY?feature=share",
              })
            }
          >
            <BlockTitle style={{ fontWeight: 500 }}>
              Промо-ролик для друзей
            </BlockTitle>
            <WrapIconBlock>
              <Icon width={14} height={14} image={arrowRightRed} />
            </WrapIconBlock>
          </WrapTwoColumnGrid>
        </WrapContainerBlockBorder>
        <Offset mb={8} />
        <WrapContainerBlockBorder
          onClick={() => {}}
          style={{
            padding: `13px 15px`,
          }}
        >
          <WrapTwoColumnGrid
            style={{
              gridTemplateColumns: `95% 5%`,
            }}
          >
            <BlockTitle style={{ fontWeight: 500 }}>
              Видео о верификации
            </BlockTitle>
            <WrapIconBlock>
              <Icon width={14} height={14} image={arrowRightRed} />
            </WrapIconBlock>
          </WrapTwoColumnGrid>
        </WrapContainerBlockBorder>
      </WrapContainerBlock>
      <Offset mb={24} />
      <MainFeedbackComponent list={linksFeedback} />
    </WrapContainer>
  );
}
