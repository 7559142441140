import React, { useState } from 'react'
import RaitingAndReviewWarrantlyForMembersComponent from '../../../Pages/RaitingAndReview/RaitingAndReviewWarrantlyForMembers/RaitingAndReviewWarrantlyForMembersComponent'

const WarrantMemberDesktopContainer = () => {
  const [ showFullInfo, setShowFullInfo ] = useState(null)
  const handleShowFullInfo = (id) => {
    if(showFullInfo === id) return setShowFullInfo(null)
    setShowFullInfo(id)
  }
  return (
    <RaitingAndReviewWarrantlyForMembersComponent
      isDesktop
      showFullInfo={showFullInfo}
      handleShowFullInfo={handleShowFullInfo}
    />
  );
}

export default WarrantMemberDesktopContainer