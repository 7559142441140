import React, { Component } from 'react'
import NotificationsDesktop from './NotificationsDesktop';
import { connectStoreon } from 'storeon/react';
import WithRouter from '../../HOC/WithRouter';
import { GET_NOTIFICATION } from '../../store/notifications/notifications';
  
  export class NotificationsDesktopContainer extends Component {
    state = {
      showDesktopButton: false,
    };
    componentDidMount() {
      this.props.dispatch(GET_NOTIFICATION);
    }

    handleShowMore = () => {
      return this.props.dispatch(GET_NOTIFICATION, {
        page: this.props.notifications.current_page + 1,
      });
    };

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.notifications !== this.props.notifications) {
        if (
          this.props.notifications?.current_page ===
          this.props.notifications?.count_pages
        ) {
          this.setState((state) => ({
            ...state,
            showDesktopButton: false,
          }));
        } else {
          this.setState((state) => ({
            ...state,
            showDesktopButton: true,
          }));
        }
      }
    }

    render() {
      console.log(this.props.notifications);
      return (
        <NotificationsDesktop
          listNotice={this.props.notifications}
          handleShowMore={this.handleShowMore}
          showDesktopButton={this.state.showDesktopButton}
        />
      );
    }
  }

export default connectStoreon(
  'notifications',
  WithRouter(NotificationsDesktopContainer));