import React from "react";
import WrapContainer from "../../../View/WrapContainer/WrapContainer";
import Offset from "../../../View/Offset";
import BlockGrid from "../../../View/Blocks/BlockGrid";
import FormInputAddfileTabs from "../../../View/CreteCardMarket/Detail/FormInputAddfileTabs";
import { Formik } from "formik";
import { createMarket } from "../../../helpers/schemaValidations/schemaValidate";
import { messageErrorValidation } from "../../../helpers/schemaValidations/messgeSchemaValidations";
import FormInputCommonInfoTabs from "../../../View/CreteCardMarket/Detail/FormInputCommonInfoTabs";
import FormInputAboutTCDescTabs from "../../../View/CreteCardMarket/Detail/FormInputAboutTCDescTabs";

import { checkEmptyDataObject, resetDataForm } from "../../../helpers/utils";
import { useStoreon } from "storeon/react";
import FormInputAboutTCTabs from "../../../View/CreteCardMarket/Detail/FormInputAboutTCTabs";
import WrapContainerPreloader from "../../../View/Preloaders/WrapContainerPreloader";
import Preloader from "../../../View/Preloaders/Preloader";
import ButtonApplyContainer from "../../../Components/ButtonApplySection/ButtonApplyContainer";

const EditCardDesktop = ({
  isEdit,
  citys,
  brands,
  models,
  values,
  countrys,
  action_tab,
  generations,
  listSectionTabs,
  handlerDeleteImage,
  listOptionsCategory,
  handlerChangeSection,
  handlerChangeOptionsData,
}) => {
  const { dispatch } = useStoreon();
  if (isEdit && checkEmptyDataObject(values))
    return (
      <WrapContainerPreloader>
        Загрузка ... <Preloader />
        <Offset mb={30} />
      </WrapContainerPreloader>
    );
  return (
    <WrapContainer isDesktop>
      <Offset mt={"var(--offset-top-desktop)"} />
      {/* Карточка объявления */}
      <Formik
        initialValues={values}
        validationSchema={createMarket(messageErrorValidation)}
        handleChange={handlerChangeOptionsData}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          values,
          errors,
          resetForm,
          handleSubmit,
          touched,
          validateField,
          handleBlur,
          handleReset,
          setFieldValue,
          setFieldError,
        }) => {
          return (
            <>
              <BlockGrid addClass="profile-desktop__self-info">
                <FormInputAddfileTabs
                  values={values}
                  handlerDeleteImage={handlerDeleteImage}
                  handlerChangeOptionsData={handlerChangeOptionsData}
                  setFieldError={setFieldError}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              </BlockGrid>
              <Offset mt={"var(--offset-top-desktop)"} />
              <BlockGrid addClass="profile-desktop__self-info">
                <FormInputCommonInfoTabs // Общяя инф
                  isDesktop
                  isEdit
                  citys={citys}
                  countrys={countrys}
                  handlerChangeOptionsData={handlerChangeOptionsData}
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleSubmit={handleSubmit}
                  setFieldValue={setFieldValue}
                  resetDataForm={resetDataForm}
                  dispatch={dispatch}
                />
              </BlockGrid>
              <Offset mt={"var(--offset-top-desktop)"} />
              <BlockGrid addClass="profile-desktop__self-info">
                <FormInputAboutTCTabs //Применимость
                  isDesktop
                  isEdit
                  brands={brands}
                  models={models}
                  generations={generations}
                  listOptionsCategory={listOptionsCategory}
                  handlerChangeOptionsData={handlerChangeOptionsData}
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleSubmit={handleSubmit}
                  setFieldValue={setFieldValue}
                  resetDataForm={resetDataForm}
                />
              </BlockGrid>
              <Offset mt={"var(--offset-top-desktop)"} />
              <BlockGrid addClass="profile-desktop__self-info">
                <FormInputAboutTCDescTabs // Подробности
                  isDesktop
                  isEdit
                  handlerChangeOptionsData={handlerChangeOptionsData}
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleSubmit={handleSubmit}
                  setFieldValue={setFieldValue}
                  resetDataForm={resetDataForm}
                  dispatch={dispatch}
                />
              </BlockGrid>
              <ButtonApplyContainer isDesktop />      
            </>
          );
        }}
      </Formik>

    </WrapContainer>
  );
};

export default EditCardDesktop;
