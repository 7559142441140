import React from 'react'
import WrapRowGrid from '../Blocks/WrapRowGrid';
import BlockTitle from '../Blocks/BlockTitle';
import WrapTitleDescBlockOpacity from '../Blocks/WrapTitleDescBlockOpacity';

const UserPhone = ({ profileInfoData, style={} }) => {
  return (
    <WrapRowGrid style={style}>
      <WrapTitleDescBlockOpacity>Ваш телефон:</WrapTitleDescBlockOpacity>
      <BlockTitle style={{ fontWeight: 500, minHeight: 20 }}>
        {profileInfoData.phone}
      </BlockTitle>
    </WrapRowGrid>
  );
};

export default UserPhone