import React, { Component } from 'react'
import MenuDesktop from './MenuDesktop'
import { MAKE_REQUEST_MENU, MAKE_REQUEST_OWN_REQUEST, menuDesktop } from '../../../helpers/config';
import { connectStoreon } from 'storeon/react';
import { ACTION_SET_TEXT_HEADER } from '../../../store/helpers/helpers-store';
import { fileFailedOne, myRequest, plus } from '../../../images';
import WithRouter from '../../../HOC/WithRouter';

export class MenuDesktopContainer extends Component {
  state = {
    menu: menuDesktop,
    buttons: [
      {
        action: () => this.handleChangeScreen({ path: MAKE_REQUEST_MENU }),
        iconLeft: fileFailedOne,
        styleIconsLeft: { height: 12 },
        title: "Создать запрос",
        className: "button__controll--red",
      },
    ],
  };
  handlerClickMenu = (id) => {
    this.setState((state) => ({
      ...state,
      menu: state.menu.map((item) =>
        item.id === id
          ? { ...item, isActive: true }
          : { ...item, isActive: false }
      ),
    }));
    this.props.dispatch(
      ACTION_SET_TEXT_HEADER,
      this.state.menu.filter((el) => el.id === id)[0]?.name
    );
  };
  handleChangeScreen = ({path}) => {
    this.props.navigate(path)
  }
  render() {
    return (
      <MenuDesktop
        list={this.state.menu}
        handlerClickMenu={this.handlerClickMenu}
        buttonsSidebar={{ buttons: this.state.buttons }}
      />
    );
  }
}

export default connectStoreon( 
 WithRouter( MenuDesktopContainer))