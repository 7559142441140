import React from "react";
import { ReactSVG } from "react-svg";
import { logo } from "../../../images";
import BlockFlex from "../../../View/Blocks/BlockFlex";

const LogoDesktop = () => {
  return (
    <BlockFlex addClass="block__logo-container">
      <ReactSVG src={logo} />
    </BlockFlex>
  );
};

export default LogoDesktop;
