import React from 'react'
import WithRouter from '../../HOC/WithRouter';
import BlockGrid from '../../View/Blocks/BlockGrid';
import { Outlet } from 'react-router-dom';
import MenuDesktopContainer from "../../Components/Desktop/MenuDesktop/MenuDesktopContainer";
import LogoDesktop from '../../Components/Desktop/LogoDesktop/LogoDesktop';
import HeaderDesktopContainer from '../Header/HeaderDesktopContainer';

const LayouDesktop = () => {
  return (
    <BlockGrid addClass='block__layout-container'>
      
      <BlockGrid addClass='block__layout-column-sidebar'>
        <LogoDesktop />
        <MenuDesktopContainer />
      </BlockGrid>

      <BlockGrid addClass='block__layout-column-main'>
        <HeaderDesktopContainer />
        
        <Outlet/>
      </BlockGrid>

    </BlockGrid>
  )
}

export default WithRouter(LayouDesktop);