import React from "react";
import WrapContainer from "../../View/WrapContainer/WrapContainer";
import Offset from "../../View/Offset";
import BlockGrid from "../../View/Blocks/BlockGrid";
import TextTitle from "../../View/Text/TextTitle";
import ViewsImage from "../../View/ViewsImage";
import Line from "../../View/Line/Line";
import { selectLinkFromText } from "../../helpers/helpers";
import WrapContainerPreloader from "../../View/Preloaders/WrapContainerPreloader";
import Preloader from "../../View/Preloaders/Preloader";
import ButtonDesktop from "../../View/ButtonDesktop/ButtonDesktop";


const NotificationsDesktop = ({ 
  listNotice,
  handleShowMore,
showDesktopButton,
 }) => {
if (!listNotice?.results && !listNotice?.results?.length)
  return (
    <WrapContainerPreloader>
      Загрузка ... <Preloader />
    </WrapContainerPreloader>
  );
  return (
    <WrapContainer isDesktop>
      <Offset mt={"var(--offset-top-desktop)"} />
      <BlockGrid addClass="profile-desktop__self-info">
        <TextTitle style={{ fontWeight: 700 }}>Уведомления</TextTitle>
        <BlockGrid
          style={{
            gridTemplateColumns: "1fr",
          }}
        >
          {!!listNotice.results &&
            listNotice.results.map((item, index) => (
              <>
                <BlockGrid
                  key={index}
                  style={{
                    gridTemplateColumns: item?.image
                      ? "1fr 1fr 1fr"
                      : " 1fr 1fr",
                    gridTemplateAreas: item?.image
                      ? `
                    'images . date'
                    'images desc desc'
                    'images desc desc'
                    `
                      : `
                    '. date'
                    'desc desc'
                    'desc desc'
                    `,
                    gap: 10,
                    marginBottom: 30,
                  }}
                >
                  {item?.image && (
                    <BlockGrid style={{ gridArea: "images" }}>
                      <ViewsImage images={[item.image]} />
                    </BlockGrid>
                  )}
                  <BlockGrid
                    style={{
                      gridArea: "date",
                      justifyContent: "end",
                      opacity: ".8",
                    }}
                  >
                    <TextTitle>{item.date_create}</TextTitle>
                  </BlockGrid>
                  <BlockGrid style={{ gridArea: "desc" }}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: selectLinkFromText( item.text),
                      }}
                    ></span>
                  </BlockGrid>
                </BlockGrid>
                <Line />
                <Offset mt={30} />
              </>
            ))}
        </BlockGrid>
          {showDesktopButton && <Offset mt={30} />}

          <ButtonDesktop
            show={showDesktopButton}
            isActive={showDesktopButton}
            type={"button"}
            name={"Загрузить еще"}
            addClass={"button__controll-roze--full"}
            onClick={handleShowMore}
          />
      </BlockGrid>
    </WrapContainer>
  );
};

export default NotificationsDesktop;
