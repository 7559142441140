import React from "react";
import WrapContainer from "../../../View/WrapContainer/WrapContainer";
import Offset from "../../../View/Offset";
import AddFileMarketPlace from "../../../Pages/MarketPlace/AddFileMarketPlace/AddFileMarketPlace";
import AddLinkMarketPlace from "../../../Pages/MarketPlace/AddFileMarketPlace/AddLinkMarketPlace";
import BlockGrid from "../../../View/Blocks/BlockGrid";
import ButtonApplyContainer from "../../../Components/ButtonApplySection/ButtonApplyContainer";
import InfoBlockContainer from "../../../Components/Component.Info/InfoBlockContainer";
import HeaderTitleActionComponent from "../../../Components/Component.HeaderTitleAction/HeaderTitleActionComponent";
import ShowPreviewImages from "../../../View/UploadImage/Detail/ShowPreviewImages";

const CreateFileDesktop = ({
  values,
  isMark,
  setData,
  handlerScreen,
  valuesPreview,
  loadingPreviewImage,

  tg,
  testSize,
  dispatch,
  listSubMenu,
  handlerSendData,
  handleGetDataOption,
  handlerDeleteFile,
  handlerDeleteLink,
  handlerChangeBlur,
  handlerChangeFocus,
  percentageAddFileMP,
}) => {
  return (
    <WrapContainer isDesktop style={{ height: "max-content" }}>
      <Offset mt={"var(--offset-top-desktop)"} />
      <BlockGrid addClass="profile-desktop__self-info">
        <InfoBlockContainer
          // •  Размер не должен превышать 50 Mb
          message={`Создайте объявление вручную или загрузите выгруженный файл из стороннего сервиса.
•  Формат загружаемого файла должен быть XML (формат как на Авито)

При автозагрузке будет автоматически применена 5% скидка на товары для участников сообщества`}
          style={{
            padding: "9px 12px 7px",
          }}
          styleText={{
            textAlign: "start",
          }}
        >
          <div
            style={{
              padding: "12px 0 0px",
              textAlign: "start",
              fontSize: 12,
            }}
          >
            По всем вопросам к{" "}
            <a href="https://t.me/admrazborov">@admrazborov</a>
          </div>
          <div
            style={{
              padding: "12px 0 0px",
              textAlign: "start",
              fontSize: 12,
            }}
          >
            Обращаем ваше внимание, автоудаление карточек происходит через 7
            дней с момента загрузки файла или последнего изменения в ссылке.
          </div>
        </InfoBlockContainer>

        <Offset mt={"var(--offset-top-desktop)"} />

        {valuesPreview.file_name && valuesPreview?.idFiles ? (
          <BlockGrid addClass="profile-desktop__self-info">
            <HeaderTitleActionComponent list={["Загруженное файлом"]} />
            <Offset mb={10} />
            <ShowPreviewImages
              preview={[
                {
                  file_name: valuesPreview["file_name"],
                  id: valuesPreview.idFiles,
                  type: valuesPreview.type,
                },
              ]}
              isFileName
              deleteImage={
                valuesPreview.type === "link"
                  ? handlerDeleteLink
                  : handlerDeleteFile
              }
              loadingPreviewImage={loadingPreviewImage}
            />
            {valuesPreview?.cards_count_file ? (
              <>
                <Offset mt={20} />
                <span> Карточек: {valuesPreview?.cards_count_file}</span>
              </>
            ) : null}
          </BlockGrid>
        ) : null}

        <Offset mt={"var(--offset-top-desktop)"} />

        {!values?.urlMarket && !valuesPreview?.urlMarket ? (
          <AddFileMarketPlace
            tg={tg}
            values={values}
            setData={setData}
            dispatch={dispatch}
            percentage={percentageAddFileMP}
            valuesPreview={valuesPreview}
            handleGetDataOption={handleGetDataOption}
            handlerApplyChange={handlerSendData}
            handlerDeleteFile={handlerDeleteFile}
            loadingPreviewImage={loadingPreviewImage}
          />
        ) : null}

        {!values?.file?.length && !valuesPreview?.file?.length ? (
          <AddLinkMarketPlace
            values={values}
            valuesPreview={valuesPreview}
            handleGetDataOption={handleGetDataOption}
            handlerDeleteLink={handlerDeleteLink}
            handlerChangeBlur={handlerChangeBlur}
            handlerChangeFocus={handlerChangeFocus}
            loadingPreviewImage={loadingPreviewImage}
          />
        ) : null}
        <Offset mb={30} />
        <ButtonApplyContainer isDesktop />
      </BlockGrid>
    </WrapContainer>
  );
};

export default CreateFileDesktop;
