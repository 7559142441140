import React, { Component } from "react";
import { connectStoreon } from "storeon/react";
import WithRouter from "../../HOC/WithRouter";
import Profile from "./Profile";
import { PROFILE_SUBSCRIBE, ROOT, menuProfile } from "../../helpers/config";
import {
  ACTION_GET_DATA_EAERNINGS,
  ACTION_GET_DATA_INFO_PROFILE,
  ACTION_GET_DATA_PROFILE,
  ACTION_SET_DATA_FOR_CHANGE_PROFILE_NULL,
  ACTION_SET_DATA_FOR_SUBSCRIBE,
} from "../../store/profile/profile";
import {
  ACTION_OPEN_MODAL,
  ACTION_SET_BUTTON_HEADER_ACTION,
} from "../../store/helpers/helpers-store";
import { openOnlyURl } from "../../helpers/helpers";
import ProfileDesktop from "../../PagesDesktop/Profile/ProfileDesktop";

class ProfileContainer extends Component {
  state = {
    headerTitleUsefulInfo: ["Полезная информация"],
    headerTitleInfoAboutProduct: ["Информация о подписке"],
  };
  componentDidMount() {
    this.props.controllerHeaderBand({
      currentTextHandlerBand: "Профиль",
      pathBackButton: ROOT,
    });

    this.props.dispatch(ACTION_SET_BUTTON_HEADER_ACTION, {
      isVisible: true,
      buttons: [
        {
          action: () => this.handleChangeScreen({ path: PROFILE_SUBSCRIBE }),
          styleIconsLeft: { height: 12 },
          title: "Продлить подписку",
          className: "button__controll--red",
        },
      ],
    });
    this.props.dispatch(ACTION_GET_DATA_PROFILE);
    this.props.dispatch(ACTION_GET_DATA_INFO_PROFILE);
  }

  componentWillUnmount() {
    this.props.dispatch(ACTION_SET_DATA_FOR_CHANGE_PROFILE_NULL);
  }
  handlerSetData = ({ key, value }) =>
    this.props.dispatch(ACTION_SET_DATA_FOR_SUBSCRIBE, { [key]: value });

  handleChangeScreen = ({ path, url }) => {
    if (url) {
      return openOnlyURl(url);
    }
    this.props.navigate(path);
  };

  handlerShowInfoEarnings = () => {
    this.props.dispatch(ACTION_GET_DATA_EAERNINGS);
  };

  handlerCopyText = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      this.props.dispatch(ACTION_OPEN_MODAL, {
        show: true,
        content: "Ссылка скопирована в буфер обмена!",
        type: "success",
      });
    } catch (error) {
      this.props.dispatch(ACTION_OPEN_MODAL, {
        show: true,
        content: "Ошибка при копировании ссылки: " + error.message,
        type: "error",
      });

      console.error("Ошибка при копировании текста: ", error.message);
    }
  };

  render() {
    if(this.props.isDesktop){
      return(
        <ProfileDesktop
          listMenu={menuProfile}
          infoPayment={this.props.descriptionProfileUser}
          isDesktop={this.props.isDesktop}
          dataEarnings={this.props.dataEarnings}
          handlerCopyText={this.handlerCopyText}
          statusPayment={this.props.statusAutopaymentProfileUser}
          handlerSetData={this.handlerSetData}
          profileInfoData={this.props.profileInfoData}
          handleChangeScreen={this.handleChangeScreen}
          headerTitleUsefulInfo={this.state.headerTitleUsefulInfo}
          handlerShowInfoEarnings={this.handlerShowInfoEarnings}
          headerTitleInfoAboutProduct={this.state.headerTitleInfoAboutProduct}
        />
      )
    }
    return (
     
        <Profile
          listMenu={menuProfile}
          infoPayment={this.props.descriptionProfileUser}
          isDesktop={this.props.isDesktop}
          dataEarnings={this.props.dataEarnings}
          handlerCopyText={this.handlerCopyText}
          statusPayment={this.props.statusAutopaymentProfileUser}
          handlerSetData={this.handlerSetData}
          profileInfoData={this.props.profileInfoData}
          handleChangeScreen={this.handleChangeScreen}
          headerTitleUsefulInfo={this.state.headerTitleUsefulInfo}
          handlerShowInfoEarnings={this.handlerShowInfoEarnings}
          headerTitleInfoAboutProduct={this.state.headerTitleInfoAboutProduct}
        />
    );
  }
}

export default connectStoreon(
  "dataEarnings",
  "profileInfoData",
  "listOptionsPayment",
  "descriptionProfileUser",
  "statusAutopaymentProfileUser",
  "listDescriptionAccessPayment",

  WithRouter(ProfileContainer)
);
