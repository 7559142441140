import React from 'react'
import BlockGrid from '../../View/Blocks/BlockGrid'
import BlockFlex from '../../View/Blocks/BlockFlex'
import Icon from '../../View/Icon/Icon'
import { arrowDown } from '../../images'
import TextTitle from '../../View/Text/TextTitle'
import ButtonApplyContainer from '../../Components/ButtonApplySection/ButtonApplyContainer'
import IncomingRequestTransportComponent from '../../Pages/IncominRequests/IncomingRequestTransport/IncomingRequestTransportComponent'
import IncomingRequestUnitSpareDesktopContainer from './IncomingRequestUnitSpareDesktop/IncomingRequestUnitSpareDesktopContainer'
import IncomingRequestRespairContainerDesktop from './IncomingRequestRespairDesktop/IncomingRequestRespairContainerDesktop'
import Offset from '../../View/Offset'
import IncomingRequestTruck from '../../Pages/IncominRequests/IncomingRequestTruck/IncomingRequestTruck'
import IncomingRequestTruckComponent from '../../Pages/IncominRequests/IncomingRequestTruck/IncomingRequestTruckComponent'


const IncomingRequests = ({ listMenu, handlerChangeItemMenu }) => (
  <BlockGrid addClass="block__requests-desktop-menu-container">
    {listMenu.map((item, index) => (
      <BlockFlex
        key={item.id}
        addClass="block__requests-desktop-container-item"
      >
        <BlockFlex
          addClass={
            item.isActive
              ? `block__requests-desktop-menu-item-container--active`
              : "block__requests-desktop-menu-item-container"
          }
          onClick={() => handlerChangeItemMenu(item.id)}
        >
          <TextTitle type="h5" addClass="text__request-desktop-menu-item-title">
            {item.name}
          </TextTitle>
          <Icon image={arrowDown} style={{ height: 8, top: 2 }} />
        </BlockFlex>
        {item.isActive && item.id === 0 && (
          <BlockGrid addClass="block__requests-desktop-menu-item-sub-container" >
            <IncomingRequestUnitSpareDesktopContainer isDesktop />
          </BlockGrid>
        )}
        {item.isActive && item.id === 1 && (
          <BlockGrid addClass="block__requests-desktop-menu-item-sub-container">
            <IncomingRequestTransportComponent isDesktop />
          </BlockGrid>
        )}
        {item.isActive && item.id === 2 && (
          <BlockGrid addClass="block__requests-desktop-menu-item-sub-container">
            <IncomingRequestRespairContainerDesktop isDesktop />
          </BlockGrid>
        )}
        {item.isActive && item.id === 3 && (
          <BlockGrid addClass="block__requests-desktop-menu-item-sub-container">
            <IncomingRequestTruckComponent isDesktop />
          </BlockGrid>
        )}
      </BlockFlex>
    ))}
    <Offset mt={30} />
    <ButtonApplyContainer isDesktop />
  </BlockGrid>
);

export default IncomingRequests;