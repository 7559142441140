import React from "react";

import ReactDOM from 'react-dom/client';
import './styles/global.scss';
import registrationServiceWorker from './registerSw';
import { StoreContext } from 'storeon/react';
import { store } from './store';
import ErrorHandler from "./Pages/ErrorHandler/ErrorHandler";
import WithTelegram from "./HOC/WithTelegram";
import WithResize from "./HOC/WithResize";

const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  <ErrorHandler>
    <WithTelegram>
        <StoreContext.Provider value={store}>
          <WithResize />
        </StoreContext.Provider>
    </WithTelegram>
  </ErrorHandler>
);

registrationServiceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
