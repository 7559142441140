import React, { Component } from "react";
import RecomandationsMarketplace from "./RecomandationsMarketplace";
import { connectStoreon } from "storeon/react";
import { GET_RECOMANDATION_MARKETPLACE } from "../../store/marketplace/recomandation/recomandation";

export class RecomandationsMarketplaceContainer extends Component {
  componentDidMount() {
    this.props.dispatch(GET_RECOMANDATION_MARKETPLACE);
  }
  render() {
    return (
      <RecomandationsMarketplace
        dispatch={this.props.dispatch}
        slidesPerView={this.props.slidesPerView}
        recomandations={this.props.recomandations}
      />
    );
  }
}

export default connectStoreon(
  "recomandations",
  RecomandationsMarketplaceContainer
);
