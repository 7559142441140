import React, { Component } from "react";
import { connectStoreon } from "storeon/react";
import WithRouter from "../../../HOC/WithRouter";
import { myRequest } from "../../../images";
import RespairRequest from "./RespairRequest";
import {
  MAKE_REQUEST_MENU,
  MAKE_REQUEST_OWN_REQUEST,
} from "../../../helpers/config";
import {
  ACTION_GET_LIST_COUNTRY,
  ACTION_SET_VALUE_UNIT_SPARE,
  ACTION_SET_VALUE_UNIT_SPARE_NULL,
} from "../../../store/requests/requests";
import {
  ACTION_SET_BUTTON_HEADER_ACTION,
  ACTION_SET_CONTROLL_BUTTON,
} from "../../../store/helpers/helpers-store";

class RespairRequestComponent extends Component {
  state = {
    type: "respair",
    desc_action: "create-respaire",
  };

  componentDidMount() {
    this.props.dispatch(ACTION_GET_LIST_COUNTRY);

    this.props.controllerHeaderBand({
      currentTextHandlerBand: "ЗАПРОС НА РЕМОНТ",
      pathBackButton: () =>
        this.handleChangeScreen({ path: MAKE_REQUEST_MENU }),
    });

    this.props.dispatch(ACTION_SET_BUTTON_HEADER_ACTION, {
      isVisible: true,
      buttons: [
        {
          action: () =>
            this.handleChangeScreen({ path: MAKE_REQUEST_OWN_REQUEST }),
          iconLeft: myRequest,
          styleIconsLeft: { height: 12 },
          title: "Мои запросы",
          className: "button__controll--roze",
        },
      ],
    });
  }

  handleChangeScreen = ({ path }) => this.props.navigate(path);

  handleSetDataValues = (values) =>
    this.props.dispatch(ACTION_SET_VALUE_UNIT_SPARE, {
      activeScreen: this.state.type,
      desc_action: this.state.desc_action,
      callback: this.callback,
      ...values,
    });
  componentWillUnmount() {
    this.props.dispatch(ACTION_SET_VALUE_UNIT_SPARE_NULL);
  }

  callback = () => {
    this.props?.handlerChangeItemMenu && this.props.handlerChangeItemMenu(99999);
  };
  render() {
    return (
      <RespairRequest
        isDesktop={this.props.isDesktop}
        listCountries={this.props.listCountries}
        dispatch={this.props.dispatch}
        handleSetDataValues={this.handleSetDataValues}
        listSectionTabs={this.state.listSectionTabs}
        action_tab={this.state.action_tab}
      />
    );
  }
}
export default connectStoreon(
  "listCountries",
  WithRouter(RespairRequestComponent)
);
