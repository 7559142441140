import React from "react";
import styles from './styles/wrappercontainercards.module.scss';

const WrapContainerCards = ({
  children,
  style={},
}) => {

  return (
    <div
      className={styles['wrapper__container']}
      style={{
        ...style, 
      }}
    >
      {children}
    </div>
  )
}

export default WrapContainerCards;