import React from 'react'
import classNames from 'classnames';

import stylesH from './style/h.module.scss';
import styles from './style/text.module.scss';

const TextTitle = ({    
    style={},
    children,
    addClass='',
    className,
    type='',
    sub,
}) => {
    const classNameInit = classNames({
        [styles['text']]:true,
        [styles['text__title']]:true,
        [styles['text__sub-title']]:!!sub,
        [stylesH[type]]:!!type,
        [styles[addClass]]: !!addClass,
        [`${className}`]:!!className
    })
    switch (type) {
        case 'h1':
            return <h1 className={classNameInit} style={style}>{children}</h1>
        case 'h2':
            return <h2 className={classNameInit} style={style}>{children}</h2>
        case 'h3':
            return <h3 className={classNameInit} style={style}>{children}</h3>
        case 'h4':
            return <h4 className={classNameInit} style={style}>{children}</h4>
        case 'h5':
            return <h5 className={classNameInit} style={style}>{children}</h5>
        case 'h6':
            return <h6 className={classNameInit} style={style}>{children}</h6>
            
        default:
            return <p className={classNameInit} style={style}>{children}</p>
    } 
}

export default TextTitle