import React, { Component } from 'react'
import OwnRequestComponent from '../../../Pages/Requests/OwnRequest/OwnRequestComponent';

export class OwnRequestDesktopContainer extends Component {
  render() {
    return (
      <OwnRequestComponent 
        isDesktop
      />
    )
  }
}

export default OwnRequestDesktopContainer