import React from 'react'
import BlockFlex from '../../View/Blocks/BlockFlex'
import ArrowCamebackContainer from '../../Components/Desktop/ArrowCameback/ArrowCamebackContainer'
import TitleHeaderContainer from '../../Components/Desktop/TitleHeader/TitleHeaderContainer'
import HeaderButtonContainer from './HeaderButton/HeaderButtonContainer'
import Button from '../../View/Button/Button'
import Icon from '../../View/Icon/Icon'
import { shrink } from '../../images'

const HeaderDesktop = ({buttonsHeader, closeApp}) => {
  return (
    <BlockFlex addClass="block__header-container">
      <BlockFlex addClass="block__header-left">
        <ArrowCamebackContainer />
        <TitleHeaderContainer />
      </BlockFlex>
      <BlockFlex addClass="block__header-right">
        <Button onClick={() => closeApp()}>
          <Icon image={shrink} width={30} height={30}/>
        </Button>
        <HeaderButtonContainer />
        <HeaderButtonContainer buttonsHeader={buttonsHeader} />
      </BlockFlex>
    </BlockFlex>
  );
}

export default HeaderDesktop