import React, { Component } from "react";
import { connectStoreon } from "storeon/react";
import WithRouter from "../../../HOC/WithRouter";
import { myRequest } from "../../../images";
import TransportRequest from "./TransportRequest";
import {
  MAKE_REQUEST_MENU,
  MAKE_REQUEST_OWN_REQUEST,
} from "../../../helpers/config";
import {
  ACTION_GET_LIST_BRANDS,
  ACTION_GET_NEW_LIST_DATA,
  ACTION_SET_VALUE_UNIT_SPARE,
  ACTION_SET_VALUE_UNIT_SPARE_NULL,
} from "../../../store/requests/requests";
import { ACTION_SET_BUTTON_HEADER_ACTION, ACTION_SET_CONTROLL_BUTTON } from "../../../store/helpers/helpers-store";

class TransportRequestComponent extends Component {
  state = {
    type: "transport",
    desc_action: "create-transport",
  };

  componentDidMount() {
    this.props.controllerHeaderBand({
      currentTextHandlerBand: "Запрос на грузоперевозки",
      pathBackButton: () =>
        this.handleChangeScreen({ path: MAKE_REQUEST_MENU }),
    });

    this.props.dispatch(ACTION_SET_BUTTON_HEADER_ACTION, {
      isVisible: true,
      buttons: [
        {
          action: () =>
            this.handleChangeScreen({ path: MAKE_REQUEST_OWN_REQUEST }),
          iconLeft: myRequest,
          styleIconsLeft: { height: 12 },
          title: "Мои запросы",
          className: "button__controll--roze",
        },
      ],
    });
    if (this.props.isDesktop) {
      setTimeout(() => {
        this.props.dispatch(ACTION_SET_CONTROLL_BUTTON, {
          isActive: false,
          isFetch: false,
          // isfixed: true,
          typeButton: "static",
          show: true,
          addClass: "button__apply--dark-blue",
          title: "Опубликовать запрос",
        });
      }, 500);
    }
  }

  handleChangeScreen = ({ path }) => {
    this.props.navigate(path);
  };

  handleGetDataOption = (changeData) =>
    this.props.dispatch(ACTION_GET_NEW_LIST_DATA, { ...changeData });

  handleSetDataValues = (values) =>
    this.props.dispatch(ACTION_SET_VALUE_UNIT_SPARE, {
      activeScreen: this.state.type,
      desc_action: this.state.desc_action,
      callback: this.callback,
      ...values,
    });
  componentWillUnmount() {
    this.props.dispatch(ACTION_SET_VALUE_UNIT_SPARE_NULL);
  }

  callback = () => {
    this.props.handlerChangeItemMenu && this.props.handlerChangeItemMenu(99999);
  };
  render() {
    return (
      <TransportRequest
        isDesktop={this.props.isDesktop}
        dispatch={this.props.dispatch}
        handleSetDataValues={this.handleSetDataValues}
      />
    );
  }
}
export default connectStoreon(
  "listBrands",

  WithRouter(TransportRequestComponent)
);
