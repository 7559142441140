import { Form, Formik } from "formik";
import WrapContainer from "../../../View/WrapContainer/WrapContainer";
import Label from "../../../View/Label/Label";
import Offset from "../../../View/Offset";
import TextArea from "../../../View/TextArea/TextArea";
import {
  addFile,
  listRemove,
  plus,
  plusFile,
  // listRemove
} from "../../../images";
import FormUploadImageV2 from "../../../View/UploadImage/FormUploadImageV2";
import { messageErrorValidation } from "../../../helpers/schemaValidations/messgeSchemaValidations";
import { aggrigateSchema } from "../../../helpers/schemaValidations/schemaValidate";
import WrapContainerPreloader from "../../../View/Preloaders/WrapContainerPreloader";
import Preloader from "../../../View/Preloaders/Preloader";
import {
  getLocaleStore,
  getDataOptionsRequest,
  setLocaleStore,
  isAndroid,
  isIos,
} from "../../../helpers/utils";
import { ACTION_SET_CONTROLL_BUTTON } from "../../../store/helpers/helpers-store";
import WithTooltip from "../../../HOC/WithTooltip";
import TooltipComponent from "../../../Components/Component.Tooltip/TooltipComponent";
import restrictionLengthText from "../../../helpers/helpers";
import Input from "../../../View/Input/Input";
import FormInputContainer from "../../../View/FormInput/FormInputContainer";
import Icon from "../../../View/Icon/Icon";
import WrapTwoColumnGrid from "../../../View/Blocks/WrapTwoColumnGrid";
import WrapRowGrid from "../../../View/Blocks/WrapRowGrid";
import { delay } from "../../../helpers/const";
import ButtonMore from "../../../View/ButtonMore/ButtonMore";
import CardOEM from "../../../View/Cards/CardOEM/CardOEM";
import React, { useEffect } from "react";
import ButtonHide from "../../../View/ButtonHide/ButtonHide";
import ReactSelectComponent from "../../../View/Select/NativeSelect/ReactSelectComponent";
import BlockGrid from "../../../View/Blocks/BlockGrid";
import ButtonApplyContainer from "../../../Components/ButtonApplySection/ButtonApplyContainer";
import NavigationRequest from "../../../View/Navigation/NavigationMyApplication/NavigationRequest";
import { useStoreon } from "storeon/react";

const UnitSpareRequest = ({
  edit,
  member,
  tooltip,
  dispatch,
  dataRequstBrand,
  listClasses,
  isShowHide,
  valuesUnitSpare,
  prepareProducts,
  isAddMultiData,
  handleGetDataOption,
  handlerShowTooltip,
  handleSetDataValues,
  isDesktop,
  listPhons,
  infoEngine,
  listScreen,
  handlerSwitchScreen,
  isEngine,
  handlerMorePrepare = () => {},
  handlerDeleteImage = () => {},
  handlerShowHide = () => {},
  onClickInside = () => {},
  onClickOutside = () => {},
  handlerAddClasses = () => {},
  deleteClasses = () => {},
  changeState = () => {},
  type,
}) => {


  // if (edit && !valuesUnitSpare?.request_id) {
  //   return (
  //     <WrapContainerPreloader>
  //       Загрузка ... <Preloader /> <Offset mb={30} />{" "}
  //     </WrapContainerPreloader>
  //   );
  // }
  const { tg } = useStoreon('tg')
  console.log({ tg });
  return (
    <WrapContainer
      isDesktop={isDesktop}
      style={{
        height: isDesktop ? "max-content" : "auto",
      }}
    >
      <Offset mt={"var(--offset-top-desktop)"} />

      <BlockGrid
        addClass={isDesktop ? "profile-desktop__self-info" : ""}
        style={{
          maxHeight: isDesktop? `var(--height-field-request, 700px)` : "unset",
          //  && !(isIos() || isAndroid())
        }}
      >
        {type === "unit" && (
          <NavigationRequest
            handlerChangeSection={handlerSwitchScreen}
            listScreen={listScreen}
            style={{
              gridTemplateAreas: `'classes engine'`,
            }}
          />
        )}
        {!dataRequstBrand?.length ? (
          <WrapContainerPreloader>
            Загрузка ... <Preloader /> <Offset mb={30} />{" "}
          </WrapContainerPreloader>
        ) : edit && !valuesUnitSpare?.request_id ? (
          <WrapContainerPreloader>
            Загрузка ... <Preloader /> <Offset mb={30} />{" "}
          </WrapContainerPreloader>
        ) : (
          <Formik
            key={isEngine}
            validationSchema={aggrigateSchema(messageErrorValidation)}
            initialValues={{
              brand: valuesUnitSpare?.brand ?? null,
              model: valuesUnitSpare?.model ?? null,
              generation: valuesUnitSpare?.generation ?? null,
              engine: valuesUnitSpare?.engine ?? null,

              optionsBrand: dataRequstBrand,
              optionsModel: JSON.parse(getLocaleStore("optionsModel")) ?? [],
              optionsGeneration:
                JSON.parse(getLocaleStore("optionsGeneration")) ?? [],
              type: "unit",
              brand_id: valuesUnitSpare?.brand_id ?? "",
              model_id: valuesUnitSpare?.model_id ?? "",
              generation_id: valuesUnitSpare?.generation_id ?? "",
              engine_id: valuesUnitSpare?.engine_id ?? "",
              classes: valuesUnitSpare?.classes ?? [],
              text: valuesUnitSpare?.text ?? "",
              images: valuesUnitSpare?.images ?? null,
              oem: valuesUnitSpare?.oem ?? "",
              phone_number: valuesUnitSpare?.phone_number ?? null,
            }}
          >
            {({
              values,
              errors,
              handleSubmit,
              touched,
              handleBlur,
              setFieldValue,
              setErrors,
            }) => {
              dispatch(ACTION_SET_CONTROLL_BUTTON, {
                buttonForm: !edit ? handleSubmit : () => {},
              });
              const handlerBlur = (e) => {
                handleBlur(e);
              };
              console.log({ values });
              return (
                <>
                  <Offset mt={15} />
                  <Form
                    data-type="wrap-input"
                    className={
                      isDesktop ? "request-unit-spare-desktop__container" : ""
                    }
                  >
                    {/* <Label style={{ fontWeight: 700 }}>Что вы ищете</Label> */}
                    <div
                      style={{
                        padding: 0,
                        width: `100%`,
                        transition: `all .5s`,
                      }}
                      className={
                        isDesktop ? "request-unit-spare-desktop__wrap-text" : ""
                      }
                    >
                      <div>
                        <TextArea
                          className={"textarea-application"}
                          value={values.text}
                          placeholder={`Опишите ваш запрос.\nВ тексте укажите город`}
                          height={90}
                          label={"Что вы ищете"}
                          // isUpblockDesktop={true}
                          isUpblock={false}
                          topFormInput={73}
                          distationtop={290}
                          id={`textarea-1`}
                          name={"text"}
                          onClickInside={onClickInside}
                          onClickOutside={onClickOutside}
                          onBlur={handlerBlur}
                          helptext={touched?.text && errors?.text}
                          style={{
                            border:
                              touched?.text && errors?.text
                                ? "1px solid #ff0000"
                                : "",
                          }}
                          stylehelptext={{
                            color: "var(--text-color-red)",
                          }}
                          onChange={(e) => {
                            const value = e.target.value;
                            const restrict = restrictionLengthText(
                              value,
                              4096,
                              dispatch
                            );
                            if (!restrict) return;
                            setFieldValue("text", value);
                            handleSetDataValues({ text: value });
                          }}
                          styleLabel={{
                            fontWeight: 700,
                          }}
                        />
                      </div>
                    </div>
                    {/* OEM\VIN */}
                    <div
                      className={
                        isDesktop ? "request-unit-spare-desktop__wrap-oem" : ""
                      }
                    >
                      <Offset mb={14} />
                      <FormInputContainer
                        style={{
                          padding: 0,
                          width: `100%`,
                          top: 0,
                        }}
                      >
                        {/* <Label style={{ fontWeight: 500 }}>OEM\VIN</Label>
                <Offset mb={7} /> */}
                        <Input
                          value={values.oem}
                          isUpblockDesktop={false}
                          isUpblock={false}
                          placeholder={"Введите OEM или VIN"}
                          name={"oem"}
                          height={50}
                          label={"OEM\\VIN"}
                          widthFormInput={`calc(100% - 38px)`}
                          topFormInput={70}
                          onChange={(e) => {
                            const value = e.target.value;
                            const key = e.target.name;
                            setFieldValue([key], value);
                            handleSetDataValues({ oem: value });
                            setLocaleStore("oem", value);
                          }}
                          styleLabel={{
                            fontWeight: 700,
                          }}
                          onClickInside={onClickInside}
                          onClickOutside={onClickOutside}
                        />
                      </FormInputContainer>
                      <Offset mt={10} />
                    </div>
                    <div
                      className={
                        isDesktop
                          ? "request-unit-spare-desktop__wrap-oem-list-prepare"
                          : ""
                      }
                    >
                      {
                        // показать список карточек
                        // добавить кнопку скрыть
                        // добавить кнопку показать ещё (ok)
                        !!prepareProducts?.count ? (
                          <ButtonHide
                            onClick={handlerShowHide}
                            isShow={isShowHide}
                          />
                        ) : null
                      }
                      {isShowHide && !!prepareProducts?.count ? (
                        <React.Fragment>
                          <CardOEM list={prepareProducts.results} />
                          <Offset mt={10} />
                        </React.Fragment>
                      ) : null}
                      {isShowHide &&
                      prepareProducts?.count > 4 &&
                      prepareProducts?.count !==
                        prepareProducts.results.length ? (
                        <React.Fragment>
                          <ButtonMore
                            currentPage={prepareProducts.current_page}
                            countCards={prepareProducts?.count}
                            onClick={handlerMorePrepare}
                          />
                          <Offset mt={18} />
                        </React.Fragment>
                      ) : null}
                    </div>
                    <div
                      className={
                        isDesktop
                          ? "request-unit-spare-desktop__wrap-brand"
                          : ""
                      }
                    >
                      <Offset mt={16} />
                      <Label style={{ fontWeight: 700 }}>Марка авто</Label>
                      <Offset mt={8} />
                      <ReactSelectComponent
                        data={values.optionsBrand}
                        enabled={true}
                        isUpTop={isDesktop ? false : true}
                        name={"brand_id"}
                        // inputValue={values?.brand}
                        classNamePrefix={"request"}
                        clearValue={!!!values.brand_id}
                        placeholder={values?.brand ?? "Выберите марку авто"}
                        onChange={(value) => {
                          const type = "model";
                          setFieldValue("brand_id", value);
                          setFieldValue(
                            "brand",
                            values.optionsBrand.filter(
                              (el) => +el.value === +value
                            )[0].title
                          );
                          setFieldValue("generation_id", "");
                          setFieldValue("model_id", "");
                          setFieldValue("model", "");
                          setFieldValue("generation", "");
                          setFieldValue("engine", "");
                          setFieldValue("classes", []);
                          changeState();
                          handleGetDataOption({
                            type,
                            brand_id: value,
                            getDataOptionsRequest: (res) => {
                              const copyData = res.slice();
                              return getDataOptionsRequest({
                                setFieldValue,
                                results: copyData,
                                type: type,
                              });
                            },
                          });
                          handleSetDataValues({
                            brand_id: value,
                            classes: [],
                          });
                        }}
                        helptext={touched?.brand_id && errors?.brand_id}
                        stylehelptext={{
                          color: "var(--text-color-red)",
                        }}
                        styleWrap={{
                          border:
                            touched?.brand_id && errors?.brand_id
                              ? "1px solid #ff0000"
                              : "",
                        }}
                      />
                    </div>
                    {isAddMultiData ? (
                      <div
                        className={
                          isDesktop
                            ? "request-unit-spare-desktop__wrap-model"
                            : ""
                        }
                      >
                        <Offset mt={16} />
                        <Label style={{ fontWeight: 700 }}>Модель авто</Label>
                        <Offset mt={8} />
                        <TooltipComponent
                          onClick={(e) =>
                            !values.brand_id &&
                            handlerShowTooltip({
                              key: "request",
                              action: "model",
                              e,
                            })
                          }
                          // id={useId()}
                          style={{ bottom: -10, left: -10 }}
                          message={tooltip?.request?.model?.message}
                          isShow={
                            tooltip?.request?.model &&
                            tooltip?.request?.model?.isShow
                          }
                        >
                          <ReactSelectComponent
                            data={values.optionsModel}
                            enabled={!values?.model && !!values.brand_id}
                            isUpTop={isDesktop ? false : true}
                            name={"model_id"}
                            classNamePrefix={"request"}
                            clearValue={!!!values.model_id}
                            placeholder={
                              values?.model ?? "Выберите модель авто"
                            }
                            helptext={touched?.model_id && errors?.model_id}
                            styleWrap={{
                              border:
                                touched?.model_id && errors?.model_id
                                  ? "1px solid #ff0000"
                                  : "",
                            }}
                            stylehelptext={{
                              color: "var(--text-color-red)",
                            }}
                            onChange={(value) => {
                              const type = "generation";
                              setFieldValue("model_id", value);
                              setFieldValue("generation_id", "");
                              setFieldValue("engine_id", "");
                              handleGetDataOption({
                                type,
                                model_id: value,
                                getDataOptionsRequest: (res) => {
                                  const copyData = res.slice();
                                  getDataOptionsRequest({
                                    setFieldValue,
                                    results: copyData,
                                    type: type,
                                  });
                                },
                              });

                              handleSetDataValues({
                                model: values.optionsModel.filter(
                                  (el) => +el.value === +value
                                )[0].title,
                                model_id: value,
                                brand_id: values.brand_id,
                                brand: values.brand,
                              });
                            }}
                          />
                        </TooltipComponent>
                      </div>
                    ) : (
                      <div
                        className={
                          isDesktop
                            ? "request-unit-spare-desktop__wrap-model"
                            : ""
                        }
                      ></div>
                    )}
                    {isAddMultiData ? (
                      <div
                        className={
                          isDesktop
                            ? "request-unit-spare-desktop__wrap-generation"
                            : ""
                        }
                      >
                        <Offset mt={16} />

                        <Label style={{ fontWeight: 700 }}>
                          Год выпуска авто
                        </Label>
                        <Offset mt={8} />
                        <TooltipComponent
                          onClick={(e) =>
                            !!!values.brand_id &&
                            !!!values.model_id &&
                            handlerShowTooltip({
                              key: "request",
                              action: "generation",
                              e,
                            })
                          }
                          // id={useId()}
                          style={{ bottom: -10, left: -10 }}
                          message={tooltip?.request?.generation?.message}
                          isShow={
                            tooltip?.request?.generation &&
                            tooltip?.request?.generation?.isShow
                          }
                        >
                          <ReactSelectComponent
                            data={values.optionsGeneration}
                            enabled={
                              !values?.generation &&
                              !!values.brand_id &&
                              !!values.model_id
                            }
                            isUpTop={isDesktop ? false : true}
                            placeholder={
                              values?.generation ?? "Выберите год выпуска авто"
                            }
                            classNamePrefix={"request"}
                            clearValue={!!!values.generation_id}
                            name={"generation_id"}
                            helptext={
                              touched?.generation_id && errors?.generation_id
                            }
                            styleWrap={{
                              border:
                                touched?.generation_id && errors?.generation_id
                                  ? "1px solid var(--text-color-red)"
                                  : "",
                            }}
                            stylehelptext={{
                              color: "var(--text-color-red)",
                            }}
                            onChange={async (value) => {
                              const type = "engine";
                              setFieldValue("generation_id", value);
                              setFieldValue("engine_id", null);
                              setFieldValue(
                                "classes",
                                isEngine
                                  ? []
                                  : [
                                      ...values.classes,
                                      {
                                        model_id: values.model_id,
                                        generation_id: value,
                                        model_name: values.optionsModel.filter(
                                          (el) => el.value === +values.model_id
                                        )[0]?.title,
                                        generation_name:
                                          values.optionsGeneration.filter(
                                            (el) => el.value === +value
                                          )[0]?.title,
                                      },
                                    ]
                              );
                              handleGetDataOption({
                                type,
                                brand_id: values.brand_id,
                                model_id: values.model_id,
                                getDataOptionsRequest: (res) => {
                                  const copyData = res.slice();
                                  getDataOptionsRequest({
                                    setFieldValue,
                                    results: copyData,
                                    type: type,
                                  });
                                },
                              });
                              handleSetDataValues({
                                brand_id: values.brand_id,
                                model_id: values.model_id,
                                generation_id: value,
                                brand: values.brand,
                                classes: isEngine
                                  ? []
                                  : [
                                      ...values.classes,
                                      {
                                        model_id: values.model_id,
                                        generation_id: value,
                                        model_name: values.optionsModel.filter(
                                          (el) => el.value === +values.model_id
                                        )[0]?.title,
                                        generation_name:
                                          values.optionsGeneration.filter(
                                            (el) => el.value === +value
                                          )[0]?.title,
                                      },
                                    ],
                              });
                              await delay(500);
                              setErrors({ ...errors, generation_id: "" });
                            }}
                          />
                        </TooltipComponent>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div
                      className={
                        isDesktop
                          ? "request-unit-spare-desktop__wrap-engine"
                          : ""
                      }
                    >
                      {isEngine && (
                        <>
                          <Offset mt={18} />
                          <Label style={{ fontWeight: 700 }}>
                            Код двигателя
                          </Label>
                          <Offset mt={8} />
                          <ReactSelectComponent
                            data={values.optionsEngine}
                            enabled={!values?.engine && !!values.generation_id}
                            selectedValue={values.engine_id}
                            clearValue={!!!values.engine_id}
                            isUpTop={isDesktop ? false : true}
                            placeholder={
                              values?.engine ??
                              "Выберите код двигателя"
                            }
                            onBlur={handleBlur}
                            name={"engine_id"}
                            helptext={touched?.engine_id && errors?.engine_id}
                            styleWrap={{
                              border:
                                touched?.engine_id && errors?.engine_id
                                  ? "1px solid #ff0000"
                                  : "",
                            }}
                            stylehelptext={{
                              color: "var(--text-color-red)",
                            }}
                            onChange={async (value) => {
                              setFieldValue("engine_id", value);
                              handleSetDataValues({
                                brand_id: values.brand_id,
                                model_id: values.model_id,
                                generation_id: values.generation_id,
                                engine_id: value,
                              });

                              await delay(500);
                              setErrors({ ...errors, engine_id: "" });
                            }}
                          />

                          {infoEngine && !!Object.keys(infoEngine).length && (
                            <>
                              <Offset mt={18} />
                              <Label style={{ fontWeight: 700 }}>
                                Мощность
                              </Label>
                              <Offset mt={8} />
                              <ReactSelectComponent
                                data={infoEngine.power}
                                enabled={false}
                                // selectedValue={values.engine_id}
                                placeholder={infoEngine.power[0].name}
                                onBlur={handleBlur}
                                name={"power"}
                              />
                              <Offset mt={18} />
                              <Label style={{ fontWeight: 700 }}>
                                Мощность(л.с.)
                              </Label>
                              <Offset mt={8} />
                              <ReactSelectComponent
                                data={infoEngine.power_horsepower}
                                enabled={false}
                                // selectedValue={values.engine_id}
                                placeholder={
                                  infoEngine.power_horsepower[0].name
                                }
                                onBlur={handleBlur}
                                name={"power_horsepower"}
                              />
                              <Offset mt={18} />
                              <Label style={{ fontWeight: 700 }}>
                                Количество цилиндров
                              </Label>
                              <Offset mt={8} />
                              <ReactSelectComponent
                                data={infoEngine.cylinders_number}
                                enabled={false}
                                // selectedValue={values.engine_id}
                                placeholder={
                                  infoEngine.cylinders_number[0].name
                                }
                                onBlur={handleBlur}
                                name={"cylinders_number"}
                              />
                              <Offset mt={18} />
                              <Label style={{ fontWeight: 700 }}>
                                Год производства
                              </Label>
                              <Offset mt={8} />
                              <ReactSelectComponent
                                data={infoEngine.production_year}
                                enabled={false}
                                // selectedValue={values.engine_id}
                                placeholder={infoEngine.production_year[0].name}
                                onBlur={handleBlur}
                                name={"production_year"}
                              />
                              <Offset mt={18} />
                              <Label style={{ fontWeight: 700 }}>
                                ЭБУ бензин
                              </Label>
                              <Offset mt={8} />
                              <ReactSelectComponent
                                data={infoEngine.ecu_petrol}
                                enabled={false}
                                // selectedValue={values.engine_id}
                                placeholder={infoEngine.ecu_petrol[0].name}
                                onBlur={handleBlur}
                                name={"ecu_petrol"}
                              />
                            </>
                          )}
                        </>
                      )}
                      {isEngine && <Offset mb={20} />}
                    </div>

                    {!isAddMultiData && (
                      <div
                        className={
                          isDesktop
                            ? "request-unit-spare-desktop__wrap-list-car"
                            : ""
                        }
                      >
                        <Offset mt={15} />

                        <WrapTwoColumnGrid
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <WrapRowGrid>Добавить ещё модель?</WrapRowGrid>{" "}
                          <Icon
                            image={plus}
                            onClick={() =>
                              handlerAddClasses(setFieldValue, values?.brand_id)
                            }
                            style={{
                              filter: "brightness(0.4)",
                              // filter: "blur(var(--filter-blur))",
                            }}
                          />
                        </WrapTwoColumnGrid>
                        <Offset mt={15} />
                      </div>
                    )}

                    <Offset
                      mt={15}
                      className={
                        isDesktop
                          ? "request-unit-spare-desktop__wrap-list-car"
                          : ""
                      }
                    >
                      {values?.classes?.length
                        ? values.classes.map((item, i) => {
                            return (
                              <WrapTwoColumnGrid
                                key={i}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <WrapRowGrid>
                                  {i + 1}. {item?.model ?? item?.model_name} ---{" "}
                                  {item?.generation ?? item.generation_name}{" "}
                                </WrapRowGrid>
                                <Icon
                                  image={listRemove}
                                  style={{
                                    filter: "blur(var(--filter-blur))",
                                  }}
                                  onClick={() =>
                                    deleteClasses(i, values, setFieldValue)
                                  }
                                />
                              </WrapTwoColumnGrid>
                            );
                          })
                        : null}
                    </Offset>

                    <FormUploadImageV2
                      multiple
                      className={
                        "request-unit-spare-desktop__wrap-upload-images"
                      }
                      image={addFile}
                      listImages={
                        values.images
                          ? values.images
                          : isDesktop
                          ? [{ url: plusFile, type: "fake-image" }]
                          : []
                      }
                      values={values}
                      setFieldValue={setFieldValue}
                      maxCountImage={8}
                      maxSizeImage={5242880} // 5242880 => 5mb
                      uploadTypeName="image"
                      // positionPreview={isDesktop ? "over" : "under"}
                      onChange={({ key, value, callback }) => {
                        handleSetDataValues({ [key]: value });
                      }}
                      handlerDeleteImage={handlerDeleteImage}
                    />
                    <Offset mt={values?.images?.length ? 40 : 18} />
                    {!!values?.images?.length && <Offset mt={18} />}
                  </Form>
                </>
              );
            }}
          </Formik>
        )}
        {isDesktop && <Offset mt={30} />}
        {isDesktop && <ButtonApplyContainer isDesktop={isDesktop} />}
      </BlockGrid>
    </WrapContainer>
  );
};
export default (WithTooltip(UnitSpareRequest));
