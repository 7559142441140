import React from "react";
import WrapContainer from "../../../View/WrapContainer/WrapContainer";
import Offset from "../../../View/Offset";
import MainFeedbackComponent from "../../../Pages/FeedbackPage/FeedbackPage";
import { linksFeedback, SELF_ID_TELEGRAM } from "../../../helpers/config";
import BlockGrid from "../../../View/Blocks/BlockGrid";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination } from "swiper";

import "swiper/scss";
import "swiper/scss/effect-coverflow";
import "swiper/scss/pagination";
import "./styles/swiper.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import WrapContainerPreloader from "../../../View/Preloaders/WrapContainerPreloader";
import Preloader from "../../../View/Preloaders/Preloader";
import CardMPTitle from "../../../View/Cards/Detail/CardMPTitle";
import CardMPContainerRow from "../../../View/Cards/Detail/CardMPContainerRow";
import Icon from "../../../View/Icon/Icon";
import CardMPText from "../../../View/Cards/Detail/CardMPText";
import CardMPPrice from "../../../View/Cards/Detail/CardMPPrice";
import { getNumberSpace } from "../../../helpers/const";
import { fullRedHart, heardRedBorder, location, user } from "../../../images";
import WrapContainerFilters from "../../../View/WrapContainer/WrapContainerFilters";
import Button from "../../../View/Button/Button";
import { getLocaleStore } from "../../../helpers/utils";
import { openURl } from "../../../helpers/helpers";
import FavoriteContainer from "../../../View/BoxContainerFor/FaforiteConrainer/FavoriteContainer";
import CardMPCharacteristic from "../../../View/Cards/Detail/CardMPCharacteristic";
import CardMPCharacteristicSubTitle from "../../../View/Cards/Detail/CardMPCharacteristicSubTitle";
import CardMPDescriptions from "../../../View/Cards/Detail/CardMPDescriptions";
import CardMPDate from "../../../View/Cards/Detail/CardMPDate";
const DetailCardDesktop = ({
  card,
  dispatch,
  countFavorite,
  hendlerFavorite,
  handleChangeScreen,
  characteristic  = true,
  description = true,
}) => {
  return (
    <WrapContainer isDesktop>
       <Offset mt={'var(--offset-top-desktop)'} />
      {/* Карточка объявления */}
      <BlockGrid addClass="profile-desktop__self-info">
        <Swiper
          spaceBetween={50}
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}  
          
          touchEventsTarget ={ 'container'}
          simulateTouch ={ true}           
          touchRatio ={ 1}                 
          touchAngle ={ 45}                
          longSwipes ={ true}              
          longSwipesRatio ={0.5}
          followFinger ={ true}            
          allowTouchMove ={ true}          
          threshold ={ 20}                 
          
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={true}
          modules={[EffectCoverflow, Pagination]}
        >
          {(card?.image_urls_http?.length
            ? card?.image_urls_http
            : card?.image_urls?.length
            ? card?.image_urls
            : [
                {
                  id: 123423142134,
                  type: "image",
                  url: "https://botrazbor.ru/media/new/banners/motor_baner.png",
                },
              ]
          ).map(({ url }, index) => {
            return (
              <SwiperSlide key={index}>
                <LazyLoadImage
                  alt={"image.alt"}
                  effect="blur"
                  src={
                    url.slice(0, 5) === "http:"
                      ? `https://razbor-market.ru/api_engines/get_image/?url=${url}`
                      : url
                  }
                  style={{
                    objectFit: "cover",
                    maxHeight: 400,
                    minHeight: 400,
                    width: "100vw",
                  }}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
        {!card?.title ? (
          <WrapContainerPreloader>
            <Offset mb={20} /> Загрузка ... <Preloader />
          </WrapContainerPreloader>
        ) : (
          <>
            {card?.title ? (
              <CardMPTitle
                style={{ fontSize: 18, fontWeight: 900, marginTop: 14 }}
              >
                {card?.title}
              </CardMPTitle>
            ) : null}
            <Offset mb={6} />
            {card?.country || card?.city || card?.address ? (
              <CardMPContainerRow>
                <Icon width={16} height={16} image={location} />
                <CardMPText
                  style={{ padding: 3, color: "var(--text-color-opacity)" }}
                >
                  {card?.country ? card?.country : ""}{" "}
                  {card?.city ? card.city : ""}{" "}
                  {card?.address ? card.address : ""}
                </CardMPText>
              </CardMPContainerRow>
            ) : null}
            {card?.price ? (
              <CardMPPrice catalog={true}>
                {getNumberSpace(card.price)} <span>₽</span>{" "}
              </CardMPPrice>
            ) : null}

            {!!card.user && (
              <CardMPContainerRow style={{ marginTop: 9 }}>
                <Icon width={16} height={16} image={user} />
                <CardMPText
                  style={{ padding: 3, color: "var(--text-color-opacity)" }}
                >
                  {card.user}
                </CardMPText>
              </CardMPContainerRow>
            )}

            <WrapContainerFilters
              style={{
                position: "relative",
                marginTop: 14,
                marginBottom: 28,
              }}
            >
              <Button
                addClass={"button__controll--red"}
                style={{
                  width: "100%",
                  minHeight: 46,
                  fontSize: 12,
                  fontWeight: 900,
                }}
                onClick={() => {
                  if (
                    getLocaleStore(SELF_ID_TELEGRAM) ===
                    card.user_data?.telegram_id
                  ) {
                    return;
                  }
                  openURl(
                    card.user_data.user_url,
                    `Написать ${card.user_data.user_profile_name}`,
                    dispatch,
                    true
                  );
                }}
              >
                <p>Написать продавцу</p>
              </Button>
              {/* /button_marketplace_menu */}
              {/* <Link to={MARKETPLACE_FAVORITE}> */}
              <FavoriteContainer
                style={{ marginLeft: 5 }}
                onClick={() => {
                  hendlerFavorite({
                    status: card.is_favorite,
                    id_card: card.id,
                  });
                }}
              >
                <Icon
                  image={!card?.is_favorite ? heardRedBorder : fullRedHart}
                  width={16}
                  height={16}
                />
                {/* {!!countFavorite && <BadgeFavorite>{countFavorite}</BadgeFavorite>} */}
              </FavoriteContainer>
              {/* </Link> */}
            </WrapContainerFilters>

            {characteristic && (
              <CardMPCharacteristic title={"Характеристики"}>
                {card.count ? (
                  <CardMPCharacteristicSubTitle title={"В наличии:"}>
                    {card.count}
                  </CardMPCharacteristicSubTitle>
                ) : null}
                {card?.oem ? (
                  <CardMPCharacteristicSubTitle title={"Номер запчасти:"}>
                    {card.oem}
                  </CardMPCharacteristicSubTitle>
                ) : null}
                {card?.brand || card?.model || card?.generation ? (
                  <CardMPCharacteristicSubTitle title={"Применимость:"}>
                    {card?.brand ? card.brand : ""}{" "}
                    {card?.model ? ` | ${card.model}` : ""}{" "}
                    {card.generation ? ` | ${card.generation}` : ""}
                  </CardMPCharacteristicSubTitle>
                ) : null}
              </CardMPCharacteristic>
            )}
            {description && card?.description ? (
              <CardMPDescriptions title={"Описание товара"}>
                {card.description}
              </CardMPDescriptions>
            ) : null}
            <Offset mb={3} />
            {!!card?.date && (
              <CardMPCharacteristicSubTitle
                title={"Опубликовано:"}
                style={{
                  color: "var(--text-color-opacity)",
                  fontSize: 12,
                }}
                styleText={{
                  color: "var(--text-color-opacity)",
                  fontSize: 12,
                }}
              >
                <CardMPDate>{card.date}</CardMPDate>
              </CardMPCharacteristicSubTitle>
            )}
          </>
        )}
      </BlockGrid>

      <Offset mt={24} />
      <MainFeedbackComponent list={linksFeedback} />
    </WrapContainer>
  );
};

export default DetailCardDesktop;
