import React, {useId} from "react";
import Button from "../Button/Button";
import WrapContainer from "../WrapContainer/WrapContainer";
import Icon from "../Icon/Icon";
import { loader, settingRedRotation } from "../../images";
import TooltipComponent from "../../Components/Component.Tooltip/TooltipComponent";
import WithTooltip from "../../HOC/WithTooltip";
import { isAndroid, isIos } from "../../helpers/utils";

const ButtonApplyFixed = ({
  id,
  icon,
  show,
  type,
  title,
  isFetch,
  onClick,
  isActive,
  addClass,
  isDesktop,
  buttonForm,
  handlerShowTooltip,
}) => {
  console.log(show)
  return (
    <React.Fragment>
      {show &&
      <WrapContainer
        style={{
          zIndex: 99,
          height: 50,
          maxWidth: isDesktop ? "" : "var(--max-width-mobile, 320px)",
          overflowY: isDesktop ? "auto" : "",
          width: "100%",
        }}
      >
        <TooltipComponent
          onClick={
            !isActive
              ? () => {
                  handlerShowTooltip({ action: "validate", id });
                  buttonForm();
                }
              : null
          }
        >
          <Button
            id={useId()}
            style={{
              position: "fixed",
              top: isIos()
                ? "80vh"
                : isAndroid()
                ? "84vh"
                : "calc(100vh - 70px)", //"93vh",
              width: "calc(100vw - 30px)",
            }}
            disabled={!isActive}
            type={type}
            addClass={addClass}
            onClick={onClick}
          >
            {title}
            {isFetch ? (
              <Icon
                // image={settingRedRotation}
                image={loader}
                id={"fixed-apply"}
                width={20}
                height={20}
                style={{
                  position: "absolute",
                  right: 10,
                  top: 15,
                }}
              />
            ) : null}
          </Button>
        </TooltipComponent>
      </WrapContainer>}
    </React.Fragment>
  );
}

export default WithTooltip(ButtonApplyFixed);