import React from 'react'
import WrapContainer from '../../View/WrapContainer/WrapContainer'
import MainCatalogMPContainer from './CatalogMarketPlace/MainCatalogMPContainer'
import MainFeedbackComponent from '../FeedbackPage/FeedbackPage'
import Offset from '../../View/Offset'
import { linksFeedback } from '../../helpers/config'
import RecomandationsMarketplaceContainer from '../../Components/RecomandationsMarketplace/RecomandationsMarketplaceContainer'

export default function MarketPlace({

}) {
  return (
    <WrapContainer>
      <Offset mb={16} />

      <MainCatalogMPContainer />

      <RecomandationsMarketplaceContainer />

      <Offset mb={36} />

      <MainFeedbackComponent list={linksFeedback} />
    </WrapContainer>
  );
}
