import React from 'react'
import WrapContainer from '../../View/WrapContainer/WrapContainer'
import Offset from '../../View/Offset';
import BlockGrid from '../../View/Blocks/BlockGrid';
import Icon from '../../View/Icon/Icon';
import CardInfo from '../../View/Cards/Detail/review/CardInfo';
import { userGray } from '../../images';
import BlockFlex from '../../View/Blocks/BlockFlex';
import RaitingContainer from '../../View/Raiting/RaitingContainer';
import WrapTitleDescBlockOpacity from '../../View/Blocks/WrapTitleDescBlockOpacity';
import TextTitle from '../../View/Text/TextTitle';
import { getDataInfoUserFromArray } from '../../helpers/helpers';
import HeaderTitleActionComponent from '../../Components/Component.HeaderTitleAction/HeaderTitleActionComponent';
import NavigationReview from '../../View/Navigation/NavigationMyApplication/NavigationReview';
import WrapContainerCardReviewList from '../../View/Cards/RaitingAndReview/WrapContainerCardReviewList';
import Label from '../../View/Label/Label';
import FeedbackMyReview from '../../View/Cards/Detail/review/FeedbackMyReview';
import CardTitleDescBlock from '../../View/Cards/Detail/review/CardTitleDescBlock';

const FullInfoUserDesktop = ({
    infoUser,
    headerTitle,
    listSection,
    reviews,
    changePagination,
    handlerChangeSection,
    handleChangeScreen,
}) => {
  return (
    <WrapContainer isDesktop>
       <Offset mt={'var(--offset-top-desktop)'} />
      {/* Контент для макета десктопной версии страницы "Полная информация о пользователе" */}
      <BlockGrid addClass="profile-desktop__self-info">
        <BlockGrid
          style={{
            gridTemplateColumns: "repeat(3,1fr)",
            // gridTemplateColumns: "1fr 1fr 1fr",
            gap: 15,
            gridTemplateAreas: `'name . raiting'
                                'company achievment address'`,
            // padding: 20,
          }}
        >
          <BlockGrid
            style={{
              gridArea: "name",
              gridTemplateColumns: "70px 1fr",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BlockFlex
              style={{
                backgroundColor: "#F7F8F9",
                borderRadius: "50%",
                color: "#666E7D",
                width: 48,
                height: 48,
                justifyContent: "center",
                alignItems: "center",
                transition: "background-color 0.3s",
              }}
            >
              <Icon image={userGray} width={16} height={16} />
            </BlockFlex>
            <CardInfo style={{ textAlign: "start" }}>
              {infoUser.first_name}
            </CardInfo>
          </BlockGrid>
          <BlockGrid
            style={{
              gridArea: "raiting",
              gridTemplateColumns: "130px 1fr",
              justifyItems: "end",
            }}
          >
            <WrapTitleDescBlockOpacity
              style={{ display: "flex", alignItems: "flex-end" }}
            >
              Рейтинг продавца:
            </WrapTitleDescBlockOpacity>
            <RaitingContainer
              max={5}
              value={infoUser?.rating_int}
              sizeStarHeight={13}
              sizeStarWidth={13}
              gap={3.5}
              backgroundFone
              reverse
              style={{
                top: 7,
              }}
            />
          </BlockGrid>
          <BlockGrid
            style={{
              gridArea: "company",
              backgroundColor: "#F7F8F9",
              borderRadius: 16,
              padding: 32,
            }}
          >
            <TextTitle style={{ fontWeight: 700 }}>Компания:</TextTitle>
            <Offset mt={14} />
            <WrapTitleDescBlockOpacity>
              {infoUser.seller_organization}
            </WrapTitleDescBlockOpacity>
          </BlockGrid>
          <BlockGrid
            style={{
              gridArea: "achievment",
              backgroundColor: "#F7F8F9",
              borderRadius: 16,
              padding: 32,
              textOverflow: " ellipsis",
              //   whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            <TextTitle style={{ fontWeight: 700 }}>
              Преимущество продавца:
            </TextTitle>
            <Offset mt={14} />
            <WrapTitleDescBlockOpacity>
              {
                <span
                  dangerouslySetInnerHTML={{ __html: infoUser.seller_comment }}
                ></span>
              }
            </WrapTitleDescBlockOpacity>
          </BlockGrid>
          <BlockGrid
            style={{
              gridArea: "address",
              backgroundColor: "#F7F8F9",
              borderRadius: 16,
              padding: 32,
            }}
          >
            <TextTitle style={{ fontWeight: 700 }}>Адрес доставки:</TextTitle>
            <Offset mt={14} />
            <WrapTitleDescBlockOpacity>
              {getDataInfoUserFromArray(infoUser.seller_address)}
            </WrapTitleDescBlockOpacity>
          </BlockGrid>
        </BlockGrid>
      </BlockGrid>
      <Offset mb={15} />
      {/* review seller */}
      <BlockGrid addClass="profile-desktop__self-info">
        <HeaderTitleActionComponent list={headerTitle} />
        <NavigationReview
          listSection={listSection}
          handlerChangeSection={handlerChangeSection}
          style={{
            gridTemplateAreas: `'good bad'`,
          }}
        />
        <Offset mb={20} />
        <Label
          style={{
            fontSize: 12,
            fontWeight: 600,
          }}
        >
          Отзывов найдено: {reviews.count}
        </Label>
        <Offset mb={10} />

        <BlockGrid style={{ gridTemplateColumns: "1fr 1fr", gap: 10 }}>
          {reviews?.results && reviews.results.map((item, index) => {
            return (
              <BlockGrid
                style={{
                  // marginBottom: 14,
                  gridTemplateColumns: "1fr 1fr",
                  gridTemplateAreas: `'name reiting'
                  'desc desc'
                  'date .'`,
                  backgroundColor: "#F7F8F9",
                  borderRadius: 16,
                  padding: 24,
                }}
                key={"Own-card-" + index}
              >
                <CardInfo style={{ textAlign: "start", gridArea: "name" }}>
                  {item.author}
                </CardInfo>
                <RaitingContainer
                  max={5}
                  gap={4}
                  value={item?.rating_int}
                  sizeStarHeight={15}
                  sizeStarWidth={15}
                  reverse
                  style={{ top: -11, gridArea: "reiting" }}
                />
                <BlockGrid style={{ gridArea: "desc" }}>{item.text}</BlockGrid>
                <CardTitleDescBlock
                  style={{ gridArea: "date", textAlign: "start" }}
                >
                  <Offset mt={24} />
                  {item.date_create.slice(0, 10)}
                </CardTitleDescBlock>
              </BlockGrid>
            );
          })}
        </BlockGrid>
      </BlockGrid>
    </WrapContainer>
  );
}

export default FullInfoUserDesktop