import React, { Component } from 'react'
import FeedbackGetMonyForIdeaComponent from '../../../Components/FeedbackSections/FeedbackGetMonyForIdeaComponent';

export class MoneyForIdeaContainer extends Component {
  render() {
    return (
      <FeedbackGetMonyForIdeaComponent 
        isDesktop
      />
    )
  }
}

export default MoneyForIdeaContainer