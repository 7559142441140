import React, { Component } from 'react'
import IncomingRequests from './IncomingRequests'
import { ACTION_SET_BUTTON_HEADER_ACTION, ACTION_SET_CONTROLL_BUTTON } from '../../store/helpers/helpers-store';
import { INCOMING_REQUEST_INFO_SETTING } from '../../helpers/config';
import { help } from '../../images';
import { connectStoreon } from 'storeon/react';
import WithRouter from '../../HOC/WithRouter';
import { ACTION_SET_LIST_CARDS_BY_TYPE_FILTER_NULL, ACTION_SET_VALUE_INTO_LIST_CARDS_SELECTS_FILTER_NULL } from '../../store/filters/filtersIncominRequest';

export class IncomingRequestsContainerDesktop extends Component {
  state = {
    menu: [
      {
        id: 0,
        name: "Агрегаты и запчасти",
        isActive: false,
      },
      {
        id: 1,
        name: "Услуги по грузоперевозке",
        isActive: false,
      },
      {
        id: 2,
        name: "Услуги по ремонту",
        isActive: false,
      },
      {
        id: 3,
        name: "Грузовые",
        isActive: false,
      },
    ],
  };

  componentDidMount() {
    this.props.controllerHeaderBand({
      currentTextHandlerBand: "Входящие заявки",
      pathBackButton: -1,
    });

    this.props.dispatch(ACTION_SET_BUTTON_HEADER_ACTION, {
      isVisible: true,
      buttons: [
        {
          action: () =>
            this.handleChangeScreen({ path: INCOMING_REQUEST_INFO_SETTING }),
          iconLeft: help,
          styleIconsLeft: { height: 12 },
          title: "Как настроить?",
          className: "button__controll--roze",
        },
      ],
    });
  }
  handlerChangeItemMenu = (itemId) => {
    this.setState((prevState) => ({
      menu: prevState.menu.map((item) =>
        item.id === itemId
          ? { ...item, isActive: !item.isActive }
          : { ...item, isActive: false }
      ),
    }));
    this.props.dispatch(ACTION_SET_VALUE_INTO_LIST_CARDS_SELECTS_FILTER_NULL);
  };

  handleChangeScreen = ({ path }) => {
    this.props.navigate(path);
  };

  render() {
    return (
      <IncomingRequests
        listMenu={this.state.menu}
        handlerChangeItemMenu={this.handlerChangeItemMenu}
      />
    );
  }
}

export default connectStoreon(
  WithRouter(IncomingRequestsContainerDesktop));