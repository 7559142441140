import React from "react";
import WrapContainer from "../../View/WrapContainer/WrapContainer";
import Offset from "../../View/Offset";
import BlockGrid from "../../View/Blocks/BlockGrid";
import AccordionContainer from "../../Components/Component.Accardion/AccordionContainer";


const FixedBugsDesktop = ({
  listMounthFixedBugs,
  handlerChangeStateMounth,
}) => {
  return (
    <WrapContainer isDesktop>
      <Offset mt={"var(--offset-top-desktop)"} />
      <BlockGrid addClass="profile-desktop__self-info">
        <AccordionContainer
          styleItem={{
            paddingTop: "14px ",
            paddingBottom: "14px ",
            filter: `blur(var(--filter-blur))`,
            minHight: "auto",
          }}
          styleItemIcon={{
            width: "14px",
            height: "14px",
          }}
          list={listMounthFixedBugs}
          onChange={handlerChangeStateMounth}
        />
      </BlockGrid>
    </WrapContainer>
  );
};

export default FixedBugsDesktop;
