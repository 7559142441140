import React, { Component } from "react";
import { connectStoreon } from "storeon/react";
import WithRouter from "../../../HOC/WithRouter";
import { myRequest } from "../../../images";
import UnitSpareRequest from "./UnitSpareRequest";
import {
  MAKE_REQUEST_MENU,
  MAKE_REQUEST_OWN_REQUEST,
} from "../../../helpers/config";
import {
  ACTION_GET_LIST_BRANDS,
  ACTION_GET_NEW_LIST_DATA,
  ACTION_SET_VALUE_UNIT_SPARE,
  ACTION_SET_VALUE_UNIT_SPARE_NULL,
  ACTION_GET_PREPARE_PRODUTS,
  ACTION_RESET_LIST_PREPARE_PRODUCTS,
  ACTION_INFO_ENGINE_NULL,
} from "../../../store/requests/requests";
import { ACTION_SET_BUTTON_HEADER_ACTION, ACTION_SET_CONTROLL_BUTTON } from '../../../store/helpers/helpers-store';
import { getLocaleStore, setLocaleStore } from "../../../helpers/utils";
import { delay } from "../../../helpers/const";

class UnitRequestComponent extends Component {
  state = {
    type: "unit",
    desc_action: "create-unit",
    activeScreen: ["unit_spare", "unit_spare_engine"],
    isShowHide: true,
    isAddMultiData: true, // getLocaleStore("isAddMultiData") ??
    screensSwitching: [
      {
        title: "Без двигателя",
        id: 0,
        type: "classes",
        enable: true,
        active: true,
      },
      {
        title: "С двигателем",
        id: 1,
        type: "engine",
        enable: true,
        active: false,
      },
    ],
  };

  componentDidMount() {
    this.props.dispatch(ACTION_GET_LIST_BRANDS);

    this.props.controllerHeaderBand({
      currentTextHandlerBand: "Запрос на агрегат",
      pathBackButton: () =>
        this.handleChangeScreen({ path: MAKE_REQUEST_MENU }),
    });

    this.props.dispatch(ACTION_SET_BUTTON_HEADER_ACTION, {
      isVisible: true,
      buttons: [
        {
          action: () =>
            this.handleChangeScreen({ path: MAKE_REQUEST_OWN_REQUEST }),
          iconLeft: myRequest,
          styleIconsLeft: { height: 12 },
          title: "Мои запросы",
          className: "button__controll--roze",
        },
      ],
    });
    window.onbeforeunload = () => {
      setLocaleStore("isAddMultiData", null);
    };

    // if (this.props.isDesktop) {
    //   setTimeout(() => {
    //     this.props.dispatch(ACTION_SET_CONTROLL_BUTTON, {
    //       isActive: false,
    //       isFetch: false,
    //       // isfixed: true,
    //       typeButton: "static",
    //       show: true,
    //       addClass: "button__apply--dark-blue",
    //       title: "Опубликовать запрос",
    //     });
    //   }, 500);
    // }
  }

  handleChangeScreen = ({ path }) => {
    this.props.navigate(path);
  };

  handleGetDataOption = (changeData) =>
    this.props.dispatch(ACTION_GET_NEW_LIST_DATA, { ...changeData });

  handleSetDataValues = (values) => {
    this.props.dispatch(ACTION_SET_VALUE_UNIT_SPARE, {
      type: this.state.type,
      desc_action:
        this.state.desc_action +
        "_" +
        this.state.activeScreen[
          this.state.screensSwitching.filter((el) => el.active)[0].id
        ],
      activeScreen:
        this.state.activeScreen[
          this.state.screensSwitching.filter((el) => el.active)[0].id
        ],
      member: this.props.access.user_status,
      callback: this.callback,
      ...values,
    });
  };

  componentWillUnmount() {
    this.props.dispatch(ACTION_SET_VALUE_UNIT_SPARE_NULL);
    setLocaleStore("isAddMultiData", "");
    // this.props.dispatch(ACTION_RESET_LIST_PREPARE_PRODUCTS);
    // setLocaleStore("oem", null);
  }

  handlerSwitchScreen = async (e, type) => {
    const screen = this.state.screensSwitching.map((el) =>
      type === el.type ? { ...el, active: true } : { ...el, active: false }
    );
    this.setState((state) => ({
      ...state,
      screensSwitching: screen,
      isAddMultiData: true,
    }));
    this.props.dispatch(ACTION_SET_VALUE_UNIT_SPARE_NULL);
    this.props.dispatch(ACTION_INFO_ENGINE_NULL);
    // await delay(500)
    this.props.dispatch(ACTION_SET_VALUE_UNIT_SPARE, {
      type: this.state.type,
      desc_action:
        this.state.desc_action +
        "_" +
        this.state.activeScreen[
          this.state.screensSwitching.filter((el) => el.active)[0].id
        ],
      activeScreen:
        this.state.activeScreen[screen.filter((el) => el.active)[0].id],
    });
  };

  handlerShowHide = () => {
    this.setState((state) => ({
      ...state,
      isShowHide: !this.state.isShowHide,
    }));
  };

  handlerMorePrepare = (page) => {
    const params = {
      oem: getLocaleStore("oem"),
      page_size: 4,
      page: page + 1,
    };
    this.props.dispatch(ACTION_GET_PREPARE_PRODUTS, params);
    // this.props.dispatch(ACTION_SET_VALUE_UNIT_SPARE_NULL);
  };
  onClickInside = () => {
    // this.props.dispatch(ACTION_SET_CONTROLL_BUTTON, { show: false });
  };
  onClickOutside = () => {
    // this.props.dispatch(ACTION_SET_CONTROLL_BUTTON, { show: true });
  };

  deleteClasses = (id, values, setFieldValue) => {
    setFieldValue(
      "classes",
      values.classes.filter((el, i) => i !== id)
    );
    this.handleSetDataValues({
      ...values,
      classes: values.classes.filter((el, i) => i !== id),
    });
    if (values.classes.filter((el, i) => i !== id).length === 0) {
      setFieldValue("model_id", "");
      setFieldValue("generation_id", "");
      setLocaleStore("isAddMultiData", true);
      this.setState((state) => ({
        ...state,
        isAddMultiData: true,
      }));
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      // !this.props.valuesUnitSpare.isEngine &&
      prevProps?.valuesUnitSpare?.classes?.length !==
        this.props?.valuesUnitSpare?.classes?.length &&
      this.props?.valuesUnitSpare?.classes?.length !== 0
    ) {
      setLocaleStore("isAddMultiData", false);
      this.setState((state) => ({
        ...state,
        isAddMultiData: false,
      }));
    }
  }

  handlerAddClasses = (setFieldValue) => {
    setFieldValue("model_id", "");
    setFieldValue("generation_id", "");
    setLocaleStore("isAddMultiData", true);

    this.setState((state) => ({
      ...state,
      isAddMultiData: true,
    }));
  };
  changeState = () => {
    setLocaleStore("isAddMultiData", true);
    this.setState((state) => ({
      ...state,
      isAddMultiData: true,
    }));
  };

  callback = () => {
    this.props?.handlerChangeItemMenu && this.props.handlerChangeItemMenu(999999);
    setLocaleStore("isAddMultiData", true);
    this.setState((state) => ({
      ...state,
      isAddMultiData: true,
    }));
  };
  render() {
    // console.log({ isEngine: this.props.valuesUnitSpare });
    // console.log({ isAddMultiData: this.state.isAddMultiData });

    return (
      <UnitSpareRequest
        isEngine={this.props.valuesUnitSpare.isEngine}
        listScreen={this.state.screensSwitching}
        handlerSwitchScreen={this.handlerSwitchScreen}
        isDesktop={this.props.isDesktop}
        onClickOutside={this.onClickOutside}
        member={this.props.access?.status_data?.user_status}
        listPhons={this.props.access?.status_data?.phone_list}
        infoEngine={this.props.infoEngine}
        dataRequstBrand={this.props.listBrands}
        valuesUnitSpare={this.props.valuesUnitSpare}
        dispatch={this.props.dispatch}
        handleGetDataOption={this.handleGetDataOption}
        handleSetDataValues={this.handleSetDataValues}
        handlerShowHide={this.handlerShowHide}
        isShowHide={this.state.isShowHide}
        handlerMorePrepare={this.handlerMorePrepare}
        prepareProducts={this.props.listPrepare}
        onClickInside={this.onClickInside}
        isAddMultiData={this.state.isAddMultiData}
        handlerAddClasses={this.handlerAddClasses}
        deleteClasses={this.deleteClasses}
        changeState={this.changeState}
        type={'unit'} 
      />
    );
  }
}
export default connectStoreon(
  "access",
  "infoEngine",
  "listBrands",
  "listPrepare",
  "valuesUnitSpare",
  WithRouter(UnitRequestComponent)
);
