import React, { PureComponent } from 'react'
import { connectStoreon } from 'storeon/react'
import WithRouter from '../../HOC/WithRouter'
import MarketPlace from './MarketPlace'
import { setLocaleStore } from '../../helpers/utils'
import { MARKETPLACE_CREATE_CARD, ROOT } from '../../helpers/config'
import CreateMarketComponent from '../../Components/ModalSection/CreateMarketComponent'
import { ACTION_OPEN_MODAL, ACTION_SET_BUTTON_HEADER_ACTION } from '../../store/helpers/helpers-store'
import MarketplaceCatalogDesktop from '../../PagesDesktop/Marketplace/MarketplaceCatalog/MarketplaceCatalogDesktop'

class MarketPlaceContainer extends PureComponent {
  componentDidMount() {
    setLocaleStore("lastPathCreateCardMarket", this.props.pathname);
    this.props.controllerHeaderBand({
      currentTextHandlerBand: "Маркет",
      pathBackButton: ROOT,
    });

    this.props.dispatch(ACTION_SET_BUTTON_HEADER_ACTION, {
      isVisible: true,
      buttons: [
        {
          action: () =>
            this.props.isDesktop
              ? this.handleChangeScreen({ path: MARKETPLACE_CREATE_CARD })
              : this.props.dispatch(ACTION_OPEN_MODAL, {
                  show: true,
                  content: <CreateMarketComponent />,
                  hideIcon: true,
                  hideControll: true,
                }),
          styleIconsLeft: { height: 12 },
          title: "Создать объявление",
          className: "button__controll--red",
        },
      ],
    });
  }
  handleChangeScreen = ({ path }) => {
    this.props.navigate(path);
  };
  render() {
    if (this.props.isDesktop) {
      return <MarketplaceCatalogDesktop />;
    }

    return <MarketPlace />;
  }
}

export default connectStoreon(

  WithRouter(MarketPlaceContainer)
)