import React from 'react'
import BlockFlex from '../../../View/Blocks/BlockFlex'
import { ReactSVG } from 'react-svg'
import { arrowCameback, arrowLeftWhite, arrowRight } from '../../../images'

function ArrowCameback({ handlerClickCameback }) {
  return (
    <BlockFlex addClass='block__arrowcameback-container'>
      <ReactSVG onClick={handlerClickCameback} src={arrowCameback} />
    </BlockFlex>
  );
}

export default ArrowCameback