import classNames from 'classnames'
import React from 'react'

import styles from './styles/block.module.scss';

const BlockFlex = ({children, style={}, addClass='', onClick,...props}) => {
    const initClassName = classNames({
        [styles['block__flex']]: true,
        [styles[addClass]]: addClass,
    })
  return (
    <div onClick={onClick} className={initClassName} style={style} {...props}>
      {children}
    </div>
  );
}

export default BlockFlex