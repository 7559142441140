import React from "react";
import WrapContainer from "../../../View/WrapContainer/WrapContainer";
import Offset from "../../../View/Offset";
import BlockGrid from "../../../View/Blocks/BlockGrid";
import MainCatalogMPContainer from "../../../Pages/MarketPlace/CatalogMarketPlace/MainCatalogMPContainer";
import RecomandationsMarketplaceContainer from "../../../Components/RecomandationsMarketplace/RecomandationsMarketplaceContainer";
import MainFeedbackComponent from "../../../Pages/FeedbackPage/FeedbackPage";
import { linksFeedback } from "../../../helpers/config";

const MarketplaceCatalogDesktop = ({
  
}) => {
  return (
    <WrapContainer isDesktop>
      <Offset mt={"var(--offset-top-desktop)"} />
      <BlockGrid addClass="profile-desktop__self-info">
        <MainCatalogMPContainer isDesktop />
      </BlockGrid>

      <Offset mt={"var(--offset-top-desktop)"} />
      <BlockGrid addClass="profile-desktop__self-info">
        <RecomandationsMarketplaceContainer slidesPerView={5} />
      </BlockGrid>

      <Offset mb={36} />

      <MainFeedbackComponent list={linksFeedback} />
    </WrapContainer>
  );
};

export default MarketplaceCatalogDesktop;
