import React from 'react'
import WrapTwoColumnGrid from '../Blocks/WrapTwoColumnGrid';
import WrapIconBlock from '../Blocks/WrapIconBlock';
import Icon from '../Icon/Icon';
import { subscribe } from '../../images';
import WrapRowGrid from '../Blocks/WrapRowGrid';
import BlockTitle from '../Blocks/BlockTitle';
import WrapTitleDescBlockOpacity from '../Blocks/WrapTitleDescBlockOpacity';

const UserName = ({ profileInfoData, style ={} }) => {
  return (
    <WrapTwoColumnGrid
      style={{
        gridTemplateColumns: `15% 85%`,
        left: 12,
        ...style
      }}
    >
      <WrapIconBlock>
        <Icon
          addClass={"icon__bg-roze-38"}
          width={14}
          height={14}
          image={subscribe}
        />
      </WrapIconBlock>
      <WrapRowGrid
        style={{
          margenLeft: -7,
        }}
      >
        <BlockTitle style={{ fontWeight: 500 }}>
          {profileInfoData.name}
        </BlockTitle>
        <WrapTitleDescBlockOpacity>
          {profileInfoData.organization}
        </WrapTitleDescBlockOpacity>
      </WrapRowGrid>
    </WrapTwoColumnGrid>
  );
};

export default UserName