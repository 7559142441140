import { Form, Formik } from 'formik';
import WrapContainer from '../../../View/WrapContainer/WrapContainer';
import Offset from '../../../View/Offset';
import TextArea from '../../../View/TextArea/TextArea';
import WithRouter from '../../../HOC/WithRouter';

import { addFile, plusFile } from '../../../images';
import FormUploadImageV2 from '../../../View/UploadImage/FormUploadImageV2';
import { truckSchema } from '../../../helpers/schemaValidations/schemaValidate';
import { messageErrorValidation } from '../../../helpers/schemaValidations/messgeSchemaValidations';
import { ACTION_SET_CONTROLL_BUTTON } from '../../../store/helpers/helpers-store';
import restrictionLengthText from '../../../helpers/helpers';
import WrapContainerPreloader from '../../../View/Preloaders/WrapContainerPreloader';
import Preloader from '../../../View/Preloaders/Preloader';
import BlockGrid from '../../../View/Blocks/BlockGrid';
import ButtonApplyContainer from '../../../Components/ButtonApplySection/ButtonApplyContainer';

const TruckRequest = ({
  edit,
  dispatch,
  isDesktop,
  handleSetDataValues,
  dataCurrentRequest,
}) => {
console.log({isDesktop})
  return (
    <WrapContainer
      isDesktop={isDesktop}
      style={{
        height: isDesktop ? "max-content" : "auto",
      }}
    >
      <Offset mt={"var(--offset-top-desktop)"} />
      <BlockGrid addClass={isDesktop ? "profile-desktop__self-info" : ""}>
        {edit && !dataCurrentRequest?.request_id ? (
          <WrapContainerPreloader>
            Загрузка ... <Preloader /> <Offset mb={30} />{" "}
          </WrapContainerPreloader>
        ) : (
          <Formik
            validationSchema={truckSchema(messageErrorValidation)}
            initialValues={{
              type: "truck",
              text: dataCurrentRequest?.text ?? "",
              images: dataCurrentRequest?.images ?? [],
            }}
          >
            {({
              values,
              errors,
              handleSubmit,
              touched,
              handleBlur,
              setFieldValue,
            }) => {
              dispatch(ACTION_SET_CONTROLL_BUTTON, {
                buttonForm: handleSubmit,
              });
              console.log(values);

              return (
                <Form
                  className={
                    isDesktop ? "request-truck-desktop__container" : ""
                  }
                >
                  <div
                    className={
                      isDesktop ? "request-truck-desktop__wrap-text" : ""
                    }
                  >
                    <Offset mt={16} />
                    <TextArea
                      className={"textarea-application"}
                      value={values.text}
                      placeholder={`Опишите ваш запрос.\nВ тексте укажите город`}
                      label={"Что вы ищете"}
                      styleLabel={{
                        fontWeight: 700,
                      }}
                      height={90}
                      id={`textarea-1`}
                      name={"text"}
                      onBlur={handleBlur}
                      helptext={touched?.text && errors?.text}
                      style={{
                        border:
                          touched?.text && errors?.text
                            ? "1px solid #ff0000"
                            : "",
                      }}
                      stylehelptext={{
                        color: "var(--text-color-red)",
                      }}
                      onChange={(e) => {
                        const value = e.target.value;
                        const restrict = restrictionLengthText(
                          value,
                          4096,
                          dispatch
                        );
                        if (!restrict) return;
                        setFieldValue("text", value);
                        handleSetDataValues({ text: value });
                      }}
                    />
                  </div>

                  <FormUploadImageV2
                    multiple
                    className={"request-truck-desktop__wrap-upload-images"}
                    image={addFile}
                    listImages={
                      values?.images?.length? values?.images : isDesktop
                        ? [{ url: plusFile, type: "fake-image" }]
                        : []
                    }
                    values={values}
                    setFieldValue={setFieldValue}
                    uploadTypeName="image"
                    positionPreview={isDesktop ? "over" : "under"}
                    onChange={({ key, value, callback }) => {
                      handleSetDataValues({ [key]: value });
                    }}
                  />
                  <Offset mt={values?.images?.length ? 38 : 18} />
                  {values?.images?.length ? <Offset mt={18} /> : null}
                </Form>
              );
            }}
          </Formik>
        )}
        {isDesktop && <Offset mt={30} />}
        {isDesktop && <ButtonApplyContainer isDesktop={isDesktop} />}
      </BlockGrid>
    </WrapContainer>
  );
};
export default (WithRouter(TruckRequest));
