import React, { Component } from 'react'
import DetaliCardsMotorChain from './DetaliCardsMotorChain'
import { connectStoreon } from 'storeon/react'
import WithRouter from '../../../HOC/WithRouter'
import { getLocaleStore, setLocaleStore } from '../../../helpers/utils';
import { ACTION_GET_ONE_MOTOR_CARD } from '../../../store/chain-motors/chain-motors';
import DetailChineEngineDesktop from '../../../PagesDesktop/ChineEngine/DetailChineEngine/DetailChineEngineDesktop';

export class DetaliCardsMotorChainContainer extends Component {
  componentDidMount() {
      this.props.dispatch(ACTION_GET_ONE_MOTOR_CARD,{
          id: this.props?.stateNavigate?.card?.id ?? getLocaleStore('idOneCardChainMotor')
        });
    this.props.controllerHeaderBand({
      currentTextHandlerBand: "Китайские двигатели",
      pathBackButton: () =>
        this.handleChangeScreen({
          path: getLocaleStore("catalogChainMotors"),
        }),
    });
    if (this.props.stateNavigate?.card?.id)
      setLocaleStore("idOneCardChainMotor", this.props.stateNavigate?.card?.id);
  }
  handleChangeScreen = ({ path }) => {
    this.props.navigate(path);
  };
  render() {
if(this.props.isDesktop){
  return (
    <DetailChineEngineDesktop 
      card={this.props.dataOneMotorChain}
      dispatch={this.props.dispatch}
    />
  )
}
    return <DetaliCardsMotorChain 
        card={this.props.dataOneMotorChain}
        dispatch={this.props.dispatch}
    />;
  }
}

export default connectStoreon( 
    'dataOneMotorChain',
   WithRouter( DetaliCardsMotorChainContainer)
)