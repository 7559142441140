import React, { Component } from 'react'
import { connectStoreon } from 'storeon/react'
import TitleHeader from './TitleHeader'

export class TitleHeaderContainer extends Component {
  render() {
    return <TitleHeader textHeader={this.props.textHeader} />;
  }
}

export default connectStoreon( 
    'textHeader',
    TitleHeaderContainer)