import React, { useEffect, useRef, useState } from "react";
import WrapContainer from '../../../View/WrapContainer/WrapContainer'
import Offset from '../../../View/Offset'
import SearchComponent from '../../../Components/SearchSection/SearchComponent';
import { SET_TEXT_SEARCH_INCOMING_FILTERS_TEXT } from '../../../store/filters/filtersIncominRequest';
import BlockGrid from '../../../View/Blocks/BlockGrid';
import WrapTitleDescBlockOpacity from '../../../View/Blocks/WrapTitleDescBlockOpacity';
import WrapTwoColumnGrid from '../../../View/Blocks/WrapTwoColumnGrid';
import Icon from '../../../View/Icon/Icon';
import BlockTitle from '../../../View/Blocks/BlockTitle';
import Select from "react-select";
import { serializerOptions } from "../../../helpers/helpers";
import CheckBox from "../../../View/CheckBox";
import './styles/costom-select.scss';
import ButtonDesktopIncomminFilters from "../../../View/ButtonDesktop/ButtonDesktopIncomminFilters";

  const IncomingRequestUnitSpareDesktop = ({
    resultModels,
    resSelectCard,
    fetchDataModels,
    fetchDataEngine,
    textInputSearch,
    resultBrands,
    resultEngines,
    statusLoadingData,
    handlerTextSearch,
    handleChangeModel,
    resultGenerations,
    fetchDataGeneration,
    handlerSelectItemFilter,

    handleShowMore,
    showDesktopButton,
  }) => {
    const refBrand = useRef(null);
    const [isGenerationShow, setIsGenerationShow] = useState('-1'); // Индикатор загрузки
    const [isEngineShow, setIsEngineShow] = useState('-1'); // Индикатор загрузки
    const [isLoading, setIsLoading] = useState(false); // Индикатор загрузки
    const [subMenuOpen, setSubMenuOpen] = useState("-1");
    const [subMenuOpenGeneration, setSubMenuOpenGeneration] = useState("-1");

   

    const customComponentsEngine = ({data,}) => {
       return data.map((data, i) => {
          // console.log({data})
          return (
            <BlockGrid
              key={i}
              style={{
                gridTemplateColumns: "130px 2fr",
                gap: 15,
                position: "relative",
                padding: 5,
              }}
            >
              <BlockGrid>{data.label}</BlockGrid>

              <BlockGrid
                style={{
                  gridTemplateColumns: "1fr 1fr",
                  gap: 10,
                  justifySelf: "end",
                }}
              >
                <CheckBox
                  checked={data?.status_unit?.status}
                  disabled={
                    statusLoadingData
                      ? statusLoadingData
                      : !data?.status_unit?.can_change
                  }
                  onChange={(res) => {
                    handlerSelectItemFilter({
                      id: res.value,
                      sub_type: "unit",
                      list: "engines",
                      checked: res.checked,
                    });
                  }}
                  name={"engine"}
                  helptext={"Агрегаты"}
                  helpTextStyle={{
                    top: 2,
                  }}
                  value={+data.id}
                  id={`check-${data.id}`}
                />
                {/* <CheckBox
                  checked={data?.status_spare?.status}
                  disabled={
                    statusLoadingData
                      ? statusLoadingData
                      : !data?.status_spare?.can_change
                  }
                  onChange={(res) => {
                    handlerSelectItemFilter({
                      id: res.value,
                      sub_type: "spare",
                      list: "engines",
                      checked: res.checked,
                    });
                  }}
                  name={"engine"}
                  helptext={"Запчасти"}
                  helpTextStyle={{
                    top: 2,
                  }}
                  value={+data.id}
                  id={`check-${data.id}`}
                /> */}
              </BlockGrid>
            </BlockGrid>
          );
        });

    };
    const customComponentsGenerations = ({ data, model_id }) => {
      return data.map((data, i) => {
        // console.log({data})
        return (
          <BlockGrid
            key={i}
            style={{
              gridTemplateColumns: "130px 2fr",
              gridTemplateAreas: `'name engine'
                                    'checkbox checkbox'`,
              gap: 15,
              position: "relative",
              padding: 5,
            }}
          >
            <BlockGrid style={{ gridArea: "name" }}>{data.label}</BlockGrid>
            <BlockGrid style={{ gridArea: "engine" }}>
              {/* engine */}
              <BlockGrid
                addClass="block__fake-options"
                style={{
                  pointerEvents:
                    data.id === 0 || !data.can_click ? "none" : "all",
                  backgroundColor:
                    data.id === 0 || !data.can_click
                      ? "#01010117"
                      : "transparent",
                }}
                onClick={() => {
                  isEngineShow.includes(`${data.id}`)
                    ? setIsEngineShow("-1")
                    : setIsEngineShow(`${data.id}`);
                  fetchDataEngine({
                    model_id,
                  });
                }}
              >
                {"Все двигателя"}
              </BlockGrid>
              {isEngineShow.includes(`${data.id}`) && (
                <div>
                  <Offset mt={10} />
                  {customComponentsEngine({
                    data: serializerOptions(resultEngines),
                    model_id: data.id,
                  })}
                </div>
              )}
            </BlockGrid>
            <BlockGrid
              style={{
                gridTemplateColumns: "1fr 1fr",
                gap: 10,
                justifySelf: "start",
                gridArea: "checkbox",
              }}
            >
              <CheckBox
                checked={data?.status_unit?.status}
                disabled={
                  statusLoadingData
                    ? statusLoadingData
                    : !data?.status_unit?.can_change
                }
                onChange={(res) => {
                  handlerSelectItemFilter({
                    id: res.value,
                    sub_type: "unit",
                    list: "generations",
                    checked: res.checked,
                  });
                }}
                name={"generation"}
                helptext={"Агрегаты"}
                helpTextStyle={{
                  top: 2,
                }}
                value={+data.id}
                id={`check-${data.id}`}
              />
              <CheckBox
                checked={data?.status_spare?.status}
                disabled={
                  statusLoadingData
                    ? statusLoadingData
                    : !data?.status_spare?.can_change
                }
                onChange={(res) => {
                  handlerSelectItemFilter({
                    id: res.value,
                    sub_type: "spare",
                    list: "generations",
                    checked: res.checked,
                  });
                }}
                name={"generation"}
                helptext={"Запчасти"}
                helpTextStyle={{
                  top: 2,
                }}
                value={+data.id}
                id={`check-${data.id}`}
              />
            </BlockGrid>
          </BlockGrid>
        );
      });
    };
    const customComponentsModel = {
      Option: (props) => {
        // console.log({props})
        const { data, isSelected, selectProps, innerRef, innerProps } = props;
        const id_brand = selectProps.id;
        return (
          <BlockGrid
            style={{
              gridTemplateColumns: "130px 2fr",
              gridTemplateAreas: `'model generation generation'
                                  'checkbox . .'`,
              gap: 15,
              padding: 5,
              position: "relative",
            }}
          >
            <BlockGrid
              style={{
                gridArea: "model",
                alignSelf: isGenerationShow.includes(`${data.id}`)
                  ? "baseline"
                  : "center",
                paddingTop: isGenerationShow.includes(`${data.id}`)
                  ? "10px"
                  : "",
              }}
            >
              {data.label}
            </BlockGrid>

            <BlockGrid
              style={{
                gridArea: "checkbox",
                gridTemplateColumns: "1fr 1fr",
                gap: 10,
                // justifySelf: "end",
              }}
            >
              <CheckBox
                checked={data?.status_unit?.status}
                disabled={
                  statusLoadingData
                    ? statusLoadingData
                    : !data?.status_unit?.can_change
                }
                onChange={(res) => {
                  handlerSelectItemFilter({
                    id: res.value,
                    sub_type: "unit",
                    list: "models",
                    checked: res.checked,
                  });
                }}
                name={""}
                helptext={"Агрегаты"}
                helpTextStyle={{
                  top: 2,
                }}
                value={+data.id}
                id={`check-${data.id}`}
              />
              <CheckBox
                checked={data?.status_spare?.status}
                disabled={
                  statusLoadingData
                    ? statusLoadingData
                    : !data?.status_spare?.can_change
                }
                onChange={(res) => {
                  handlerSelectItemFilter({
                    id: res.value,
                    sub_type: "spare",
                    list: "models",
                    checked: res.checked,
                  });
                }}
                name={""}
                helptext={"Запчасти"}
                helpTextStyle={{
                  top: 2,
                }}
                value={+data.id}
                id={`check-${data.id}`}
              />
            </BlockGrid>

            <BlockGrid style={{ gridArea: "generation" }}>
              {/* generetions */}
              <BlockGrid
                addClass="block__fake-options"
                style={{
                  pointerEvents:
                    data.id === 0 || !data.can_click ? "none" : "all",
                  backgroundColor:
                    data.id === 0 || !data.can_click
                      ? "#01010117"
                      : "transparent",
                }}
                onClick={() => {
                  isGenerationShow.includes(`${data.id}`) &&
                    setIsEngineShow("-1");
                  isGenerationShow.includes(`${data.id}`)
                    ? setIsGenerationShow("-1")
                    : setIsGenerationShow(`${data.id}`);
                  fetchDataGeneration({
                    model_id: data.id,
                  });
                }}
              >
                {"Все поколения"}
              </BlockGrid>
              {isGenerationShow.includes(`${data.id}`) && (
                <div>
                  <Offset mt={10} />
                  {customComponentsGenerations({
                    data: serializerOptions(resultGenerations),
                    model_id: data.id,
                  })}
                </div>
              )}
            </BlockGrid>
          </BlockGrid>
        );
      },
    };
    return (
      <WrapContainer isDesktop style={{ height: "auto", minHeight: 455 }}>
        <Offset mt={"var(--offset-top-desktop)"} />
        <BlockGrid
          style={{
            gridTemplateColumns: "1fr 1fr",
            gap: 10,
            gridTemplateAreas: `'search .'
          'title-catalog title-catalog'
          'catalog catalog'
          `,
          }}
        >
          <SearchComponent
            isIconLeft
            placeholder={"Найдите марку автомобиля"}
            enteredText={textInputSearch} // текущий текст
            getResultSearch={handlerTextSearch} // функция обработки запроса
            actionDisptchGetSearch={SET_TEXT_SEARCH_INCOMING_FILTERS_TEXT} // где хроним текст
            style={{
              maxWidth: "100%",
              gridArea: "search",
            }}
          />

          {resultBrands?.results?.length ? (
            <>
              {/* header table */}
              <BlockGrid
                style={{
                  gridArea: "title-catalog",
                  gridTemplateColumns: "1fr 1fr 1fr 2fr",
                  gap: 10,
                  gridTemplateAreas: `'title-brand title-model title-generation category'`,
                  marginTop: 20,
                }}
              >
                <WrapTitleDescBlockOpacity
                  style={{
                    gridArea: "title-brand",
                    textTransform: "uppercase",
                  }}
                >
                  Марка авто
                </WrapTitleDescBlockOpacity>
                <WrapTitleDescBlockOpacity
                  style={{
                    gridArea: "title-model",
                    textTransform: "uppercase",
                  }}
                >
                  Модель
                </WrapTitleDescBlockOpacity>
                <WrapTitleDescBlockOpacity
                  style={{
                    gridArea: "title-generation",
                    textTransform: "uppercase",
                  }}
                >
                  Поколение
                </WrapTitleDescBlockOpacity>
                <WrapTitleDescBlockOpacity
                  style={{
                    gridArea: "category",
                    justifySelf: "end",
                    textTransform: "uppercase",
                  }}
                >
                  категория заявки
                </WrapTitleDescBlockOpacity>
              </BlockGrid>
              <BlockGrid
                style={{
                  gridArea: "catalog",
                  gap: 10,

                  // gridTemplateAreas: `'title-brand title-model title-generation category'`,
                }}
              >
                {resultBrands.results.map((item, index) => {
                  let styleMainOption = {};
                  if (subMenuOpen !== "-1") {
                    styleMainOption = {
                      filter: subMenuOpen.includes(item.id)
                        ? "unset"
                        : `blur(5px)`,
                      boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
                    };
                  }
                  return (
                    <BlockGrid
                      key={index}
                      style={{
                        gridTemplateColumns: "1fr 6fr 2fr",
                        gap: 10,
                        ...styleMainOption,
                      }}
                    >
                      {/* brands */}
                      <WrapTwoColumnGrid
                        style={{
                          gridTemplateColumns: item?.image ? `10% 90%` : "100%",
                          columnGap: 15,
                        }}
                      >
                        {item?.image && (
                          <Icon image={item.image} width={24} height={24} />
                        )}
                        <BlockTitle
                          style={{
                            top: 2,
                            fontWeight: 500,
                          }}
                        >
                          {item.name}
                        </BlockTitle>
                      </WrapTwoColumnGrid>
                      {/* selct models */}
                      <Select
                        ref={refBrand}
                        id={`model-${item.id}`}
                        data-label={`model-${item.id}`}
                        isDisabled={item.id === 0 || !item.can_click}
                        options={serializerOptions(resultModels)}
                        menuIsOpen={subMenuOpen.includes(item.id)}
                        // value={selectedOptions}
                        menuPlacement={"auto"}
                        maxMenuHeight={200}
                        isLoading={isLoading} // Показывать индикатор загрузки
                        onMenuClose={() => {
                          setSubMenuOpen(`-1`);
                        }}
                        onMenuOpen={() => {
                          if (subMenuOpen.includes(`${item.id}`)) {
                            setSubMenuOpen(`-1`);
                          } else {
                            setSubMenuOpen(`${item.id}`);
                            fetchDataModels({ brand_id: item.id });
                          }
                        }} // Загрузка данных при открытии меню
                        placeholder="Все модели"
                        // isSearchable // Включение поиска
                        components={customComponentsModel} // Кастомизация опций
                        noOptionsMessage={() => "Нет доступных данных"}
                        isSearchable={false}
                        className="select-model"
                        styles={{
                          menu: (provided) => {
                            return {
                              ...provided,
                              zIndex: 999999, // Чтобы выпадающий список не скрывался другими элементами
                            };
                          },
                        }}
                      />

                      {/* checkbox brands */}
                      <BlockGrid
                        style={{
                          gridTemplateColumns: "1fr 1fr",
                          gap: 10,
                          justifySelf: "end",
                          alignSelf: "center",
                        }}
                      >
                        <CheckBox
                          checked={item?.status_unit?.status}
                          disabled={
                            statusLoadingData
                              ? statusLoadingData
                              : !item?.status_unit?.can_change
                          }
                          // disabled={false}
                          onChange={(res) => {
                            handlerSelectItemFilter({
                              id: res.value,
                              sub_type: "unit",
                              list: "brands",
                              checked: res.checked,
                              curTab: 0,
                            });
                          }}
                          name={""}
                          helptext={"Агрегаты"}
                          helpTextStyle={{
                            top: 2,
                          }}
                          style={
                            {
                              // marginRight: isShowButton ? 11 : 0
                              // pointerEvents: 'all': none
                            }
                          }
                          value={+item.id}
                          // className={style['item-filter__container--checked']}
                          id={`check-${item.id}`}
                        />
                        <CheckBox
                          checked={item?.status_spare?.status}
                          disabled={
                            statusLoadingData
                              ? statusLoadingData
                              : !item?.status_spare?.can_change
                          }
                          onChange={(res) => {
                            handlerSelectItemFilter({
                              id: res.value,
                              sub_type: "spare",
                              list: "brands",
                              checked: res.checked,
                              curTab: 0,
                            }); //'spare'
                          }}
                          name={""}
                          helptext={"Запчасти"}
                          helpTextStyle={{
                            top: 2,
                          }}
                          value={+item.id}
                          // className={style['item-filter__container--checked']}
                          id={`check-${item.id}`}
                        />
                      </BlockGrid>
                    </BlockGrid>
                  );
                })}
              </BlockGrid>
            </>
          ) : null}
        </BlockGrid>
        <Offset mt={100} />
        <ButtonDesktopIncomminFilters
          show={showDesktopButton}
          type={"button"}
          name={"Загрузить еще"}
          addClass={"button__controll-roze--full"}
          onClick={handleShowMore}
        />
        <Offset mt={70} />
      </WrapContainer>
    );
  };

export default IncomingRequestUnitSpareDesktop