import React from "react";
import WrapContainer from "../../../View/WrapContainer/WrapContainer";
import Offset from "../../../View/Offset";
import BlockGrid from "../../../View/Blocks/BlockGrid";
import TextArea from "../../../View/TextArea/TextArea";
import ButtonApplyContainer from "../../../Components/ButtonApplySection/ButtonApplyContainer";


const MoneyForIdea = ({
  placeholder,
  textInputFeedback,
  handlerChangeInputText,
}) => {
  return (
    <WrapContainer isDesktop>
      <Offset mt={"var(--offset-top-desktop)"} />

      <BlockGrid addClass="profile-desktop__self-info">
        <TextArea
          // className={'textarea-application-background'}
          value={textInputFeedback}
          name={"description"}
          placeholder={placeholder[0]}
          height={100}
          distationtop={10}
          id={`textarea-1`}
          onChange={(e) => {
            const value = e.target.value;
            handlerChangeInputText({ text: value });
          }}
        />
        <Offset mt={30} />
        <ButtonApplyContainer isDesktop />
      </BlockGrid>
      
    </WrapContainer>
  );
};

export default MoneyForIdea;
