import React from 'react'
import WrapContainerPreloader from '../../../View/Preloaders/WrapContainerPreloader';
import Preloader from '../../../View/Preloaders/Preloader';
import WrapContainer from '../../../View/WrapContainer/WrapContainer';
import Offset from '../../../View/Offset';
import BlockGrid from '../../../View/Blocks/BlockGrid';
import Icon from '../../../View/Icon/Icon';
import BlockTitle from '../../../View/Blocks/BlockTitle';
import CheckBox from '../../../View/CheckBox';

const IncomingRequestTransportDesktop = ({
  listCards = [],
  toolTipAction,
  handlerSelectcardFilter,
  type
}) => {
  if (!listCards?.results?.length)
    return (
      <WrapContainerPreloader>
        Загрузка ... <Preloader />
      </WrapContainerPreloader>
    );
  return (
    <WrapContainer isDesktop style={{
        height: 'auto'
    }}>
      <Offset mt={"var(--offset-top-desktop)"} />

      {listCards.results.length ? (
        listCards.results
          .filter((el) => !el?.isHide)
          .map((card, i) => {
            return (
              <BlockGrid
                key={i}
                style={{
                  gridTemplateColumns: `90% 10%`,
                  display: "grid",
                  padding: "14px 14px 9px",
                }}
              >
                <BlockGrid
                  style={{
                    gridTemplateColumns: card?.image ? `10% 90%` : "100%",
                  }}
                >
                  {card?.image && (
                    <Icon image={card.image} width={24} height={24} />
                  )}
                  <BlockTitle
                    style={{
                      top: 2,
                      fontWeight: 500,
                    }}
                  >
                    {card.name}
                  </BlockTitle>
                </BlockGrid>
                <BlockGrid
                  style={{
                    gridTemplateColumns: "100%",
                    justifyItems: 'end'
                  }}
                >
                  <CheckBox
                    checked={card["status_" + type]?.status}
                    disabled={false}
                    onChange={(res) => {
                      handlerSelectcardFilter({
                        id: res.value,
                        sub_type: type,
                        type,
                        checked: res.checked,
                      }); //
                    }}
                    helpTextStyle={{
                      top: 2,
                    }}
                    style={{
                      pointerEvents: "all",
                    }}
                    value={+card.id}
                    id={`check-${card.id}`}
                  />
                </BlockGrid>
              </BlockGrid>
            );
          })
      ) : (
        <WrapContainerPreloader>
          Загрузка ... <Preloader />
        </WrapContainerPreloader>
      )}
    </WrapContainer>
  );
};

export default IncomingRequestTransportDesktop