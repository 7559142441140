import React from 'react'
import styles from './styles/accordion.module.scss'

export default function ItemAccordionDescription({children, style={}, isOpen}) {
  return (
    <div
      className={styles['accordion__item-description']}
      style={{
        height: isOpen? '100%' : '0',
        visibility: isOpen? 'visible' : 'hidden',
        opacity: isOpen? 1 : 0,
        marginTop: isOpen? 10 : 0,
        transition: 'all .15s ease-in-out',
        color: 'var(--text-color-opacity)',
        fontFamily: 'HelveticaNeue',
        fontWeight: 400,
        fontSize: 12,
        pointerEvents: 'none',
        lineHeight: '18px',
        fontStyle: 'normal',
        ...style
      }}
    >{children}</div>
  )
}
