import React, { Component } from "react";
import WithRouter from "../../../HOC/WithRouter";
import { connectStoreon } from "storeon/react";
import { getLocaleStore, setSessionStore } from "../../../helpers/utils";
import {
  ID_USER_FOR_REVIEW,
  PROFILE_MENU,
  SELF_ID_TELEGRAM,
  TYPE_REVIEW,
} from "../../../helpers/config";
import OwnProfileReview from "./OwnProfileReview";
import {
  ACTION_DELETE_REVIEWS,
  ACTION_GET_FULL_INFO_USER,
  ACTION_GET_REVIEWS_FOR_FULL_INFO_USER,
  ACTION_GET_REVIEWS_FOR_FULL_INFO_USER_RECIEVE,
  ACTION_GET_REVIEWS_FOR_FULL_INFO_USER_SHOW_MORE,
  ACTION_SEND_FEEDBACK_MY_REVIEW,
  ACTION_SET_FEEDBACK_MY_REVIEW_STORE,
  ACTION_SET_INFO_ABOUT_USER_LIST_REVIEW_NULL,
} from "../../../store/raiting-review/raiting-review";
import MyReviewDesktop from "../../../PagesDesktop/Profile/MyReview/MyReviewDesktop";

class OwnProfileReviewComponent extends Component {
  state = {
    showDesktopButton: false,

    // headerTitle: ['Отзывы продавца'],
    listScreen: [
      {
        title: "Хорошие",
        id: 0,
        type: "good",
        active: true,
      },
      {
        title: "Плохие",
        id: 1,
        type: "bad",
        active: false,
      },
    ],
    listScreenReviews: [
      {
        title: "Полученные",
        id: 0,
        type: "received",
        active: true,
      },
      {
        title: "Оставленные",
        id: 1,
        type: "left",
        active: false,
      },
    ],
    statusInputForm: {
      id: null,
      isEnable: false,
    },
  };
  componentDidMount() {
    this.props.controllerHeaderBand({
      currentTextHandlerBand: "Мои отзывы",
      pathBackButton: PROFILE_MENU, //ROOT,
    });

    this.props.dispatch(ACTION_GET_FULL_INFO_USER, {
      user_id: getLocaleStore(SELF_ID_TELEGRAM),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.infoAboutUserListReview !== this.props.infoAboutUserListReview
    ) {
      if (this.props.infoAboutUserListReview?.next_page === "End") {
        this.setState((state) => ({
          ...state,
          showDesktopButton: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          showDesktopButton: true,
        }));
      }
    }
  }

  handleChangeScreen = ({ path, id }) => {
    if (id) {
      return console.log(id);
    }
    this.props.navigate(path);
  };

  handlerChangeSection = (e, type) => {
    e.preventDefault();
    this.props.dispatch(ACTION_SET_INFO_ABOUT_USER_LIST_REVIEW_NULL);
    let typeReviewStatus = this.state.listScreenReviews.filter(
      (el) => el.active
    )[0].type;
    let typeReview = this.state.listScreen.filter((el) => el.active)[0].type;
    setSessionStore(TYPE_REVIEW, typeReview);
    if (type === "bad" || type === "good") {
      setSessionStore(TYPE_REVIEW, type);
      typeReview = type;
      this.setState((state) => ({
        ...state,
        listScreen: state.listScreen.map((el) =>
          type === el.type ? { ...el, active: true } : { ...el, active: false }
        ),
      }));
      if (typeReviewStatus === "received") {
        return this.props.dispatch(ACTION_GET_REVIEWS_FOR_FULL_INFO_USER, {
          user_id: getLocaleStore(ID_USER_FOR_REVIEW),
          type: typeReview,
        });
      } else {
        return this.props.dispatch(
          ACTION_GET_REVIEWS_FOR_FULL_INFO_USER_RECIEVE,
          {
            user_id: getLocaleStore(ID_USER_FOR_REVIEW),
            type: typeReview,
          }
        );
      }
    } else {
      typeReviewStatus = type;
      this.setState((state) => ({
        ...state,
        listScreenReviews: state.listScreenReviews.map((el) =>
          type === el.type ? { ...el, active: true } : { ...el, active: false }
        ),
      }));

      console.log({
        typeReviewStatus,
        typeReview,
      });
      if (typeReviewStatus === "received") {
        return this.props.dispatch(ACTION_GET_REVIEWS_FOR_FULL_INFO_USER, {
          user_id: getLocaleStore(ID_USER_FOR_REVIEW),
          type: typeReview,
        });
      } else {
        return this.props.dispatch(
          ACTION_GET_REVIEWS_FOR_FULL_INFO_USER_RECIEVE,
          {
            user_id: getLocaleStore(ID_USER_FOR_REVIEW),
            type: typeReview,
          }
        );
      }
    }
  };

  changePagination = ({ page }) => {
    let typeReviewStatus = this.state.listScreenReviews.filter(
      (el) => el.active
    )[0].type;
    let typeReview = this.state.listScreen.filter((el) => el.active)[0].type;
    if (typeReviewStatus === "received") {
      return this.props.dispatch(ACTION_GET_REVIEWS_FOR_FULL_INFO_USER, {
        user_id: getLocaleStore(ID_USER_FOR_REVIEW),
        type: typeReview,
        page,
      });
    } else {
      return this.props.dispatch(
        ACTION_GET_REVIEWS_FOR_FULL_INFO_USER_RECIEVE,
        {
          user_id: getLocaleStore(ID_USER_FOR_REVIEW),
          type: typeReview,
          page,
        }
      );
    }
  };

  handlerDeleteReview = (id) => {
    this.props.dispatch(ACTION_DELETE_REVIEWS, { id });
  };
  handlerChangeFeedback = (id) => {
    console.log({ id, props: this.props.textMyFeedbackStore });
    this.setState((state) => ({
      ...state,
      statusInputForm: {
        id,
        isEnable: true,
      },
    }));

    this.props.textMyFeedbackStore.id === id &&
      this.props.textMyFeedbackStore.text &&
      // this.state.statusInputForm.isEnable &&
      this.props.dispatch(ACTION_SEND_FEEDBACK_MY_REVIEW, {
        id,
        // path: PROFILE_MENU,
      });
  };
  handleSetDataValues = (message) => {
    this.props.dispatch(ACTION_SET_FEEDBACK_MY_REVIEW_STORE, message);
  };

  handleShowMore = () => {
    return this.props.dispatch(
      ACTION_GET_REVIEWS_FOR_FULL_INFO_USER_SHOW_MORE,
      {
        user_id: getLocaleStore(ID_USER_FOR_REVIEW),
        page: this.props.infoAboutUserListReview.next_page,
        type: this.state.listScreen.filter((el) => el.active)[0].type,
      }
    );
  };

  render() {
    console.log(this.props.infoAboutUserListReview);
    if (this.props.isDesktop) {
      return (
        <MyReviewDesktop
          showDesktopButton={this.state.showDesktopButton}
          handleShowMore={this.handleShowMore}
          infoUser={this.props.infoAboutUserReview}
          isLeftReview={
            this.state.listScreenReviews.filter((el) => el.active)[0].type ===
            "left"
          }
          // headerTitle={this.state.headerTitle}
          listScreen={this.state.listScreen}
          listScreenReviews={this.state.listScreenReviews}
          changePagination={this.changePagination}
          handlerDeleteReview={this.handlerDeleteReview}
          handlerChangeSection={this.handlerChangeSection}
          reviews={this.props.infoAboutUserListReview}
          handlerChangeFeedback={this.handlerChangeFeedback}
          handleSetDataValues={this.handleSetDataValues}
          statusInputForm={this.state.statusInputForm}
          handleChangeScreen={this.handleChangeScreen}
          textMyFeedbackStore={this.props.textMyFeedbackStore}
        />
      );
    }
    return (
      <OwnProfileReview
        infoUser={this.props.infoAboutUserReview}
        isLeftReview={
          this.state.listScreenReviews.filter((el) => el.active)[0].type ===
          "left"
        }
        // headerTitle={this.state.headerTitle}
        listScreen={this.state.listScreen}
        listScreenReviews={this.state.listScreenReviews}
        changePagination={this.changePagination}
        handlerDeleteReview={this.handlerDeleteReview}
        handlerChangeSection={this.handlerChangeSection}
        reviews={this.props.infoAboutUserListReview}
        handlerChangeFeedback={this.handlerChangeFeedback}
        handleSetDataValues={this.handleSetDataValues}
        statusInputForm={this.state.statusInputForm}
        handleChangeScreen={this.handleChangeScreen}
        textMyFeedbackStore={this.props.textMyFeedbackStore}
      />
    );
  }
}

export default connectStoreon(
  "infoAboutUserReview",
  "textMyFeedbackStore",
  "infoAboutUserListReview",
  WithRouter(OwnProfileReviewComponent)
);
