import { API_GET_NOTIFICATION } from "../../helpers/config";
import { handlerWarningInfoMessageResponse } from "../../helpers/helpers";
import { _INIT, ACTION_GET } from "../api-store/getpage";

export const GET_NOTIFICATION = 'get-notice';
export const SET_NOTIFICATION = 'SET_NOTICE';

export const notifications = store => {

    store.on(_INIT, () => ({
      notifications: {
        count: 0,
        results: [],
        current_page: null,
      },
    }));
    store.on(SET_NOTIFICATION, ({notifications}, data)=>({notifications: {
        ...notifications, 
        ...data,
        current_page: data.current_page,
        results: [...notifications.results, ...data.results]

  
    }}))
    // fetch notifications from server and set it to store

    store.on(GET_NOTIFICATION, async (_, data, {dispatch}) => {
        const url = API_GET_NOTIFICATION
        const params = {
          url,
        //   page_size: 9,
          dataRequst: (res) => {
            if (res === undefined) return;
            const isWarning = handlerWarningInfoMessageResponse(res, dispatch);
            if (isWarning) return;
            dispatch(SET_NOTIFICATION, res);
          },
          ...data,
        };
        dispatch(ACTION_GET, params)
    })
}