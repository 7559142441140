import React, { Component } from "react";
import { connectStoreon } from "storeon/react";
import WithRouter from "../../../HOC/WithRouter";
import { help } from "../../../images";
import IncomingRequestTransport from "./IncomingRequestTransport";
import {
  INCOMING_REQUEST_INFO_SETTING,
  INCOMING_REQUEST_MENU,
} from "../../../helpers/config";
import {
  ACTION_GET_LIST_FILTERS_BY_TYPE,
  ACTION_SAVE_LIST_FILTERS_BY_TYPE_INTO_STORE,
  ACTION_SET_VALUE_INTO_LIST_CARDS_SELECTS_FILTER_NULL,
} from "../../../store/filters/filtersIncominRequest";
import {
  ACTION_SET_BUTTON_HEADER_ACTION,
  ACTION_SET_CONTROLL_BUTTON,
} from "../../../store/helpers/helpers-store";
import IncomingRequestTransportDesktop from "../../../PagesDesktop/IncomingRequests/IncomingRequestTransportDesktop/IncomingRequestTransportDesktop";
import { CHECK_SELECT_FILTERS } from "../../../store/filters/IncomingRequestStore";

class IncomingRequestTransportComponent extends Component {
  state = {
    type: "transport",
    messages: ["Вы можете включить получение заявок на грузоперевозки."],
    toolTipAction: {
      isShow: false,
      id: null,
      message: "",
    },
  };
  componentDidMount() {
    this.props.controllerHeaderBand({
      currentTextHandlerBand: "Услуги по грузоперевозкам",
      pathBackButton: INCOMING_REQUEST_MENU,
    });

    this.props.dispatch(ACTION_SET_BUTTON_HEADER_ACTION, {
      isVisible: true,
      buttons: [
        {
          action: () =>
            this.handleChangeScreen({ path: INCOMING_REQUEST_INFO_SETTING }),
          iconLeft: help,
          styleIconsLeft: { height: 12 },
          title: "Как настроить?",
          className: "button__controll--roze",
        },
      ],
    });
    this.props.dispatch(ACTION_GET_LIST_FILTERS_BY_TYPE, {
      type: this.state.type,
      callback: this.callback,
    });
      if(this.props.isDesktop) setTimeout(() => {
          this.props.dispatch(ACTION_SET_CONTROLL_BUTTON, {
            isActive: false,
            isFetch: false,
            isfixed: true,
            typeButton: "fixed",
            show: true,
            addClass: "button__apply--incomin-request-red",
            title: "Применить",
          });
          this.props.dispatch(CHECK_SELECT_FILTERS, {
            obj: {},
            list: "brands",
            type: "unit_spare",
          });
    
        }, 300);
  }
  componentWillUnmount() {
    if (this.props.isDesktop){
      setTimeout(() => {
        this.props.dispatch(ACTION_SET_CONTROLL_BUTTON, {
          isActive: false,
          isFetch: false,
          typeButton: "fixed",
          show: false,
          addClass: "button__apply--incomin-request-red",
          title: "Применить",
        });
      }, 0);
        this.props.dispatch(ACTION_SET_VALUE_INTO_LIST_CARDS_SELECTS_FILTER_NULL);
    }
  }
  handleChangeScreen = ({ path }) => {
    return this.props.navigate(path);
  };

  handlerSelectcardFilter = ({ id, sub_type, type, checked }) =>
    this.props.dispatch(ACTION_SAVE_LIST_FILTERS_BY_TYPE_INTO_STORE, {
      id,
      sub_type,
      type,
      checked,
    });

  render() {
    if (this.props.isDesktop) {
      return (
        <IncomingRequestTransportDesktop
          type={this.state.type}
          message={this.state.messages[0]}
          listCards={this.props.listCardsByType}
          toolTipAction={this.state.toolTipAction}
          handlerSelectcardFilter={this.handlerSelectcardFilter}
        />
      );
    }
    return (
      <IncomingRequestTransport
        type={this.state.type}
        message={this.state.messages[0]}
        listCards={this.props.listCardsByType}
        toolTipAction={this.state.toolTipAction}
        handlerSelectcardFilter={this.handlerSelectcardFilter}
      />
    );
  }
}

export default connectStoreon(
  "listCardsByType",

  WithRouter(IncomingRequestTransportComponent)
);
