import { activeButtonBootomForConfirm, getOptions, handlerWarningInfoMessageResponse } from "../../helpers/helpers";
import { API_CREATE_REQUEST, API_CREATE_REQUEST_V2, API_DELETE_IMAGE_FROM_REQUEST, API_DELETE_REQUEST, API_DUBLICATE_REQUEST, API_ENGINES_MARKET, API_GET_CARS, API_GET_COUNTRIES, API_GET_INFO_ENGINE, API_GET_PAGE, API_GET_REQUEST_BY_TYPE, API_GET_REQUEST_ENGINE, API_UPDATE_REQUEST, API_UPDATE_REQUEST_ENGINE, DEFAULT_PAGE_SIZE, MAKE_REQUEST_MENU } from "../../helpers/config";
import { contentCopy, docSuccess } from "../../images";
import { ACTION_GET, ACTION_POST, _INIT } from "../api-store/getpage";
import { ACTION_OPEN_MODAL, ACTION_SET_CONTROLL_BUTTON, ACTION_SET_CONTROLL_BUTTON_NULL } from "../helpers/helpers-store";


export const ACTION_GET_LIST_BRANDS = 'getListBrands';
export const ACTION_GET_NEW_LIST_DATA = 'getChangeAggregateRequests';
export const ACTION_SET_LIST_BRANDS = 'setListBrands';
export const ACTION_SET_MY_APPLICATION = 'setPageMyApplication';
export const ACTION_SET_VALUE_UNIT_SPARE = 'setValuesUnitSpare';
export const ACTION_SET_VALUE_UNIT_SPARE_NULL = 'setValuesUnitSpareNull';
export const ACTION_APPLY_AGGREGATE_REQUEST = 'applyAggregateRequests';
export const ACTION_APPLY_UPDATE_REQUEST = 'applyUpdateRequests';
export const ACTION_SET_LIST_MY_APPliCATION = 'setListMyApplication';
export const ACTION_GET_LIST_MY_APPliCATION = 'getListMyApplication';
export const ACTION_GET_LIST_MY_APPliCATION_SHOW_MORE = 'getListMyApplicationShoowMore';
export const ACTION_SET_LIST_COUNTRY = 'setListCountries';
export const ACTION_GET_LIST_COUNTRY = 'getListCountries';
export const ACTION_APPLY_ACTION_INTO_MY_APPLICATION = 'applyActionIntoMyApplication';
export const ACTION_DELETE_IMAGE_FROM_REQUEST = "deleteImageReqest";
export const ACTION_GET_PREPARE_PRODUTS = "getPrepare";
export const ACTION_SET_LIST_PREPARE_PRODUCTS = "setPrepare";
export const ACTION_RESET_LIST_PREPARE_PRODUCTS = "resetPrepare";
export const ACTION_GET_INFO_ENGINE = 'getInfoEngine';
export const ACTION_SET_INFO_ENGINE = 'setInfoEngine';
export const ACTION_INFO_ENGINE_NULL = "nullInfoEngine";

export const requests = store => {

  store.on(_INIT, () => ({}));

  store.on(_INIT, () => ({ pageMyApplication: {} }));
  store.on(ACTION_SET_MY_APPLICATION, ({ pageMyApplication = {} }, data) => ({ pageMyApplication: { ...pageMyApplication, ...data } }));
  
  
  store.on(ACTION_DELETE_IMAGE_FROM_REQUEST, (_,data) => {
    const { id } = data;
  const params = {
    url: API_DELETE_IMAGE_FROM_REQUEST,
id,
  };
});

// *****************************************************************************
store.on(_INIT, () => ({ infoEngine: {} }));
store.on(ACTION_INFO_ENGINE_NULL, () => ({ infoEngine: {} }));
store.on(ACTION_SET_INFO_ENGINE, (_, data) => ({ infoEngine: data }));
store.on(ACTION_GET_INFO_ENGINE, (_, data = {}, { dispatch }) => {
  const {engine_id} = data;
    let params = {
      url: API_GET_INFO_ENGINE,
      engine_id,
      dataRequst: (res) => {
        const isWarning = handlerWarningInfoMessageResponse(res, dispatch);
        if (isWarning) return;
        console.log({res})
        dispatch(ACTION_SET_INFO_ENGINE, res);
      },
      ...data,
    };
    dispatch(ACTION_GET, params);
  });

  store.on(_INIT, () => ({ listBrands: [] }));
  store.on(ACTION_SET_LIST_BRANDS, (_, data) => ({ listBrands: [...data] }));
  store.on(ACTION_GET_LIST_BRANDS, (_, data = {}, { dispatch }) => {
    let params = {
      url: API_GET_CARS,
      page_id: 2,
      type: 'brand',
      dataRequst: (res) => {
        const isWarning = handlerWarningInfoMessageResponse(res, dispatch);
        if (isWarning) return;
        if (res?.length) {
          if(typeof data?.callback === 'function') data.callback();
          dispatch(ACTION_SET_LIST_BRANDS, [...getOptions(res)]);
        }
      },
      ...data,
    };
    dispatch(ACTION_GET, params);
  });

  store.on(ACTION_GET_NEW_LIST_DATA, (_, data, { dispatch }) => {
    let params = {
      type: data.type,
      dataRequst: data.getDataOptionsRequest,
      url: API_GET_CARS,
    };
    if (data.brand_id) {
      params = {
        ...params,
        brand_id: data.brand_id,
      };
    }
    if (data.model_id) {
      params = {
        ...params,
        model_id: data.model_id,
      };
    }
    dispatch(ACTION_GET, params);
  });

  const initValuesAggrigateUnitsSpare = {
    isEngine: false,
    model_id: "",
    generation_id: "",
    engine_id: "",
    brand:null,
    model:null,
    generation:null,
    engine:null,
    optionsModel:[],
    optionsGeneration:[],
    brand_id:"",
    classes:[],
    text:"",
    images:null,
    oem:"",
    phone_number:null,
  };

  store.on(_INIT, () => ({ valuesUnitSpare: initValuesAggrigateUnitsSpare }));
  store.on(ACTION_SET_VALUE_UNIT_SPARE_NULL, () => ({ valuesUnitSpare: initValuesAggrigateUnitsSpare }));
  store.on(ACTION_SET_VALUE_UNIT_SPARE, ({ valuesUnitSpare }, data, { dispatch }) => {
    // определяем с какой вкладкой работаем activeScreen
    //
    let newValuesUnitSpare = { ...valuesUnitSpare, isEngine: false };
    const { activeScreen, desc_action } = data;
    // если нужно использовать поле "движки"
    if (activeScreen === "unit_spare_engine") {
      newValuesUnitSpare = {
        ...newValuesUnitSpare,
        isEngine: true,
      };
    }

    const member = data?.member;
    newValuesUnitSpare = {
      ...newValuesUnitSpare,
      ...data,
    };
    // делаем проверку что бы случайно не очистить при редактировании
    if (data?.brand_id ) {
      newValuesUnitSpare = {
        ...newValuesUnitSpare,
        model_id: "",
        generation_id: "",
        engine_id: "",
      };
      dispatch(ACTION_INFO_ENGINE_NULL);
    }

    if (data?.model_id) {
      newValuesUnitSpare = {
        ...newValuesUnitSpare,
        ...data,
        generation_id: "",
        engine_id: "",
      };
      dispatch(ACTION_INFO_ENGINE_NULL);
    }
    if (data?.generation_id) {
      newValuesUnitSpare = {
        ...newValuesUnitSpare,
        ...data,
        engine_id: "",
      };
      dispatch(ACTION_INFO_ENGINE_NULL);
    }
    // подгружаем инфу по движкам
    if (data?.engine_id && newValuesUnitSpare.isEngine) {
      newValuesUnitSpare = {
        ...newValuesUnitSpare,
        engine_id: data.engine_id,
      };
        dispatch(ACTION_GET_INFO_ENGINE, {
          engine_id: data.engine_id,
        });
    }
    // подгружаем рекомендованные при вводе ориг номера
    if (data?.oem && data.oem.length > 2) {
      const params = {
        oem: data.oem,
      };
      dispatch(ACTION_GET_PREPARE_PRODUTS, params);
    }

    const isActiveButton = activeButtonBootomForConfirm(
      { ...newValuesUnitSpare },
      desc_action,
      () => {},
      member
    );
    if (isActiveButton) {
      dispatch(ACTION_SET_CONTROLL_BUTTON, {
        isActive: true,
        name: "Опубликовать запрос",
        action: () => {
          //  чистим от ненужных данных
          newValuesUnitSpare = {
            ...newValuesUnitSpare,
            desc_action: '',
            brand: '',
            model: '',
            generation: '',
            engine: '',
            optionsModel: [],
            optionsGeneration: [],
          };
          dispatch(ACTION_SET_VALUE_UNIT_SPARE_NULL);
          dispatch(ACTION_SET_CONTROLL_BUTTON_NULL);
          if (data?.edit) {
            dispatch(ACTION_APPLY_UPDATE_REQUEST, { ...newValuesUnitSpare });
          } else {
            dispatch(ACTION_APPLY_AGGREGATE_REQUEST, { ...newValuesUnitSpare });
          }
          dispatch(ACTION_SET_VALUE_UNIT_SPARE_NULL);
          return dispatch(ACTION_SET_CONTROLL_BUTTON_NULL);
        },
      });
    } else {
      dispatch(ACTION_SET_CONTROLL_BUTTON, {
        isActive: false,
      });
    }
    return { valuesUnitSpare: newValuesUnitSpare };
  });
  
  const initListPrepare = {
    count: 0,
    current_page: 0,
    results: [],
  };
  store.on(_INIT, () => ({
    listPrepare: initListPrepare,
  }));
  store.on(ACTION_SET_LIST_PREPARE_PRODUCTS, (_, data) => ({ listPrepare: data }));
  store.on(ACTION_RESET_LIST_PREPARE_PRODUCTS, (_, data) => ({
    listPrepare: initListPrepare,
  }));
  store.on(ACTION_GET_PREPARE_PRODUTS, ({ listPrepare }, data = {}, { dispatch }) => {
    try {
      //92405A7000
      const params = {
        url: API_ENGINES_MARKET,
        page_size: 4,
        page: data?.page ?? 1,
        blockButtonDisabled:true,
        dataRequst: (res) => {
          if (res === undefined) return;
          const isWarning = handlerWarningInfoMessageResponse(res, dispatch);
          if (isWarning) return;
          dispatch(ACTION_SET_LIST_PREPARE_PRODUCTS, {
            ...listPrepare,
            ...res,
            results: data?.page? [...listPrepare.results, ...res.results] : res.results
          });
        },
        ...data,
      };
      dispatch(ACTION_GET, params);
    } catch (error) {
      console.log("erorr catch", error);
    }
  });

  store.on(_INIT, () => ({ listCountries: [] }));
  store.on(ACTION_SET_LIST_COUNTRY, (_, data) => ({ listCountries: [...data] }));
  store.on(ACTION_GET_LIST_COUNTRY, ({ _ }, data = {}, { dispatch }) => {
    try {

      const params = {
        url: API_GET_COUNTRIES,//'/api_get_requests/',
        page: 1,
        page_size: DEFAULT_PAGE_SIZE,
        // setIsLoading: status => data?.isLoading(status),
        dataRequst: res => {
          if (res === undefined) return;
          const isWarning = handlerWarningInfoMessageResponse(res, dispatch);
          if (isWarning) return;
          dispatch(ACTION_SET_LIST_COUNTRY, res)
        },
        ...data
      };
      dispatch(ACTION_GET, params)

    } catch (error) {
      console.log('erorr catch', error)
    }
  });

  store.on(_INIT, () => ({ listMyApplication: {} }));
  store.on(ACTION_SET_LIST_MY_APPliCATION, (_, data) => ({ listMyApplication: { ...data } }));

  store.on(ACTION_GET_LIST_MY_APPliCATION, ({ pageMyApplication }, data = {}, { dispatch }) => {
    const params = {
      url: API_GET_REQUEST_BY_TYPE,//'/api_get_requests/',
      page: pageMyApplication[data.type] ?? 1,
      page_size: DEFAULT_PAGE_SIZE,
      // setIsLoading: status => data?.isLoading(status),
      dataRequst: res => {
        const isWarning = handlerWarningInfoMessageResponse(res, dispatch);
        if (isWarning) return;
        if(typeof data?.callback === 'function') data.callback();
        dispatch(ACTION_SET_MY_APPLICATION, {
          [data.type]: res.current_page
        })
        dispatch(ACTION_SET_LIST_MY_APPliCATION, res)
      },
      ...data
    };
    dispatch(ACTION_GET, params)

  });
  store.on(ACTION_GET_LIST_MY_APPliCATION_SHOW_MORE, ({ listMyApplication }, data = {}, { dispatch }) => {
    const params = {
      url: API_GET_REQUEST_BY_TYPE,//'/api_get_requests/',
      page: data.page,
      page_size: DEFAULT_PAGE_SIZE,
      dataRequst: res => {
        const isWarning = handlerWarningInfoMessageResponse(res, dispatch);
        if (isWarning) return;
        dispatch(ACTION_SET_LIST_MY_APPliCATION, { 
          ...listMyApplication, 
          ...res,
          results: [...listMyApplication.results,...res.results]
         });
      },
      ...data
    };
    dispatch(ACTION_GET, params)

  });


  store.on(ACTION_APPLY_UPDATE_REQUEST, (_, data, { dispatch }) => {
    dispatch(ACTION_SET_CONTROLL_BUTTON, {
      isFetch: true
    })
    let url = API_UPDATE_REQUEST;
    if (data?.isEngine) {
      url = API_UPDATE_REQUEST_ENGINE;
    }
    // чичтим от ненужных данных перед отправкой на сервер
    data = {
      ...data,
      brand: "",
      model: "",
      generation: "",
      engine: "",
      date_create: "",
      id: "",
      user: "",
      isEngine: "",
      activeScreen: '',
    };
    const params = {
      url,
      dataRequst: (res) => {
        const isWarning = handlerWarningInfoMessageResponse(res, dispatch);
        if (isWarning) return;
        if (typeof data?.callback === 'function') data.callback();
        dispatch(ACTION_OPEN_MODAL, {
          show: res?.info?.status,
          content: res?.info?.message,
          contentBtn: 'Ок',
          error: !res?.info?.status,
          path: MAKE_REQUEST_MENU,
          icon: docSuccess
        })

      },
      ...data,
    };
    dispatch(ACTION_POST, params);
  });

  store.on(ACTION_APPLY_AGGREGATE_REQUEST, (_, data, { dispatch }) => {
    dispatch(ACTION_SET_CONTROLL_BUTTON, {
      isFetch: true
    })
    let url = API_CREATE_REQUEST_V2;
    if(data?.engine_id){
      url = API_GET_REQUEST_ENGINE;
    }
    let params = {
      url,
      type: data?.activeScreen,
      // url: API_CREATE_REQUEST,
      dataRequst: (res) => {
        const isWarning = handlerWarningInfoMessageResponse(res, dispatch);
        if (isWarning) return;
        dispatch(ACTION_OPEN_MODAL, {
          show: res?.info?.status,
          content: res?.info?.message,
          contentBtn: "Ок",
          error: !res?.info?.status,
          actionClickOk: () => data?.callback(),
          path: MAKE_REQUEST_MENU,
          icon: docSuccess,
        });
        // if (typeof data?.callback === "function") data.callback();
      },
      ...data,
    };
    // чистим запрос от муссора
    params = {
      ...params,
      activeScreen: "",
      isEngine: "",
    };
    dispatch(ACTION_POST, params);
  });

  store.on(ACTION_APPLY_ACTION_INTO_MY_APPLICATION, ({ pageMyApplication }, data, { dispatch }) => {
    const { action, id, type } = data;
    let params = {}
    switch (action) {
      case 'copy':
        params = { url: API_DUBLICATE_REQUEST, };
        break;
      case 'delete':
        params = { url: API_DELETE_REQUEST, }
        break;
      default: break;
    }
    params = {
      ...params,
      request_id: id,
      type,
      page: pageMyApplication[type] ?? 1,
      dataRequst: (res) => {
        const isWarning = handlerWarningInfoMessageResponse(res, dispatch);
        if (isWarning) return;
        // const copyData = res?.slice();
        dispatch(ACTION_OPEN_MODAL, {
          show: res?.info?.status,
          content: res?.info?.message,
          contentBtn: 'Ок',
          error: !res?.info?.status,
          icon: contentCopy,
          actionClickOk: (e) => dispatch(ACTION_GET_LIST_MY_APPliCATION, { type })
        })
      },
    };
    dispatch(ACTION_GET, params);
  })




}