import React from 'react'
import BlockFlex from '../../../View/Blocks/BlockFlex'

function TitleHeader({
    textHeader
}) {
// if (!textHeader.length) return;
  return (
    <BlockFlex addClass='block__title-container-section'>
        {!!textHeader? (textHeader[0].toUpperCase() + textHeader?.slice(1).toLowerCase()) : null }
    </BlockFlex>
  )
}

export default TitleHeader