import React from "react";
import styles from './styles/wrappercontainerfilters.module.scss';

const WrapContainerFilters = ({
  column,
  children,
  style = {},
  isBlur
}) => {

  if (isBlur) {
    style = { ...style, filter: `blur(4px)` }
  }
  return (
    <div
      className={styles['wrapper__container']}
      style={{

        ...style,
      }}
    >
      {children}
    </div>
  )
}

export default WrapContainerFilters;