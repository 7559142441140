import React from 'react'
import TextTitle from '../../../View/Text/TextTitle';
import Offset from '../../../View/Offset';
import BlockFlex from '../../../View/Blocks/BlockFlex';
import SearchComponent from '../../SearchSection/SearchComponent';
import { ACTION_SET_TEXT_SEARCH_MARKET_CARDS } from '../../../store/marketplace/marketplace';
import FavoriteContainer from '../../../View/BoxContainerFor/FaforiteConrainer/FavoriteContainer';
import { MARKETPLACE_FAVORITE } from '../../../helpers/config';
import Icon from '../../../View/Icon/Icon';
import { cross, heardRedBorder, sort } from '../../../images';
import BadgeFavorite from '../../../View/BoxContainerFor/Badge/BadgeFavorite';
import { Link } from 'react-router-dom';
import BlockGrid from '../../../View/Blocks/BlockGrid';
import ReactSelectComponent from '../../../View/Select/NativeSelect/ReactSelectComponent';
import { getOptionsFiltersMP } from '../../../helpers/helpers';
import WrapRowGrid from '../../../View/Blocks/WrapRowGrid';
import Input from '../../../View/Input/Input';
import CardMPItem from '../../../View/Cards/CardMPItem';
import Button from '../../../View/Button/Button';
import WrapContainerPreloader from '../../../View/Preloaders/WrapContainerPreloader';
import Preloader from '../../../View/Preloaders/Preloader';
import ButtonDesktop from '../../../View/ButtonDesktop/ButtonDesktop';
import { WithFavorite } from '../../../HOC/WithFavorite';

const CatalogComponentDesktop = ({
  option,
  dispatch,
  listCards,
  showParams,
  countFavorite,
  handleShowMore,
  toggleFavorite,
  textInputSearch,
  listOptionsAuto,
  showDesktopButton,
  handlerTextSearch,
  listOptionsCountry,
  handlerResetParams,
  handlerChangeFilters,
  handlerShowHideParams,
}) => {
  return (
    <>
      <TextTitle type={"h5"} addClass="text__main-desktop-title">
        Поиск по объявлениям
      </TextTitle>
      <Offset mt={15} />
      <BlockFlex addClass="block__main-desktop-container-filters">
        <SearchComponent
          style={{
            width: `none`,
            height: 46,
            // maxWidth: 250,
          }}
          isIconLeft
          isButtonRight
          enteredText={textInputSearch} // текущий текст
          getResultSearch={handlerTextSearch} // функция обработки запроса
          actionDisptchGetSearch={ACTION_SET_TEXT_SEARCH_MARKET_CARDS} // где хроним текст
        />
        <FavoriteContainer
          addClass={"favorite__container-desktop"}
          style={{ marginLeft: 5, maxWidth: "150px" }}
        >
          <Link to={MARKETPLACE_FAVORITE}>
            <Icon image={heardRedBorder} width={14} height={14} />
            <TextTitle>Избранное</TextTitle>
            {!!countFavorite && <BadgeFavorite>{countFavorite}</BadgeFavorite>}
          </Link>
        </FavoriteContainer>
        <Button
          iconLeft={sort}
          classNameIcon={"size-15"}
          addClass={"button__main-desktop-sort"}
          style={{
            maxWidth: 114,
          }}
          onClick={(e) => {
            e.preventDefault();
            handlerChangeFilters({
              key:
                +option.optinsFastFilter === 1
                  ? "order_by_high_price"
                  : "order_by_low_price",
              value: +option.optinsFastFilter === 1 ? 2 : 1,
              id: true,
            });
          }}
        >
          <TextTitle>Сортировка</TextTitle>
        </Button>
      </BlockFlex>
      <Offset mt={20} />
      {/* params */}
      <BlockFlex addClass="block__main-desktop-container-params-title">
        <TextTitle type={"h6"} addClass="text__main-desktop-title">
          Параметры
        </TextTitle>
        <BlockGrid addClass="block__main-desktop-container-params-controller">
          <Button
            onClick={handlerResetParams}
            addClass="button__main-desktop-params-reset"
          >
            Сбросить
          </Button>
          <Button
            onClick={handlerShowHideParams}
            iconRight={showParams ? cross : ""}
            styleIconsRight={{ height: 10 }}
            addClass="button__main-desktop-params-showhide"
          >
            {showParams ? "Скрыть" : "Показать"}
          </Button>
        </BlockGrid>
      </BlockFlex>
      {showParams ? (
        <BlockGrid addClass="block__main-desktop-container-params">
          <ReactSelectComponent
            data={getOptionsFiltersMP(listOptionsCountry.countrys)}
            enabled={true}
            clearValue={!!!option?.country}
            classNamePrefix="mp-desktop-filter"
            placeholder={"Страна"}
            onChange={(value) => {
              handlerChangeFilters({
                key: "country",
                value: value,
              });
            }}
          />
          <ReactSelectComponent
            data={
              (listOptionsCountry?.citys &&
                getOptionsFiltersMP(listOptionsCountry.citys)) ??
              []
            }
            enabled={!!option?.country}
            clearValue={!!!option?.city}
            classNamePrefix="mp-desktop-filter"
            placeholder={"Город"}
            onChange={(value) => {
              handlerChangeFilters({
                key: "city",
                value: value,
              });
            }}
          />

          <ReactSelectComponent
            data={getOptionsFiltersMP(listOptionsAuto.brands)}
            enabled={true}
            clearValue={!!!option?.brand}
            classNamePrefix="mp-desktop-filter"
            placeholder={"Марка"}
            onChange={(value) => {
              handlerChangeFilters({
                key: "brand",
                value: value,
              });
            }}
          />

          <ReactSelectComponent
            data={
              (listOptionsAuto?.models &&
                getOptionsFiltersMP(listOptionsAuto.models)) ??
              []
            }
            enabled={!!option?.brand}
            clearValue={!!!option?.model}
            classNamePrefix="mp-desktop-filter"
            placeholder={"Модель"}
            onChange={(value) => {
              handlerChangeFilters({
                key: "model",
                value: value,
              });
            }}
          />
          <ReactSelectComponent
            data={
              (listOptionsAuto?.generations &&
                getOptionsFiltersMP(listOptionsAuto.generations)) ??
              []
            }
            enabled={!!option?.model}
            clearValue={!!!option?.generation}
            classNamePrefix="mp-desktop-filter"
            placeholder={"Поколение"}
            onChange={(value) => {
              handlerChangeFilters({
                key: "generation",
                value: value,
              });
            }}
          />

          <WrapRowGrid>
            <Input
              value={option?.price_from ?? ""}
              placeholder={"От  50 000"}
              height={46}
              styleInput={{
                padding: 5,
              }}
              onChange={(e) => {
                const value = e.target.value;
                handlerChangeFilters({
                  key: "price_from",
                  id: value,
                });
              }}
            />
          </WrapRowGrid>
          <WrapRowGrid>
            <Input
              value={option?.price_to ?? ""}
              placeholder={"До  1 000 000"}
              height={46}
              styleInput={{
                padding: 5,
              }}
              onChange={(e) => {
                const value = e.target.value;
                handlerChangeFilters({
                  key: "price_to",
                  id: value,
                });
              }}
            />
          </WrapRowGrid>
        </BlockGrid>
      ) : null}
      <Offset mt={20} />

      <BlockGrid addClass="block__main-desktop-container-catalog">
        {listCards.count ? (
          listCards.results.map((item, i) => {
            return (
              <CardMPItem
                key={i}
                item={item}
                dispatch={dispatch}
                catalog
                hendlerFavorite={toggleFavorite}
              />
            );
          })
        ) : (
          <WrapContainerPreloader empty="Ничего не найдено">
            Загрузка ... <Preloader />
          </WrapContainerPreloader>
        )}
      </BlockGrid>
      <Offset mt={30} />
      <ButtonDesktop
        show={showDesktopButton}
        isActive={showDesktopButton}
        type={"button"}
        name={"Загрузить еще"}
        addClass={"button__controll-roze--full"}
        onClick={handleShowMore}
      />
    </>
  );
};

export default WithFavorite( CatalogComponentDesktop)