import React, { Component } from 'react'
import HeaderButton from './HeaderButton'
import { connectStoreon } from 'storeon/react'

export class HeaderButtonContainer extends Component {
  render() {
    return (
      <HeaderButton
        buttonHeaderAction={
          this.props.buttonsHeader ?? this.props.buttonHeaderAction
        }
      />
    );
  }
}

export default connectStoreon( 'buttonHeaderAction', HeaderButtonContainer)