import React, { useState } from "react";
import styles from './styles/preloader.module.scss'
const WrapContainerPreloader = ({ children, empty, style={} }) => {
  const [ message, setMessage ] = useState('');

  if(empty){
    setTimeout(()=>{
      setMessage(empty)
    }, 2500)
  }
  return (
    <div className={styles['preload__wrap-container']} 
      style={style}
    >
      {message? message : children}     
    </div>
  )
}

export default WrapContainerPreloader;