import React from "react";
import WrapContainer from "../../View/WrapContainer/WrapContainer";
import Offset from "../../View/Offset";
import BlockGrid from "../../View/Blocks/BlockGrid";
import { Formik } from "formik";
import { reviewSchema } from "../../helpers/schemaValidations/schemaValidate";
import { messageErrorValidation } from "../../helpers/schemaValidations/messgeSchemaValidations";
import { USERNAME } from "../../helpers/config";
import { getLocaleStore } from "../../helpers/utils";
import { ACTION_SET_CONTROLL_BUTTON } from "../../store/helpers/helpers-store";
import Form from "../../View/Form/Form";
import Label from "../../View/Label/Label";
import Input from "../../View/Input/Input";
import RaitingContainer from "../../View/Raiting/RaitingContainer";
import TextArea from "../../View/TextArea/TextArea";
import ButtonApplyContainer from "../../Components/ButtonApplySection/ButtonApplyContainer";
import ModalDesktop from "../../Components/Desktop/ModalDesktop/ModalDesktop";

const CreateReviewDesktop = ({ dispatch, handleSetDataValues }) => {
  return (
    <WrapContainer isDesktop>
      <Offset mt={"var(--offset-top-desktop)"} />
      <BlockGrid
        addClass="profile-desktop__self-info"
        style={{ gridTemplateColumns: "1fr 1fr" }}
      >
        <Formik
          validationSchema={reviewSchema(messageErrorValidation)}
          initialValues={{
            text: "",
            rating: 0,
            username: getLocaleStore(USERNAME) ?? "",
          }}
        >
          {({
            values,
            errors,
            handleSubmit,
            touched,
            handleBlur,
            setFieldValue,
            resetForm,
          }) => {
            let resetDataForm = (setFieldValue) => {
              console.log(setFieldValue);
              setFieldValue("text", "");
              setFieldValue("rating", 0);
              // handleSetDataValues({ text: "", rating: 0 });
            };
            dispatch(ACTION_SET_CONTROLL_BUTTON, {
              buttonForm: () => {
                handleSubmit();
                console.log("click reset form");
                resetDataForm(setFieldValue);
              },
              resetDataForm: () => resetDataForm(setFieldValue),
            });

            const handlerChangeReviewStar = (data) => {
              const amountStar = data.target.getAttribute("value");
              setFieldValue("rating", amountStar);
              handleSetDataValues({ rating: amountStar });
              !!values?.username &&
                handleSetDataValues({ [USERNAME]: values.username });
            };
            return (
              <Form>
                <Offset mt={15} />
                <Label style={{ fontWeight: 700 }}>Продавец</Label>
                <Offset mt={4} />
                <div>
                  <Input
                    value={values.username}
                    height={48}
                    disabled={getLocaleStore(USERNAME)}
                    placeholder={"Введите имя пользователя"}
                    distationtop={10}
                    style={{
                      border:
                        touched?.username && errors?.username
                          ? "1px solid #ff0000"
                          : "",
                      paddingLeft: 8,
                      top: 4,
                    }}
                    name={USERNAME}
                    onBlur={handleBlur}
                    helptext={touched?.username && errors?.username}
                    stylehelptext={{
                      color: "var(--text-color-red)",
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      setFieldValue(USERNAME, value);
                      handleSetDataValues({ [USERNAME]: value });
                    }}
                  />
                </div>
                <Offset mt={27} />
                <Label style={{ fontWeight: 700, letterSpacing: "0px" }}>
                  Ваша оценка
                </Label>
                <Offset mt={3} />

                <RaitingContainer
                  max={5}
                  value={values.rating}
                  onChange={(e) => handlerChangeReviewStar(e, values)}
                  sizeStarHeight={24}
                  sizeStarWidth={24}
                  isBorderDigital
                  onBlur={handleBlur}
                  helptext={touched?.rating && errors?.rating}
                  stylehelptext={{
                    color: "var(--text-color-red)",
                  }}
                />
                <Offset mt={16} />
                <Label style={{ fontWeight: 700, letterSpacing: "0px" }}>
                  Напишите отзыв
                </Label>
                <Offset mt={7} />
                <div>
                  <TextArea
                    className={"textarea-application"}
                    value={values.text}
                    name={"text"}
                    placeholder={`Опишите ваше впечатление о продавце. Выделите плюсы или минусы работы`}
                    height={90}
                    id={`textarea-1`}
                    style={{
                      border:
                        touched?.text && errors?.text
                          ? "1px solid #ff0000"
                          : "",
                    }}
                    onBlur={handleBlur}
                    helptext={touched?.text && errors?.text}
                    stylehelptext={{
                      color: "var(--text-color-red)",
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      setFieldValue("text", value);
                      handleSetDataValues({ text: value });
                      !!values?.username &&
                        handleSetDataValues({
                          [USERNAME]: values.username,
                        });
                    }}
                  />
                </div>
                <Offset mt={37} />
                <ButtonApplyContainer isDesktop />
              </Form>
            );
          }}
        </Formik>
      </BlockGrid>
    </WrapContainer>
  );
};

export default CreateReviewDesktop;
