import React from 'react'
import Button from '../../../View/Button/Button';
import BlockFlex from '../../../View/Blocks/BlockFlex';

const HeaderButton = ({buttonHeaderAction}) => {
  return (
        <BlockFlex addClass='block__header-right-action-container'>
          { buttonHeaderAction.buttons.map((button, i) => {
            return (
              <Button
                addClass={button.className}
                iconLeft={button?.iconLeft}
                style={button?.style ?? {}}
                styleIconsLeft={button?.styleIconsLeft}
                styleIconsRight={button?.styleIconsRight}
                onClick={button.action}
                iconRight={button?.iconRight}
                key={i}
              >
                <p>{button.title}</p>
              </Button>
            );
          })}
        </BlockFlex>
  );
}

export default HeaderButton