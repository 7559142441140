import React from 'react'
import ProfileContainer from '../../Pages/Profile/ProfileContainer'

const ProfileDesktopContainer = () => {
  return (
    <ProfileContainer 
        isDesktop
    />
  )
}

export default ProfileDesktopContainer