import React, { Component } from 'react'
import { ACTION_SET_BUTTON_HEADER_ACTION } from '../../store/helpers/helpers-store';
import { bell, plus, plusDarkBlue, userWhite } from '../../images';
import { MAKE_REQUEST_MENU, MARKETPLACE_OWN_CARDS, PROFILE_MENU } from '../../helpers/config';
import { connectStoreon } from 'storeon/react';
import WithRouter from '../../HOC/WithRouter';
import { openOnlyURl } from '../../helpers/helpers';
import MainPageDesktop from './MainPageDesktop';
import { ACTION_GET_LIST_CARDS_MARKET } from '../../store/marketplace/marketplace';
import { ACTION_GET_LIST_OPTIONS_MARKET_CATEGORY, ACTION_LIST_OPTIONS_MARKET, ACTION_RESET_FILTER_INTO_MARKET, ACTION_SET_FILTER_INTO_MARKET } from '../../store/marketplace/filtermarketplace/filtermarketplace';

export class MainPageDesktopContainer extends Component {
  state = {
    listFAQ: [
      {
        title: "Как создать запрос на агрегат?",
        description:
          "Чтобы найти запчасть, нажмите: «Синяя кнопка меню — Запрос — агрегат/запчасть.» ",
        icon: plusDarkBlue,
        id: 0,
      },
      {
        title: "Что делать, если истекает подписка?",
        description:
          "Оплата подписки: Синяя кнопка меню — профиль  — подписка. Выберите необходимый доступ.",
        icon: plusDarkBlue,
        id: 1,
      },
      {
        title: "Как настроить входящие заявки?",
        description:
          "Чтобы начать получать целевые Входящие заявки, нажмите: Синяя кнопка меню — Входящие заявки — и выберите марки, модели и поколения авто от которых у вас есть запчасти.",
        icon: plusDarkBlue,
        id: 2,
      },
    ],
    showParams: true,
  };
  componentDidMount() {

    this.props.controllerHeaderBand({
      currentTextHandlerBand: "Главное меню",
    });
    window.performance.mark("mark_fully_loaded");

    this.props.dispatch(ACTION_SET_BUTTON_HEADER_ACTION, {
      isVisible: true,
      buttons: [
        {
          action: () => this.handleChangeScreen({ path: MAKE_REQUEST_MENU }),
          iconLeft: plus,
          styleIconsLeft: { height: 12 },
          title: "Создать запрос",
          className: "button__controll--red",
        },
      ],
    });
  }
  callback = () => {
    this.setState((state) => ({
      ...state,
      isLoading: false,
    }));
  };
  handleChangeScreen = ({ path }) => {
    if (path.includes("http")) {
      return openOnlyURl(path);
    }
    this.props.navigate(path);
  };



  render() {
    return (
      <MainPageDesktop
        listFAQ={this.state.listFAQ}     
      />
    );
  }
}

export default connectStoreon(
  "optionMP",
  "mainListMP",
  "favoriteMPCards",
  "listOptionsMPAuto",
  "listOptionsMPCountry",
  "textInputMarketSearch",
  WithRouter(MainPageDesktopContainer)
);