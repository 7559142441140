import React, { Component } from "react";
import {
  getLocaleStore,
  getDataOptionsRequest,
  setLocaleStore,
} from "../../../helpers/utils";
import UnitSpareRequest from "../UnitRequest/UnitSpareRequest";
import WithRouter from "../../../HOC/WithRouter";
import { connectStoreon } from "storeon/react";
import {
  ACTION_GET_INFO_ENGINE,
  ACTION_GET_LIST_BRANDS,
  ACTION_GET_LIST_MY_APPliCATION,
  ACTION_GET_NEW_LIST_DATA,
  ACTION_INFO_ENGINE_NULL,
  ACTION_SET_VALUE_UNIT_SPARE,
} from "../../../store/requests/requests";
import {
  LAST_PATH_OWN_REQUEST,
  MAKE_REQUEST_MENU,
} from "../../../helpers/config";
import {
  ACTION_OPEN_MODAL,
  ACTION_SET_BUTTON_HEADER_ACTION,
} from "../../../store/helpers/helpers-store";
import { plus } from "../../../images";
import { delay } from "../../../helpers/const";
import TransportRequest from "../TransportRequest/TransportRequest";
import TruckRequest from "../TruckRequest/TruckRequest";

class OwnRequestEditComponent extends Component {
  state = {
    tab: getLocaleStore("itemTabRequest"),
    data: {},
    type: "unit",
    desc_action: "edit",
    activeScreen: ["unit_spare", "unit_spare_engine"],
    delete_request_images: [],
    isAddMultiData: true,
    screensSwitching: [
      {
        title: "Без двигателя",
        id: 0,
        type: "classes",
        active: true,
        enable: true,
      },
      {
        title: "С двигателем",
        id: 1,
        type: "engine",
        active: false,
        enable: true,
      },
    ],
  };
  componentDidMount() {
    const loadData = async () => {
      const callback = async ()=>{
        console.log('callback done')
        await delay(500)
        this.dataCurrentRequest();

      }
      const loadMyCard = async () => {
        this.props.dispatch(ACTION_GET_LIST_MY_APPliCATION, {
          type: getLocaleStore("itemTabRequest"),
          page_size: 9999,
          callback
        });
      };
      if (
        getLocaleStore("itemTabRequest") === "unit" ||
        getLocaleStore("itemTabRequest") === "spare"
      ) {
        this.props.dispatch(ACTION_GET_LIST_BRANDS, {
          callback: async () => {
            await delay(1500)
            loadMyCard();
          },
        });
      } else if (
        getLocaleStore("itemTabRequest") === "transport" ||
        getLocaleStore("itemTabRequest") === "truck"
      ) {
        loadMyCard();
      }
    };

    loadData();

    this.props.controllerHeaderBand({
      currentTextHandlerBand: "Мои запросы",
      pathBackButton: getLocaleStore(LAST_PATH_OWN_REQUEST),
    });

    this.props.dispatch(ACTION_SET_BUTTON_HEADER_ACTION, {
      isVisible: true,
      buttons: [
        {
          action: () => this.handleChangeScreen({ path: MAKE_REQUEST_MENU }),
          iconLeft: plus,
          styleIconsLeft: { height: 12 },
          title: "Создать запрос",
          className: "button__controll--red",
        },
      ],
    });

  }

  handlerSwitchScreen = (e, type) => {

    this.setState((state) => ({
      ...state,
      screensSwitching: state.screensSwitching.map((el) =>
        type === el.type ? { ...el, active: true } : { ...el, active: false }
      ),
    }));
  };
  handleChangeScreen = ({ path }) => {
    this.props.navigate(path);
  };

  handleGetDataOption = (changeData) =>
    this.props.dispatch(ACTION_GET_NEW_LIST_DATA, { ...changeData });

  handleSetDataValues = (values) => {
    console.log('set value into store')
    // if (values?.brand_id) this.dataCurrentRequest(values);
    this.props.dispatch(ACTION_SET_VALUE_UNIT_SPARE, {
      type: getLocaleStore("itemTabRequest"),
      desc_action: this.state.desc_action + "_" + getLocaleStore("itemTabRequest"),
      edit: true,
      // isEngine: this.state.screensSwitching[1].active,
      request_id: +getLocaleStore("itemIdOwnRequest"),
      activeScreen:
        this.state.activeScreen[
          this.state.screensSwitching.filter((el) => el.active)[0].id
        ],
      ...values,
    });
    if (this.state.screensSwitching[0].active && values.generation_id) {
      setLocaleStore("isAddMultiData", false);
      this.setState((state) => ({
        ...state,
        isAddMultiData: false,
      }));
    }
  };
  dataCurrentRequest = (values) => {

    let res =
      this.props.listMyApplication.results &&
      this.props.listMyApplication.results.filter(
        (el) => el.id === +getLocaleStore("itemIdOwnRequest")
      )[0];
      if(res === undefined) return window.alert('Error get data');
    const screen = this.state.screensSwitching.map((el) =>
      res?.engine_id && el.id === 1
        ? { ...el, active: true, enable: true }
        : !res?.engine_id && el.id === 0
        ? { ...el, active: true, enable: true }
        : { ...el, active: false, enable: false}
    );
    this.setState((state) => ({
      ...state,
      screensSwitching: screen,
      isAddMultiData: !!!res.classes.length,
    }));
    this.props.dispatch(ACTION_SET_VALUE_UNIT_SPARE, {
      ...res,
      type: getLocaleStore("itemTabRequest"),
      desc_action:
        this.state.desc_action + "_" + getLocaleStore("itemTabRequest"),
      edit: true,
      activeScreen:
        this.state.activeScreen[screen.filter((el) => el.active)[0].id],
      request_id: +getLocaleStore("itemIdOwnRequest"),
    });
  };
  handlerDeleteImage = (data) => {
    this.setState((state) => ({
      ...state,
      delete_request_images: state.delete_request_images.push(data.id),
    }));
    this.props.dispatch(ACTION_SET_VALUE_UNIT_SPARE, {
      type: getLocaleStore("itemTabRequest"),
      desc_action:
        this.state.desc_action + "_" + getLocaleStore("itemTabRequest"),
      edit: true,
      delete_request_images: [...this.state.delete_request_images, data.id],
      request_id: +getLocaleStore("itemIdOwnRequest"),
      activeScreen:
        this.state.activeScreen[
          this.state.screensSwitching.filter((el) => el.active)[0].id
        ],
      // isEngine: this.state.screensSwitching[1].active,
      callback: data.callback,
    });
  };
  deleteClasses = (id, values, setFieldValue) => {
    if (values?.classes.length === 1) {
      this.props.dispatch(ACTION_OPEN_MODAL, {
        show: true,
        content: "Вы не можете удалить \nпоследнее описание марки с сервера",
      });
      return;
    }
    setFieldValue(
      "classes",
      values.classes.filter((el, i) => i !== id)
    );
    this.handleSetDataValues({
      ...values,
      classes: values.classes.filter((el, i) => i !== id),
    });
    if (values.classes.filter((el, i) => i !== id).length === 0) {
      setFieldValue("model_id", "");
      setFieldValue("generation_id", "");
      setLocaleStore("isAddMultiData", true);
      this.setState((state) => ({
        ...state,
        isAddMultiData: true,
      }));
    }
  };
  handlerAddClasses = (setFieldValue, brand_id) => {
    this.handleGetDataOption({
      type: "model",
      brand_id: brand_id,
      getDataOptionsRequest: (res) => {
        const copyData = res.slice();
        return getDataOptionsRequest({
          setFieldValue,
          results: copyData,
          type: "model",
        });
      },
    });
    setFieldValue("brand_id", brand_id);
    setFieldValue("model_id", "");
    setFieldValue("generation_id", "");
    setLocaleStore("isAddMultiData", true);

    this.setState((state) => ({
      ...state,
      isAddMultiData: true,
    }));
  };
  changeState = () => {
    setLocaleStore("isAddMultiData", true);
    this.setState((state) => ({
      ...state,
      isAddMultiData: true,
    }));
  };

  render() {
    if (this.state.tab === "unit" || this.state.tab === "spare") {
      console.log("data value = ", this.props.valuesUnitSpare);
      return (
        <UnitSpareRequest
          edit
          listScreen={this.state.screensSwitching}
          isEngine={this.props.valuesUnitSpare.isEngine}
          valuesUnitSpare={this.props.valuesUnitSpare}
          infoEngine={this.props.infoEngine}
          handlerSwitchScreen={this.handlerSwitchScreen}
          isDesktop={this.props.isDesktop}
          listPhons={this.props.access?.status_data?.phone_list}
          dataRequstBrand={this.props.listBrands}
          dispatch={this.props.dispatch}
          handleGetDataOption={this.handleGetDataOption}
          handleSetDataValues={this.handleSetDataValues}
          handlerDeleteImage={this.handlerDeleteImage}
          isAddMultiData={this.state.isAddMultiData}
          handlerAddClasses={this.handlerAddClasses}
          deleteClasses={this.deleteClasses}
          changeState={this.changeState}
          type={this.props?.valuesUnitSpare?.type}
        />
      );
    }
    if (getLocaleStore("itemTabRequest") === "transport") {
      return (
        <TransportRequest
          edit
          isDesktop={this.props.isDesktop}
          dispatch={this.props.dispatch}
          handleSetDataValues={this.handleSetDataValues}
          dataCurrentRequest={this.props.valuesUnitSpare}
        />
      );
    }
    if (getLocaleStore("itemTabRequest") === "truck") {
      return (
        <TruckRequest
          edit
          isDesktop={this.props.isDesktop}
          dispatch={this.props.dispatch}
          handleSetDataValues={this.handleSetDataValues}
          dataCurrentRequest={this.props.valuesUnitSpare}
        />
      );
    }
    return <div>OwnRequestEditComponent</div>;
  }
}
export default connectStoreon(
  "access",
  "infoEngine",
  "listBrands",
  "pageMyApplication",
  "listMyApplication",
  "valuesUnitSpare",
  WithRouter(OwnRequestEditComponent)
);
