import React from 'react'
import WrapContainer from '../../View/WrapContainer/WrapContainer'
import UserName from '../../View/User/UserName';
import UserCity from '../../View/User/UserCity';
import UserPhone from '../../View/User/UserPhone';
import UserInfoLink from '../../View/User/UserInfoLink';
import Menu from '../../View/Menu/Menu';
import BlockGrid from '../../View/Blocks/BlockGrid';
import Button from '../../View/Button/Button';
import { arrowRightRed, attention, background, penRed } from '../../images';
import { linksFeedback, PROFILE_EDIT, PROFILE_INFO, PROFILE_SUBSCRIBE } from '../../helpers/config';
import Offset from '../../View/Offset';
import BlockTitle from '../../View/Blocks/BlockTitle';
import { selectLinkFromText } from '../../helpers/helpers';
import WrapTwoColumnGrid from '../../View/Blocks/WrapTwoColumnGrid';
import WrapTitleDescBlockOpacity from '../../View/Blocks/WrapTitleDescBlockOpacity';
import { CheckBox } from 'react-native-web';
import HeaderTitleActionComponent from '../../Components/Component.HeaderTitleAction/HeaderTitleActionComponent';
import WrapContainerBlockBorder from '../../View/Blocks/WrapContainerBlockBorder';
import WrapRowGrid from '../../View/Blocks/WrapRowGrid';
import InfoContainer from '../../View/InfoBlock/InfoContainer';
import InfoIconBlock from '../../View/InfoBlock/InfoIconBlock';
import Icon from '../../View/Icon/Icon';
import InfoTextBlock from '../../View/InfoBlock/InfoTextBlock';
import WrapIconBlock from '../../View/Blocks/WrapIconBlock';
import MainFeedbackComponent from '../../Pages/FeedbackPage/FeedbackPage';
import WrapContainerBlock from '../../View/Blocks/WrapContainerBlock';
          
const ProfileDesktop = ({
  listMenu,
  infoPayment,
  dataEarnings,
  statusPayment,
  handlerSetData,
  handlerCopyText,
  profileInfoData,
  handleChangeScreen,
  headerTitleUsefulInfo,
  handlerShowInfoEarnings,
  headerTitleInfoAboutProduct,
}) => {
  return (
    <WrapContainer isDesktop>
       <Offset mt={'var(--offset-top-desktop)'} />
      <BlockGrid
        style={{
          gridTemplateColumns: "1.27fr 1.27fr 0.73fr 0.73fr 1fr",
          gridTemplateAreas: `'name link city phone edit-profile'
          'info-earn info-earn info-earn info-earn info-earn'
          'earn earn earn earn earn'
        'menu menu menu menu menu'`,
        }}
        addClass="profile-desktop__self-info"
      >
        <UserName
          profileInfoData={profileInfoData}
          style={{ gridArea: "name" }}
          isDesktop
        />
        <UserInfoLink
          profileInfoData={profileInfoData}
          style={{ gridArea: "link" }}
        />
        <UserCity
          profileInfoData={profileInfoData}
          style={{ gridArea: "city" }}
        />
        <UserPhone
          profileInfoData={profileInfoData}
          style={{ gridArea: "phone" }}
        />

        <Button
          addClass={"button__edit-my-profile"}
          iconLeft={penRed}
          style={{
            gridArea: "edit-profile",
            justifySelf: "end",
            marginRight: 0,
          }}
          styleIconsLeft={{ width: 12, height: 12 }}
          onClick={() => handleChangeScreen({ path: PROFILE_EDIT })}
        >
          Редактировать профиль
        </Button>
        {!!Object.keys(dataEarnings).length && (
          <div style={{ gridArea: "info-earn", marginTop: 20 }}>
            <BlockTitle style={{ whiteSpace: "pre-line" }}>
              <span
                dangerouslySetInnerHTML={{
                  __html: selectLinkFromText(dataEarnings.info.message),
                }}
              ></span>
            </BlockTitle>
            <WrapTwoColumnGrid
              style={{
                gridTemplateColumns: `80% 20%`,
                justifyItems: "start",
                gap: 10,
              }}
            >
              <BlockTitle>Количество приглашенных</BlockTitle>
              <WrapTitleDescBlockOpacity>
                {dataEarnings.count_invited}
              </WrapTitleDescBlockOpacity>
            </WrapTwoColumnGrid>
            <WrapTwoColumnGrid
              style={{
                gridTemplateColumns: `80% 20%`,
                justifyItems: "start",
                gap: 10,
              }}
            >
              <BlockTitle>Сумма</BlockTitle>
              <WrapTitleDescBlockOpacity>
                {dataEarnings.total_sum}
              </WrapTitleDescBlockOpacity>
            </WrapTwoColumnGrid>

            {dataEarnings.link}
            <Button
              addClass={"button__copy-text"}
              onClick={() => handlerCopyText(dataEarnings.link)}
            >
              Скопировть
            </Button>
            <Offset mb={20} />
          </div>
        )}
        <Button
          addClass={"button__edit-my-profile--full"}
          style={{ gridArea: "earn", marginTop: 20 }}
          styleIconsLeft={{
            width: 12,
            height: 12,
          }}
          onClick={() => handlerShowInfoEarnings()}
        >
          Зарабатывай
        </Button>
        <Menu
          isDesktop
          style={{ gridArea: "menu" }}
          list={listMenu}
          iconSize={18}
          handlerScreen={handleChangeScreen}
        />
      </BlockGrid>
      <Offset mt={20} />
      {/* -------------------------- */}
      <BlockGrid addClass="profile-desktop__self-info">
        <HeaderTitleActionComponent list={headerTitleInfoAboutProduct} />
        <Offset mb={14} />

        <BlockGrid
          style={{
            gridTemplateColumns: "1fr 4fr",
            gridTemplateAreas: `'info-access info-date'
                                'info-auto-pay info-auto-pay'
                                'extend-subscribe status-auto-pay'`,
          }}
        >
          <WrapRowGrid style={{ gridArea: "info-access" }}>
            <WrapTitleDescBlockOpacity>
              Текущий доступ:
            </WrapTitleDescBlockOpacity>
            <BlockTitle style={{ fontWeight: 500 }}>
              {infoPayment.user_status}
            </BlockTitle>
          </WrapRowGrid>
          <WrapRowGrid style={{ gridArea: "info-date" }}>
            <WrapTitleDescBlockOpacity>Действует до:</WrapTitleDescBlockOpacity>
            <BlockTitle style={{ fontWeight: 500 }}>
              {infoPayment.user_date_end}
            </BlockTitle>
          </WrapRowGrid>

          <InfoContainer style={{ gridArea: "info-auto-pay", marginTop: 10 }}>
            <InfoIconBlock>
              <Icon width={14} height={14} image={attention} />
            </InfoIconBlock>
            <InfoTextBlock
              style={{ fontWeight: 500, color: "var(--text-color-red)" }}
            >
              {statusPayment
                ? "У вас подключен автоплатеж"
                : "У вас отключен автоплатеж"}
            </InfoTextBlock>
          </InfoContainer>
          {/* <WrapTwoColumnGrid
            style={{, marginTop: 10 }}
          > */}
          <Button
            addClass={"button__controll--red"}
            onClick={() => handleChangeScreen({ path: PROFILE_SUBSCRIBE })}
            style={{
              justifyContent: "center",
              paddingTop: 4,
              gridArea: "extend-subscribe",
              marginTop: 20,
            }}
          >
            Продлить подписку
          </Button>
          {statusPayment && (
            <CheckBox
              checked={statusPayment}
              variant={"auto-payment"}
              onChange={(res) =>
                handlerSetData({ key: "auto_payment", value: false })
              }
              name={"payment"}
              helptext={"Автоплатеж"}
              style={{
                gridArea: "status-auto-pay",
                marginTop: 20,
                alignSelf: "center",
              }}
              helpTextStyle={{
                fontSize: 14,
                fontWeight: 500,
                lineHeight: "20px",
              }}
            />
          )}
          {/* </WrapTwoColumnGrid> */}
        </BlockGrid>
      </BlockGrid>
      <Offset mb={20} />

      <BlockGrid addClass="profile-desktop__self-info">
        <HeaderTitleActionComponent list={headerTitleUsefulInfo} />
        <Offset mb={48} />
        <BlockGrid
          style={{
            gridTemplateColumns: "1fr 1fr",            
            gap: 15
          }}
        >
          <WrapContainerBlockBorder
            onClick={() => {}}
            style={{
              padding: `13px 15px`,
              backgroundColor: "#F7F8F9",
            }}
          >
            <WrapTwoColumnGrid
              style={{
                gridTemplateColumns: `95% 5%`,
              }}
              onClick={() => handleChangeScreen({ path: PROFILE_INFO })}
            >
              <BlockTitle style={{ fontWeight: 500 }}>
                Инструкция по работе с ботом
              </BlockTitle>
              <WrapIconBlock>
                <Icon width={14} height={14} image={arrowRightRed} />
              </WrapIconBlock>
            </WrapTwoColumnGrid>
          </WrapContainerBlockBorder>

          <WrapContainerBlockBorder
            onClick={() => {}}
            style={{
              padding: `13px 15px`,
              backgroundColor: "#F7F8F9",
            }}
          >
            <WrapTwoColumnGrid
              style={{
                gridTemplateColumns: `95% 5%`,
              }}
              onClick={() =>
                handleChangeScreen({
                  url: "https://youtube.com/shorts/MBrA-iGZvWY?feature=share",
                })
              }
            >
              <BlockTitle style={{ fontWeight: 500 }}>
                Промо-ролик для друзей
              </BlockTitle>
              <WrapIconBlock>
                <Icon width={14} height={14} image={arrowRightRed} />
              </WrapIconBlock>
            </WrapTwoColumnGrid>
          </WrapContainerBlockBorder>

          <WrapContainerBlockBorder
            onClick={() => {}}
            style={{
              padding: `13px 15px`,
              backgroundColor: "#F7F8F9",
            }}
          >
            <WrapTwoColumnGrid
              style={{
                gridTemplateColumns: `95% 5%`,
              }}
            >
              <BlockTitle style={{ fontWeight: 500 }}>
                Видео о верификации
              </BlockTitle>
              <WrapIconBlock>
                <Icon width={14} height={14} image={arrowRightRed} />
              </WrapIconBlock>
            </WrapTwoColumnGrid>
          </WrapContainerBlockBorder>
        </BlockGrid>
      </BlockGrid>

      <Offset mb={24} />
      <MainFeedbackComponent list={linksFeedback} />
    </WrapContainer>
  );
};

export default ProfileDesktop