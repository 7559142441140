  import React from 'react'
import WrapContainerPreloader from '../../../View/Preloaders/WrapContainerPreloader';
import Preloader from '../../../View/Preloaders/Preloader';
import WrapContainer from '../../../View/WrapContainer/WrapContainer';
import Offset from '../../../View/Offset';
import BlockGrid from '../../../View/Blocks/BlockGrid';
import Icon from '../../../View/Icon/Icon';
import BlockTitle from '../../../View/Blocks/BlockTitle';
import CheckBox from '../../../View/CheckBox';
import SearchComponent from '../../../Components/SearchSection/SearchComponent';
import { API_SEARCH_FILTERS } from '../../../helpers/config';
import { ACTION_SET_TEXT_SEARCH_FILTER_SERVICE } from '../../../store/filters/filtersIncominRequest';
import TooltipComponent from '../../../Components/Component.Tooltip/TooltipComponent';
import Button from '../../../View/Button/Button';
import MenuItemIconContainer from '../../../View/Menu/Detali/MenuItemIconContainer';
import { arrowRightWhite } from '../../../images';
import WithTooltip from '../../../HOC/WithTooltip';
import { SET_TEXT_SEARCH_SERVICE_FILTER } from '../../../store/filters/IncomingRequestStore';

const IncomingRequestRespairDesktop = ({

  helptext,
  toolTipAction,
  textInputSearch,

  handlerTextSearch,
  handlerShowTooltip,

  listCitys,
  showListCity,
  listCountrys,
  handleShowCitys,
  handlerSelectItemFilter,
}) => {
  // if (!listCountrys?.length)
  //   return (
  //     <WrapContainerPreloader>
  //       Загрузка ... <Preloader />
  //     </WrapContainerPreloader>
  //   );
  return (
    <WrapContainer
      isDesktop
      style={{
        height: 'auto',
      }}
    >
      <Offset mt={"var(--offset-top-desktop)"} />
      <BlockGrid style={{ gridTemplateColumns: "1fr 1fr" }}>
        <SearchComponent
          isIconLeft
          style={{
            maxWidth: "100vw",
          }}
          urlRequest={API_SEARCH_FILTERS}
          helptext={helptext}
          styleHelpTextSearch={{}}
          placeholder={"Найдите город"}
          getResultSearch={handlerTextSearch} // функция обработки запроса
          enteredText={textInputSearch} // текущий текст
          actionDisptchGetSearch={SET_TEXT_SEARCH_SERVICE_FILTER} // где хроним текст
        />
      </BlockGrid>
      {listCountrys.length ? (
        listCountrys
          .filter((el) => !el?.isHide)
          .map((country, i) => {
            return (
              <React.Fragment key={i}>
                <BlockGrid
                  style={{
                    gridTemplateColumns: `90% 10%`,
                    display: "grid",
                    padding: "14px 14px 9px",
                  }}
                >
                  <BlockGrid
                    style={{
                      gridTemplateColumns: country?.image ? `10% 90%` : "100%",
                    }}
                  >
                    {country?.image && (
                      <Icon image={country.image} width={24} height={24} />
                    )}
                    <BlockTitle
                      style={{
                        top: 2,
                        fontWeight: 500,
                      }}
                    >
                      {country.name}
                    </BlockTitle>
                  </BlockGrid>

                  <BlockGrid
                    style={{
                      gridTemplateColumns: `50% 50%`,
                    }}
                  >
                    <CheckBox
                      checked={country["status_country"]?.status}
                      onChange={(res) => {
                        handlerSelectItemFilter({
                          id: res.value,
                          sub_type: "country",
                          list: "countrys",
                          checked: res.checked,
                        });
                      }}
                      helpTextStyle={{
                        top: 2,
                      }}
                      style={{
                        transform: `scale(1.4)`,
                        pointerEvents: "all",
                      }}
                      value={+country.id}
                      id={`check-${country.id}`}
                    />

                    <TooltipComponent
                      onClick={() =>
                        !country?.status_child &&
                        handlerShowTooltip({
                          // нужно другое
                          action: "disabled",
                          id: +country.id,
                        })
                      }
                      message={toolTipAction?.message}
                      isShow={
                        toolTipAction?.id === country.id && toolTipAction.isShow
                      }
                    >
                      <Button
                        disabled={!country.can_click} // нужно другое
                        style={{
                          transform: `scale(1.4)`,
                          top: 2,
                        }}
                        onClick={() => {
                          const id = country.id;
                          handleShowCitys({ country_id: id });
                        }}
                      >
                        <MenuItemIconContainer
                          width={20}
                          height={20}
                          style={{
                            borderRadius: 5,
                            top: 0,
                          }}
                        >
                          <Icon
                            image={arrowRightWhite}
                            width={11}
                            height={11}
                          />
                        </MenuItemIconContainer>
                      </Button>
                    </TooltipComponent>
                  </BlockGrid>
                </BlockGrid>
                <BlockGrid
                  style={{
                    gridTemplateColumns: "1fr",
                    justifyContent: "end",
                    alignItems: "center",
                    padding: "10px 0",
                  }}
                >
                  {showListCity === country.id &&
                    listCitys.map((city, i) => {
                      return (
                        <BlockGrid
                          key={i}
                          style={{
                            gridTemplateColumns: `95% 5%`,
                            display: "grid",
                            padding: "14px 14px 9px",
                          }}
                        >
                          <BlockGrid
                            style={{
                              gridTemplateColumns: city?.image
                                ? `10% 90%`
                                : "100%",
                            }}
                          >
                            {city?.image && (
                              <Icon image={city.image} width={24} height={24} />
                            )}
                            <BlockTitle
                              style={{
                                top: 2,
                                fontWeight: 500,
                              }}
                            >
                              {city.name}
                            </BlockTitle>
                          </BlockGrid>

                          <BlockGrid
                            style={{
                              gridTemplateColumns: `50%`,
                              justifyItem: "end",
                            }}
                          >
                            <CheckBox
                              checked={city["status_city"]?.status}
                              onChange={(res) => {
                                handlerSelectItemFilter({
                                  id: res.value,
                                  sub_type: "city",
                                  list: "citys",
                                  checked: res.checked,
                                });
                              }}
                              helpTextStyle={{
                                top: 2,
                              }}
                              style={{
                                transform: `scale(1.4)`,
                                pointerEvents: "all",
                              }}
                              value={+city.id}
                              id={`check-${city.id}`}
                            />
                          </BlockGrid>
                        </BlockGrid>
                      );
                    })}
                </BlockGrid>
                {showListCity === country.id && !listCitys.length &&
                <div></div>
                }
              </React.Fragment>
            );
          })
      ) : (
        <WrapContainerPreloader>
          Загрузка ... <Preloader />
        </WrapContainerPreloader>
      )}
    </WrapContainer>
  );
};

export default WithTooltip( IncomingRequestRespairDesktop)