import React from "react";
import WrapContainer from "../../../View/WrapContainer/WrapContainer";
import Offset from "../../../View/Offset";
import BlockGrid from "../../../View/Blocks/BlockGrid";
import NavigationMyApplication from "../../../View/Navigation/NavigationMyApplication/NavigationMyApplication";
import { ACTION_DELETE } from "../../../store/api-store/getpage";
import Button from "../../../View/Button/Button";
import { bascketRed, copyDoc, penGrey } from "../../../images";
import ButtonApplyContainer from "../../../Components/ButtonApplySection/ButtonApplyContainer";
import ButtonDesktop from "../../../View/ButtonDesktop/ButtonDesktop";


const OwnRequestDesktop = ({
  listSection,
  activeSection,
  handleShowMore,
  showDesktopButton,
  listMyApplication,
  countMyApplication,
  changePaginationPage,
  handlerChangeSection,
  currentPageMyApplication,
  handlerActionMyApplication,
}) => {
  return (
    <WrapContainer isDesktop>
      <Offset mt={"var(--offset-top-desktop)"} />
      <NavigationMyApplication
        isDesktop
        listSection={listSection}
        handlerChangeSection={handlerChangeSection}
      />
      <BlockGrid addClass="profile-desktop__self-info">
        {/* header catalog */}
        {activeSection === 0 || activeSection === 1 ? (
          <BlockGrid
            style={{
              gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
              gridTemplateAreas: `'brand model generation oem date . . .'`,

              gap: 10,
              backgroundColor: "#F7F8F9",
              color: "#666E7D",
              // padding: "0 24px",
              alignContent: "center",
              minHeight: 42,
            }}
          >
            <BlockGrid style={{ gridArea: "brand" }}>Марка</BlockGrid>
            <BlockGrid style={{ gridArea: "model" }}>Модель</BlockGrid>
            <BlockGrid style={{ gridArea: "generation" }}>Поколение</BlockGrid>
            <BlockGrid style={{ gridArea: "oem" }}>OEM/VIN</BlockGrid>
            <BlockGrid style={{ gridArea: "date" }}>Дата</BlockGrid>
          </BlockGrid>
        ) : (
          <BlockGrid
            style={{
              gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
              gridTemplateAreas: `'desc desc date . . .'`,

              gap: 10,
              backgroundColor: "#F7F8F9",
              color: "#666E7D",
              // padding: "0 24px",
              alignContent: "center",
              minHeight: 42,
            }}
          >
            <BlockGrid style={{ gridArea: "desc" }}>Запрос</BlockGrid>
            <BlockGrid style={{ gridArea: "date" }}>Дата</BlockGrid>
          </BlockGrid>
        )}

        {/* catalog */}
        <Offset mt={20} />
        {activeSection === 0 || activeSection === 1 ? (
          <React.Fragment>
            {!!listMyApplication?.length &&
              listMyApplication.map((item) => {
                return (
                  <BlockGrid
                    key={item.id}
                    style={{
                      gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                      gridTemplateAreas: `'brand model generation oem date delete dublicate edit'`,
                      gap: 10,
                    }}
                  >
                    <BlockGrid style={{ gridArea: "brand" }}>
                      {item.brand}
                    </BlockGrid>
                    <BlockGrid style={{ gridArea: "model" }}>
                      {item?.classes && !!item.classes.length ? (
                        item.classes.map((el, i) => {
                          return (
                            <React.Fragment key={i}>
                              {el.model_name}
                            </React.Fragment>
                          );
                        })
                      ) : item?.brand && item?.model && item.generation ? (
                        <React.Fragment>{item.model}</React.Fragment>
                      ) : null}
                    </BlockGrid>
                    <BlockGrid style={{ gridArea: "generation" }}>
                      {item?.classes && !!item.classes.length ? (
                        item.classes.map((el, i) => {
                          return (
                            <React.Fragment key={i}>
                              {el.generation_name}
                            </React.Fragment>
                          );
                        })
                      ) : item?.brand && item?.model && item.generation ? (
                        <React.Fragment>{item.generation}</React.Fragment>
                      ) : null}
                    </BlockGrid>
                    <BlockGrid style={{ gridArea: "oem" }}>
                      {item.oem}
                    </BlockGrid>
                    <BlockGrid style={{ gridArea: "date" }}>
                      {item.date_create.slice(0, 10)}
                    </BlockGrid>
                    <BlockGrid style={{ gridArea: "delete" }}>
                      <Button
                        onClick={() =>
                          handlerActionMyApplication(
                            ACTION_DELETE,
                            item.id,
                            item.type.type
                          )
                        }
                        addClass={"button__application-control-card"}
                        iconRight={bascketRed}
                        style={{
                          color: "var(--text-color-red)",
                          letterSpacing: "0px",
                          padding: 0,
                        }}
                        styleIconsRight={{
                          width: 14,
                          height: 14,
                          top: -2,
                          minWidth: 10,
                          marginRight: 7,
                        }}
                      >
                        Удалить запрос
                      </Button>
                    </BlockGrid>
                    <BlockGrid style={{ gridArea: "dublicate" }}>
                      <Button
                        onClick={() =>
                          handlerActionMyApplication(
                            "copy",
                            item.id,
                            item.type.type
                          )
                        }
                        addClass={"button__application-control-card"}
                        iconRight={copyDoc}
                        style={{
                          color: "var(--text-color-rlight-blue)",
                          letterSpacing: "0px",
                          padding: 0,
                        }}
                        styleIconsRight={{
                          width: 14,
                          height: 14,
                          top: -1,
                          minWidth: 10,
                          marginRight: 10,
                        }}
                      >
                        Дублировать
                      </Button>
                    </BlockGrid>
                    <BlockGrid style={{ gridArea: "edit" }}>
                      <Button
                        onClick={() =>
                          handlerActionMyApplication(
                            "edit",
                            item.id,
                            item.type.type
                          )
                        }
                        addClass={"button__application-control-card"}
                        iconRight={penGrey}
                        style={{
                          color: "#666E7D",
                          padding: 0,
                          letterSpacing: "0px",
                        }}
                        styleIconsRight={{
                          width: 14,
                          height: 14,
                          top: -1,
                          minWidth: 10,
                          marginRight: 10,
                        }}
                      >
                        Редактировать
                      </Button>
                    </BlockGrid>
                    <Offset mb={20} />
                  </BlockGrid>
                );
              })}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {!!listMyApplication?.length &&
              listMyApplication.map((item) => {
                return (
                  <BlockGrid
                    key={item.id}
                    style={{
                      gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                      gridTemplateAreas: `'desc desc date delete dublicate edit'`,
                      gap: 10,
                    }}
                  >
                    <BlockGrid style={{ gridArea: "desc" }}>{item.text}</BlockGrid>
                    <BlockGrid style={{ gridArea: "date" }}>
                      {item.date_create.slice(0, 10)}
                    </BlockGrid>
                    <BlockGrid style={{ gridArea: "delete" }}>
                      <Button
                        onClick={() =>
                          handlerActionMyApplication(
                            ACTION_DELETE,
                            item.id,
                            item.type.type
                          )
                        }
                        addClass={"button__application-control-card"}
                        iconRight={bascketRed}
                        style={{
                          color: "var(--text-color-red)",
                          letterSpacing: "0px",
                          padding: 0,
                        }}
                        styleIconsRight={{
                          width: 14,
                          height: 14,
                          top: -2,
                          minWidth: 10,
                          marginRight: 7,
                        }}
                      >
                        Удалить запрос
                      </Button>
                    </BlockGrid>
                    <BlockGrid style={{ gridArea: "dublicate" }}>
                      <Button
                        onClick={() =>
                          handlerActionMyApplication(
                            "copy",
                            item.id,
                            item.type.type
                          )
                        }
                        addClass={"button__application-control-card"}
                        iconRight={copyDoc}
                        style={{
                          color: "var(--text-color-rlight-blue)",
                          letterSpacing: "0px",
                          padding: 0,
                        }}
                        styleIconsRight={{
                          width: 14,
                          height: 14,
                          top: -1,
                          minWidth: 10,
                          marginRight: 10,
                        }}
                      >
                        Дублировать
                      </Button>
                    </BlockGrid>
                    <BlockGrid style={{ gridArea: "edit" }}>
                      <Button
                        onClick={() =>
                          handlerActionMyApplication(
                            "edit",
                            item.id,
                            item.type.type
                          )
                        }
                        addClass={"button__application-control-card"}
                        iconRight={penGrey}
                        style={{
                          color: "#666E7D",
                          padding: 0,
                          letterSpacing: "0px",
                        }}
                        styleIconsRight={{
                          width: 14,
                          height: 14,
                          top: -1,
                          minWidth: 10,
                          marginRight: 10,
                        }}
                      >
                        Редактировать
                      </Button>
                    </BlockGrid>
                    <Offset mb={20} />
                  </BlockGrid>
                );
              })}
          </React.Fragment>
        )}

        {showDesktopButton && <Offset mt={30} />}

        <ButtonDesktop
          show={showDesktopButton}
          isActive={showDesktopButton}
          type={"button"}
          name={"Загрузить еще"}
          addClass={"button__controll-roze--full"}
          onClick={handleShowMore}
        />
      </BlockGrid>
    </WrapContainer>
  );
};

export default OwnRequestDesktop;
