import React, { Component } from 'react'
import { connectStoreon } from 'storeon/react';
import WithRouter from '../../../HOC/WithRouter';
import CarSell from './CarSell';
import { ROOT } from '../../../helpers/config';
import { SET_DATA_CAR_SALE } from '../../../store/car-sale/carSale';
import { ACTION_SET_CONTROLL_BUTTON } from '../../../store/helpers/helpers-store';
import { delay } from '../../../helpers/const';
import CarSellDesktop from '../../../PagesDesktop/CarSellDesktop/CarSellDesktop';

class CarSellComponent extends Component {

  componentDidMount() {
    this.props.controllerHeaderBand({
      currentTextHandlerBand: 'Разместить авто на продажу',
      pathBackButton: () => this.handleChangeScreen({ path: ROOT })
    });
  }
  handleChangeScreen = ({ path }) => {
    this.props.navigate(path)
  }
  
  handleSetDataValues = values => this.props.dispatch(SET_DATA_CAR_SALE, { ...values });

  render() {
    if(this.props.isDesktop){
      return (
        <CarSellDesktop
          isDesktop={this.props.isDesktop}
          dispatch={this.props.dispatch}
          handleSetDataValues={this.handleSetDataValues}
        />
      );
    }
    return (
      <CarSell
      dispatch={this.props.dispatch}
      handleSetDataValues={this.handleSetDataValues}
    /> 
    )
  }
}

export default connectStoreon( 
  WithRouter(CarSellComponent)
);