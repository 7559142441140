import React, { forwardRef } from "react";
import Button from "../Button/Button";

const ButtonDesktopIncomminFilters = forwardRef(
  ({ show, type, name, onClick, addClass }, ref) => {
    return (
      <div style={{}}>
        <Button
          style={{
            visibility: show ? "visible" : "hidden",
            opacity: show ? 1 : 0,
          }}
          ref={ref}
          type={type ?? "submit"}
          addClass={addClass ?? "button__controll-incoming--red--full"}
          active={show}
          onClick={() => {
            onClick();
          }}
        >
          {name}
        </Button>
      </div>
    );
    // }
    // return null;
  }
);

export default ButtonDesktopIncomminFilters;