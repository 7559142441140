import React from "react";
import WrapContainer from "../../View/WrapContainer/WrapContainer";
import Offset from "../../View/Offset";
import BlockGrid from "../../View/Blocks/BlockGrid";
import Label from "../../View/Label/Label";
import LinkGoTo from "../../View/LinkGoTo/LinkGoTo";
import WrapContainerBlock from "../../View/Blocks/WrapContainerBlock";
import FormUploadImageV2 from "../../View/UploadImage/FormUploadImageV2";
import { addFile, plusFile } from "../../images";
import TextArea from "../../View/TextArea/TextArea";
import Form from "../../View/Form/Form";
import { ACTION_SET_CONTROLL_BUTTON } from "../../store/helpers/helpers-store";
import { Formik } from "formik";
import ButtonApplyContainer from "../../Components/ButtonApplySection/ButtonApplyContainer";
import restrictionLengthText from "../../helpers/helpers";

const CarSellDesktop = ({ dispatch, handleSetDataValues, isDesktop }) => {
  return (
    <WrapContainer isDesktop>
      <Offset mt={"var(--offset-top-desktop)"} />
      <BlockGrid addClass="profile-desktop__self-info">
        <Formik
          initialValues={{
            text: "",
            image: null,
          }}
        >
          {({
            values,
            errors,
            handleSubmit,
            touched,
            handleBlur,
            setFieldValue,
          }) => {
            dispatch(ACTION_SET_CONTROLL_BUTTON, { buttonForm: handleSubmit });
            return (
              <Form>
                <Offset mt={15} />
                <Label style={{ fontWeight: 700 }}>Текст объявления</Label>
                <Offset mt={10} />
                <div>
                  <TextArea
                    className={"textarea-application"}
                    value={values.text}
                    placeholder={`Опишите объявление`}
                    height={90}
                    distationtop={290}
                    id={`textarea-1`}
                    name={"text"}
                    onBlur={handleBlur}
                    helptext={touched?.text && errors?.text}
                    style={{
                      border:
                        touched?.text && errors?.text
                          ? "1px solid #ff0000"
                          : "",
                    }}
                    stylehelptext={{
                      color: "var(--text-color-red)",
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      const restrict = restrictionLengthText(
                        value,
                        4096,
                        dispatch
                      );
                      if (!restrict) return;
                      setFieldValue("text", value);
                      handleSetDataValues({ text: value });
                    }}
                  />
                </div>
                <Offset mt={18} />
                <FormUploadImageV2
                  listImages={
                    values.image ?? values.image
                      ? values.image
                      : isDesktop
                      ? [{ url: plusFile, type: "fake-image" }]
                      : []
                  }
                  multiple
                  image={addFile}
                  values={values}
                  setFieldValue={setFieldValue}
                  maxCountImage={8}
                  maxSizeImage={5242880} // 5242880 => 5mb
                  uploadTypeName="image"
                  // positionPreview={"under"}
                  onChange={({ key, value, callback }) => {
                    handleSetDataValues({ [key]: value });
                  }}
                />
                {/* <Offset mt={values?.image?.length ? 38 : 18} /> */}
                <Offset mt={values?.image?.length ? 38 : 10} />
              </Form>
            );
          }}
        </Formik>
        <Offset mt={30} />
        <BlockGrid>
          <ButtonApplyContainer isDesktop />
        </BlockGrid>
        <WrapContainerBlock
          style={{
            fontWeight: 600,
            padding: "20px 0 40px",
            position: "absolute",
            bottom: -200,
          }}
        >
          <Offset mt={40} />
      <BlockGrid addClass="profile-desktop__self-info" >

          <LinkGoTo
            position={"left"}
            url={"https://t.me/avtobayer"}
            fontSize={12}
            style={{
              alignItems: "center",
              fontWeight: 600,
              backgroundColor: "var(--background-color-button-red)",
              color: "var(--text-color-white)",
              zIndex: 11,
              position: "relative",
              width: "100%",
              minHeight: 46,
              justifyContent: "center",
              borderRadius: 12,
            }}
          >
            {"Посетить группу"}
          </LinkGoTo>
      </BlockGrid>
        </WrapContainerBlock>
      </BlockGrid>
    </WrapContainer>
  );
};

export default CarSellDesktop;
