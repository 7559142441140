import React from 'react'
import WrapContainer from '../../../View/WrapContainer/WrapContainer';
import Offset from '../../../View/Offset';
import BlockGrid from '../../../View/Blocks/BlockGrid';
import SearchComponent from '../../../Components/SearchSection/SearchComponent';
import { ACTION_SET_TEXT_SEARCH_MARKET_CARDS_OWN } from '../../../store/marketplace/marketplace';
import { sort } from '../../../images';
import TextTitle from '../../../View/Text/TextTitle';
import Button from '../../../View/Button/Button';
import CatalogMarketPlace from '../../../Pages/MarketPlace/CatalogMarketPlace/CatalogMarketPlace';
import Spinner from '../../../View/Spinner';
import WrapContainerPreloader from '../../../View/Preloaders/WrapContainerPreloader';
import Preloader from '../../../View/Preloaders/Preloader';
import CardMPItem from '../../../View/Cards/CardMPItem';
import { WithFavorite } from '../../../HOC/WithFavorite';
import HeaderTitleActionComponent from '../../../Components/Component.HeaderTitleAction/HeaderTitleActionComponent';
import BlockTitle from '../../../View/Blocks/BlockTitle';
import ShowPreviewImages from '../../../View/UploadImage/Detail/ShowPreviewImages';
import CreateFileDesktopContainer from '../CreateFileDesktop/CreateFileDesktopContainer';

const OwnCardsDesktop = ({
  dispatch,
  listCards,
  isLoading,
  textSearch,
  valuesPreview,
  changePagination,
  handlerDeleteFile,
  handlerDeleteLink,
  handlerActionCard,
  handlerTextSearch,
  loadingActionCard,
  loadingPreviewImage,
  handlerChangeFilters,
}) => {
  return (
    <WrapContainer isDesktop>
       <Offset mt={'var(--offset-top-desktop)'} />
      <BlockGrid addClass="profile-desktop__self-info">
        <BlockGrid
          style={{
            gridTemplateColumns: "4fr 1fr 1fr",
            gridTemplateAreas: `'search sorting .'`,
            gap: 10,
          }}
        >
          <SearchComponent
            iconLeft
            style={{
              maxWidth: `100% `,
              height: 46,
              gridArea: "search",
            }}
            isButtonRight
            enteredText={textSearch} // текущий текст
            getResultSearch={handlerTextSearch} // функция обработки запроса
            actionDisptchGetSearch={ACTION_SET_TEXT_SEARCH_MARKET_CARDS_OWN} // где хроним текст
          />
          <Button
            iconLeft={sort}
            classNameIcon={"size-15"}
            addClass={"button__main-desktop-sort"}
            style={{
              maxWidth: 114,
              gridArea: "sorting",
            }}
            onClick={(e) => {
              e.preventDefault();
              handlerChangeFilters({
                //   key:
                //     +option.optinsFastFilter === 1
                //       ? "order_by_high_price"
                //       : "order_by_low_price",
                //   value: +option.optinsFastFilter === 1 ? 2 : 1,
                id: true,
              });
            }}
          >
            <TextTitle>Сортировка</TextTitle>
          </Button>
        </BlockGrid>

        <Offset mb={36} />

        {/* catalog */}
        <BlockGrid
          style={{
            gridTemplateColumns: "repeat(5, 1fr)",
            gap: 10,
          }}
        >
          {
            isLoading ? (
              // !listCards.results.length ?
              <WrapContainerPreloader>
                Загрузка ... <Preloader />
              </WrapContainerPreloader>
            ) : null
            // : null
          }
          {listCards.count ? (
            <>
              {listCards.results.map((item, i) => {
                return (
                  <CardMPItem
                    key={i}
                    own
                    item={item}
                    dispatch={dispatch}
                    hendlerFavorite={()=>{}}
                    handlerActionCard={handlerActionCard}
                    loadingActionCard={loadingActionCard}
                    catalog
                    style={
                      {
                        // opacity: own && item.is_upload? .3 : 1,
                        // pointerEvents: own && item.is_upload? 'none' : 'all'
                      }
                    }
                  />
                );
              })}
            </>
          ) : (
            <Spinner time={3000} text="Ваш список пуст" />
          )}
        </BlockGrid>
      </BlockGrid>
      <Offset mb={10} />


        <CreateFileDesktopContainer />
    </WrapContainer>
  );
};

export default OwnCardsDesktop;