import React, { useEffect, useState } from 'react'
import Offset from '../../Offset';
import Label from '../../Label/Label';
import Button from '../../Button/Button';
import { getOptions } from '../../../helpers/helpers';
import WrapTwoColumnGrid from '../../Blocks/WrapTwoColumnGrid';
import WrapRowGrid from '../../Blocks/WrapRowGrid';
import { checkErrorForm } from '../../../helpers/utils';
import ItemAccordionTitle from '../../Accordion/ItemAccordionTitle';
import ReactSelectComponent from '../../Select/NativeSelect/ReactSelectComponent';

export default function FormInputAboutTCTabs({
  values,
  isDesktop,
  brands,
  models,
  generations,
  listOptionsCategory,
  isEdit,
  handlerChangeOptionsData,

  errors,
  touched,
  handleBlur,
  handleSubmit,
  setFieldValue,
  resetDataForm,
}) {

  const [isHasErrorForm, setIsHasErrorForm] = useState(false)
  const initValueAggrigate = [
    { key: 'brand_id', value: '' },
    { key: 'model_id', value: '' },
    { key: 'generation_id', value: '' },
    { key: 'category_id', value: '' },
  ];

  useEffect(() => {
    setIsHasErrorForm(checkErrorForm(errors, initValueAggrigate));
    setTimeout(() => setIsHasErrorForm(false), 1500)
  }, [errors, touched])

  return (
    <>
      <Offset mb={12} />
      <ItemAccordionTitle
        style={{
          fontWeight: 700,
          pointerEvents: "none",
          fontSize: 16,
          filter: `blur(var(--filter-blur))`,
        }}
      >
        Применимость
      </ItemAccordionTitle>
      <Offset mb={22} />

      <WrapTwoColumnGrid style={{ gap: 10 }}>
        <WrapRowGrid>
          <Label style={{ fontWeight: 500 }}>{"Марка"}</Label>
          <ReactSelectComponent
            data={getOptions(brands)}
            enabled={true}
            isUpTop={isDesktop ? false : true}
            classNamePrefix={"request"}
            clearValue={!!!values.brand}
            placeholder={isEdit && !values.brand_id ? values.brand : "Любая"}
            styleWrap={{
              border:
                touched?.brand_id && errors?.brand_id
                  ? "1px solid #ff0000"
                  : "",
            }}
            name={"brand_id"}
            helptext={touched?.brand_id && errors?.brand_id}
            stylehelptext={{
              color: "var(--text-color-red)",
            }}
            onChange={(value) => {
              setFieldValue("brand_id", value, true);
              handlerChangeOptionsData({
                key: "brand_id",
                value: value,
                buttonForm: handleSubmit,
              });
            }}
          />
        </WrapRowGrid>

        <WrapRowGrid>
          <Label style={{ fontWeight: 500 }}>{"Модель"}</Label>
          <ReactSelectComponent
            data={getOptions(models)}
            enabled={!!values?.brand_id}
            isUpTop={isDesktop ? false : true}
            classNamePrefix={"request"}
            clearValue={!!!values.model}
            placeholder={
              isEdit && !values.model_id && !values.brand_id
                ? values.model
                : "Любая"
            }
            styleWrap={{
              border:
                touched?.model_id && errors?.model_id
                  ? "1px solid #ff0000"
                  : "",
            }}
            name={"model_id"}
            helptext={touched?.model_id && errors?.model_id}
            stylehelptext={{
              color: "var(--text-color-red)",
            }}
            onChange={(value) => {
              setFieldValue("model_id", value, true);

              handlerChangeOptionsData({
                key: "model_id",
                value: value,
                buttonForm: handleSubmit,
              });
            }}
          />
        </WrapRowGrid>
      </WrapTwoColumnGrid>

      <Offset mb={15} />
      <Label style={{ fontWeight: 500 }}>{"Поколение"}</Label>
      <Offset mb={3} />
      <ReactSelectComponent
        data={getOptions(generations)}
        enabled={!!values?.model_id}
        isUpTop={isDesktop ? false : true}
        classNamePrefix={"request"}
        clearValue={!!!values.generation}
        placeholder={
          isEdit && !values.model_id && !values.brand_id
            ? values.generation
            : "Любая"
        }
        styleWrap={{
          border:
            touched?.generation_id && errors?.generation_id
              ? "1px solid #ff0000"
              : "",
        }}
        name={"generation_id"}
        helptext={touched?.generation_id && errors?.generation_id}
        stylehelptext={{
          color: "var(--text-color-red)",
        }}
        onChange={(value) => {
          setFieldValue("generation_id", value, true);
          handlerChangeOptionsData({
            key: "generation_id",
            value: value,
            buttonForm: handleSubmit,
          });
        }}
      />
      <Offset mb={14} />
      <Label style={{ fontWeight: 500 }}>{"Категория"}</Label>
      <Offset mb={4} />
      <ReactSelectComponent
        data={getOptions(listOptionsCategory)}
        enabled={true}
        isUpTop={isDesktop ? false : true}
        classNamePrefix={"request"}
        clearValue={!!!values.category}
        placeholder={isEdit && values.category ? values.category : "Любое"}
        styleWrap={{
          border:
            touched?.category_id && errors?.category_id
              ? "1px solid #ff0000"
              : "",
        }}
        name={"category_id"}
        helptext={touched?.category_id && errors?.category_id}
        stylehelptext={{
          color: "var(--text-color-red)",
        }}
        onChange={(value) => {
          setFieldValue("category_id", value, true);
          handlerChangeOptionsData({
            key: "category_id",
            value: value,
            buttonForm: handleSubmit,
          });
        }}
      />

      <Offset mb={32} />

      <Button
        onClick={() => {
          resetDataForm(setFieldValue, initValueAggrigate);
          setFieldValue("brand_id", null);
          handlerChangeOptionsData({
            key: null,
            value: null,
            type: "aggrigate",
          });
        }}
        style={{
          color: "var(--text-color-red)",
          pointerEvents: "all",
          textAlign: "justify",
          justifyContent: isDesktop ? "flex-start" : "center",
        }}
      >
        Сбросить
      </Button>
      <Offset mb={24} />
    </>
  );
}
