import React from "react";
import BlockGrid from "../../View/Blocks/BlockGrid";
import BlockFlex from "../../View/Blocks/BlockFlex";
import Icon from "../../View/Icon/Icon";
import { arrowDown } from "../../images";
import TextTitle from "../../View/Text/TextTitle";
import UnitRequestComponent from "../../Pages/Requests/UnitRequest/UnitRequestComponent";
import SpareRequestComponent from "../../Pages/Requests/SpareRequest/SpareRequestComponent";
import RespairRequestComponent from "../../Pages/Requests/RespairRequest/RespairRequestComponent";
import TransportRequestComponent from "../../Pages/Requests/TransportRequest/TransportRequestComponent";
import TruckRequestComponent from "../../Pages/Requests/TruckRequest/TruckRequestComponent";
const Requests = ({ listMenu, handlerChangeItemMenu }) => {
  return (
    <BlockGrid addClass="block__requests-desktop-menu-container">
      {listMenu.map((item, index) => (
        <BlockFlex
          key={item.id}
          addClass="block__requests-desktop-container-item"
        >
          <BlockFlex
            addClass={
              item.isActive
                ? `block__requests-desktop-menu-item-container--active`
                : "block__requests-desktop-menu-item-container"
            }
            onClick={() => handlerChangeItemMenu(item.id)}
          >
            <TextTitle
              type="h5"
              addClass="text__request-desktop-menu-item-title"
            >
              {item.name}
            </TextTitle>
            <Icon image={arrowDown} style={{ height: 8, top: 2 }} />
          </BlockFlex>
          {item.isActive && item.id === 0 && (
            <BlockGrid addClass="block__requests-desktop-menu-item-sub-container">
              <UnitRequestComponent isDesktop handlerChangeItemMenu ={handlerChangeItemMenu}/>
            </BlockGrid>
          )}
          {item.isActive && item.id === 1 && (
            <BlockGrid addClass="block__requests-desktop-menu-item-sub-container">
              <SpareRequestComponent isDesktop handlerChangeItemMenu ={handlerChangeItemMenu}/>
            </BlockGrid>
          )}
          {item.isActive && item.id === 2 && (
            <BlockGrid addClass="block__requests-desktop-menu-item-sub-container">
              <RespairRequestComponent isDesktop handlerChangeItemMenu ={handlerChangeItemMenu}/>
            </BlockGrid>
          )}
          {item.isActive && item.id === 3 && (
            <BlockGrid addClass="block__requests-desktop-menu-item-sub-container">
              <TruckRequestComponent isDesktop handlerChangeItemMenu ={handlerChangeItemMenu}/>
            </BlockGrid>
          )}
          {item.isActive && item.id === 4 && (
            <BlockGrid addClass="block__requests-desktop-menu-item-sub-container">
              <TransportRequestComponent isDesktop handlerChangeItemMenu ={handlerChangeItemMenu}/>
            </BlockGrid>
          )}
        </BlockFlex>
      ))}
    </BlockGrid>
  );
};

export default Requests;
