import React, { Component } from "react";
import IncomingRequestRespairDesktop from "./IncomingRequestRespairDesktop";
import { ACTION_SET_CONTROLL_BUTTON } from "../../../store/helpers/helpers-store";
import {
  CHECK_SELECT_FILTERS,
  GET_FILTERS,
  GET_SEARCH_FILTERS,
  GET_SEARCH_RESPARE_FILTERS,
  SET_VALUE_FILTER_BRAND,
} from "../../../store/filters/IncomingRequestStore";
import { connectStoreon } from "storeon/react";
import { API_SEARCH_FILTER } from "../../../helpers/config";
import { ACTION_SET_VALUE_INTO_LIST_CARDS_SELECTS_FILTER_NULL } from "../../../store/filters/filtersIncominRequest";

export class IncomingRequestRespairContainerDesktop extends Component {
    state = {
        showListCity: null
    }
    componentDidMount() {
    this.props.dispatch(GET_FILTERS, {
      type: "respair",
      list: "countrys",
      callback: this.callback,
    });

    if (this.props.isDesktop)
      setTimeout(() => {
        this.props.dispatch(ACTION_SET_CONTROLL_BUTTON, {
          isActive: false,
          isFetch: false,
          isfixed: true,
          typeButton: "fixed",
          show: true,
          addClass: "button__apply--incomin-request-red",
          title: "Применить",
        });
        this.props.dispatch(CHECK_SELECT_FILTERS, {
          obj: {},
          list: "brands",
          type: "unit_spare",
        });
      }, 300);
  }

    componentWillUnmount() {
      if (this.props.isDesktop){
        setTimeout(() => {
            console.log('unmount')
            this.props.dispatch(ACTION_SET_CONTROLL_BUTTON, {
              isActive: false,
              isFetch: false,
              typeButton: "fixed",
              show: false,
              addClass: "button__apply--incomin-request-red",
              title: "Применить",
            });
          }, 0);
            this.props.dispatch(ACTION_SET_VALUE_INTO_LIST_CARDS_SELECTS_FILTER_NULL);
        }
      }

  handleShowCitys = ({ country_id }) => {
    if(this.state.showListCity === country_id){
        this.setState({ showListCity: null });
        return;
    }
    this.setState({ showListCity: country_id });
    this.props.dispatch(GET_FILTERS, {
      type: "respair",
      list: "citys",
      page_size: 9999,
      country_id,
      callback: this.callback,
    });
  };
  handlerSelectItemFilter = ({ id, sub_type, list, checked, curTab }) => {
    this.props.dispatch(SET_VALUE_FILTER_BRAND, {
      id,
      sub_type,
      list,
      checked,
    });
  };

  handlerTextSearch = (text, e, callback) => {
      this.props.dispatch(GET_SEARCH_RESPARE_FILTERS, {
        list: "citys",
        type: "respare",
        url: API_SEARCH_FILTER,
        country_id: this.state.showListCity,
        text,
        callback,
      });
    };
  render() {
    return (
      <IncomingRequestRespairDesktop
        listCitys={this.props.storeFilters.citys.results}
        showListCity={this.state.showListCity}
        listCountrys={this.props.storeFilters.countrys.results}
        handleShowCitys={this.handleShowCitys}
        textInputSearch={this.props.textSearchIncominFilterService}
        handlerTextSearch={this.handlerTextSearch}
        handlerSelectItemFilter={this.handlerSelectItemFilter}
      />
    );
  }
}

export default connectStoreon(
  "storeFilters",
  "textSearchIncominFilterService",
  IncomingRequestRespairContainerDesktop
);
