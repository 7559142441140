import React from 'react'
import MyMarketplacePageContainer from '../../../Pages/MarketPlace/MyMarketPlace/MyMarketplacePageContainer'

const OwnCardsDesktopContainer = () => {
  return (
    <MyMarketplacePageContainer 
    isDesktop
    />
  )
}

export default OwnCardsDesktopContainer