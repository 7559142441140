import React from 'react'
import WrapContainer from '../../WrapContainer/WrapContainer'
import Button from '../../Button/Button'
import WrapContainerNavigation from './WrapContainerNavigation'

export default function NavigationRequest({
  handlerChangeSection = () => {},
  listScreen = [],
  style = {},
}) {
  return (
    <WrapContainer>
      {!!listScreen?.length && (
         <WrapContainerNavigation style={style}>
          {listScreen.map((section) => {
            return (
              <Button
                style={{
                  gridArea: `${section.type}`,
                  backgroundColor: !section.active
                    ? "var(--background-color-block)"
                    : "var(--background-color-Mirage-blue)",
                  color: !section.active
                    ? "var(--text-color)"
                    : "var(--text-color-white)",
                  pointerEvents: section.enable ? "all" : "none",
                  opacity: section.enable ? 1 : 0.5,
                }}
                key={section.id}
                onClick={(e) => handlerChangeSection(e, section.type)}
                addClass={"button__navigation-my-application"}
              >
                {section.title}
              </Button>
            );
          })}
        </WrapContainerNavigation>
      )}
    </WrapContainer>
  );
}
