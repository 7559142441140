import React, { useEffect } from 'react'
import MyFavoriteContainer from '../../../Pages/MarketPlace/MyFavorite/MyFavoriteContainer'

const FavoriteCardContainer = () => {
  
  return (
    <MyFavoriteContainer isDesktop />
  )
}

export default FavoriteCardContainer