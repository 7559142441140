import React, { useEffect } from "react";
import styles from "./styles/modal-desktop.module.scss";
import { useStoreon } from "storeon/react";
import Offset from "../../../View/Offset";
import Icon from "../../../View/Icon/Icon";
import { crossRed, done } from "../../../images";
import { ACTION_CLOSE_MODAL } from "../../../store/helpers/helpers-store";
import WithRouter from "../../../HOC/WithRouter";

const ModalDesktop = ({ navigate }) => {
  const { dispatch, modal } = useStoreon("modal");
  const closeModal = () => {
    dispatch(ACTION_CLOSE_MODAL);
  };
  useEffect(()=>{
    modal.show && document.querySelector('[data-wrap="wrap"]')?.style &&
      document
        .querySelector('[data-wrap="wrap"]')
        .style.setProperty("overflow-y", "hidden");
    return ()=> document.querySelector('[data-wrap="wrap"]')?.style && 
                  document.querySelector('[data-wrap="wrap"]').style.setProperty("overflow-y", "auto");
  },[modal.show])
  return (
    <div
      className={styles["modal__container"]}
      style={{
        opacity: modal.show ? 1 : 0,
        top: modal.show ? '50%' : "-100vh",
        maxHeight: 500 
      }}
    >
      {!modal?.hideIcon ? (
        <>
          <Offset mb={42} />
          <Icon
            image={modal?.icon ? modal?.icon : modal?.error ? crossRed : done}
            width={35}
            height={35}
          />
        </>
      ) : (
        <Offset mb={60} />
      )}
      <div
        div
        className={styles["modal__context"]}
      >
        {modal.content ?? ""}
      </div>
      {!modal?.hideControll ? (
        <div className={styles["modal__control-panel"]}>
          <div
            className={styles["modal__control-panel-container"]}
            style={{
              // width: modal?.contentCancelBtn ? "49%" : "100%",
            gridTemplateColumns: modal?.contentCancelBtn ? '1fr 1fr' : '1fr'

            }}
          >
            {modal?.contentCancelBtn ? (
              <div
                className={styles["modal__control-panel-btn"]}
                onClick={() => {
                  modal?.actionCancelOk && modal.actionCancelOk(true);
                  closeModal();
                }}
              >
                <span
                  className={styles["modal__control-panel-btn-text-cancel"]}
                >
                  {modal?.contentCancelBtn ?? "Отмена"}
                </span>
              </div>
            ) : null}
            <div
              className={styles["modal__control-panel-btn"]}
              onClick={() => {
                console.log('click modal')
                modal?.path &&
                  navigate(modal.path, {
                    ...(modal?.state ?? {}),
                  });
                modal?.actionClickOk && modal.actionClickOk(true);
                closeModal();
              }}
            >
              <span style={{}}>{modal?.contentBtn ?? "Ок"}</span>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default WithRouter( ModalDesktop);
