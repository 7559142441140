import { _INIT } from "../api-store/getpage"

export const ACTION_SET_MENU_ELECTRON = 'setMenuDesktop';


export const electron = store => {
    const initMenuDesktop = {};
    store.on(_INIT, () => ({menuDesktop: initMenuDesktop}))
    store.on(ACTION_SET_MENU_ELECTRON, ({_}, data, {dispatch})=>{

    })
}