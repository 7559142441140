import React from 'react'
import WrapContainer from '../../../View/WrapContainer/WrapContainer'
import BlockGrid from '../../../View/Blocks/BlockGrid'
import Offset from '../../../View/Offset'
import WrapContainerCards from '../../../View/WrapContainer/WrapContainerCards'
import CardMPItem from '../../../View/Cards/CardMPItem'
import Spinner from '../../../View/Spinner'

const FavoriteCard = ({
    dispatch,
  listCards,
  removeFavorite,
  changePagination,
}) => {
  return (
    <WrapContainer isDesktop>
      <Offset mt={"var(--offset-top-desktop)"} />
      <BlockGrid addClass="profile-desktop__self-info">
        {listCards.count ? (
          <>
            <WrapContainerCards style={{ gridTemplateColumns: 'repeat(5,1fr)', gap: 10 }}>
              {listCards.results.map((item, i) => {
                return (
                  <CardMPItem
                    catalog
                    key={i}
                    item={item}
                    dispatch={dispatch}
                    hendlerFavorite={removeFavorite}
                  />
                );
              })}
            </WrapContainerCards>
          </>
        ) : (
          <Spinner time={3000} text="Ваш список пуст" />
        )}
      </BlockGrid>
    </WrapContainer>
  );
}

export default FavoriteCard