import React from "react";
import WrapContainer from "../../WrapContainer/WrapContainer";
import Button from "../../Button/Button";
import WrapContainerNavigationMyApplication from "./WrapContainerNavigationMyApplication";
import SearchComponent from "../../../Components/SearchSection/SearchComponent";

export default function NavigationMyApplication({
  isDesktop,
  listSection = [],
  handlerChangeSection = () => {},
}) {
  let desktopStyle = {}
  if(isDesktop){
    desktopStyle = {
      ...desktopStyle,
      height: 'max-content'
    }
  }
  return (
    <WrapContainer isDesktop={isDesktop} style={desktopStyle}>
      <WrapContainerNavigationMyApplication
        style={
          isDesktop
            ? {
                gridTemplateColumns: "1fr 1fr 1fr 1fr 2fr",
              }
            : {}
        }
      >
        {listSection.map((section) => {
          return (
            <Button
              style={{
                gridArea: isDesktop ? "" : `${section.type}`,
                backgroundColor: !section.active
                  ? "var(--background-color-block)"
                  : "var(--background-color-Mirage-blue)",
                color: !section.active
                  ? "var(--text-color)"
                  : "var(--text-color-white)",
              }}
              key={section.id}
              onClick={(e) => handlerChangeSection(e, section.type)}
              addClass={"button__navigation-my-application"}
            >
              {section.title}
            </Button>
          );
        })}
        {isDesktop && (
          <SearchComponent
            isDesktop
            isIconLeft
            style={{
              paddingLeft: 1,
              height: 44,
              overflow: 'unset',
              width: '97%'
            }}
            // getResultSearch={handlerTextSearch}
            // enteredText={textSearchReview}
            // actionDisptchGetSearch={ACTION_SET_SEARCH_TEXT_FILTER} // где хроним текст
            styleHelpTextSearch={{}}
          />
        )}
      </WrapContainerNavigationMyApplication>
    </WrapContainer>
  );
}
