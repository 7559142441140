import React from "react";
import WrapContainer from "../../View/WrapContainer/WrapContainer";
import Menu from "../../View/Menu/Menu";
import BlockGrid from "../../View/Blocks/BlockGrid";
import SearchComponent from "../../Components/SearchSection/SearchComponent";
import { ACTION_SET_SEARCH_TEXT_FILTER } from "../../store/raiting-review/raiting-review";
import Offset from "../../View/Offset";
import Preloader from "../../View/Preloaders/Preloader";
import WrapContainerPreloader from "../../View/Preloaders/WrapContainerPreloader";
import Label from "../../View/Label/Label";
import RaitingContainer from "../../View/Raiting/RaitingContainer";
import ButtonDesktop from "../../View/ButtonDesktop/ButtonDesktop";

const RaitingDesktop = ({
  listMenu,
  handlerClick,
  isLoadingList,
  handleShowMore,
  textSearchReview,
  showDesktopButton,
  handlerTextSearch,
  handleChangeScreen,
  listRaitingAndReview,
}) => {
  return (
    <WrapContainer isDesktop>
      <Offset mt={"var(--offset-top-desktop)"} />
      <BlockGrid addClass="profile-desktop__self-info">
        <Menu
          isDesktop
          list={listMenu}
          handlerScreen={handleChangeScreen}
          iconSize={18}
        />
        <Offset mt={20} />
        <BlockGrid
          style={{
            gridTemplateAreas: `'search .'
                            'all-user .'
                            'catalog catalog'`,
            gridTemplateColumns: "1fr 1fr",
            gap: 25,
          }}
        >
          <BlockGrid style={{ gridArea: "search" }}>
            <SearchComponent
              placeholder={"Имя пользователя"}
              isIconLeft
              isUpblock={false}
              style={{
                paddingLeft: 1,
                height: 44,
                maxWidth: "100%",
              }}
              getResultSearch={handlerTextSearch}
              enteredText={textSearchReview}
              actionDisptchGetSearch={ACTION_SET_SEARCH_TEXT_FILTER} // где хроним текст
              styleHelpTextSearch={{}}
            />
          </BlockGrid>
          <Label
            style={{
              gridArea: "all-user",
              fontSize: 12,
              fontWeight: 500,
              letterSpacing: "0px",
            }}
          >
            Активные пользователи: {listRaitingAndReview?.count_all}
          </Label>
          <BlockGrid style={{ gridArea: "catalog" }}>
            {/* header catalog */}
            <BlockGrid
              style={{
                gridTemplateAreas: `'user company amount-review raiting'`,
                gridTemplateColumns: "1fr 1fr 2fr 1fr",
                gap: 10,
                backgroundColor: "#F7F8F9",
                color: "#666E7D",
                padding: "0 24px",
                alignContent: "center",
                minHeight: 42,
              }}
            >
              <BlockGrid style={{ gridArea: "user" }}>Пользователь</BlockGrid>
              <BlockGrid style={{ gridArea: "company" }}>Компания</BlockGrid>
              <BlockGrid style={{ gridArea: "amount-review" }}>
                Количество отзывов
              </BlockGrid>
              <BlockGrid style={{ gridArea: "raiting", justifySelf: "end" }}>
                Рейтинг продавца
              </BlockGrid>
            </BlockGrid>
            {/* body catalog */}

            {!isLoadingList ? (
              <React.Fragment>
                {
                textSearchReview &&
                listRaitingAndReview.results.length &&
                !listRaitingAndReview.results[0]?.rating_number ? ( // только для100% совпадения и толко для одного
                  <React.Fragment>
                    {listRaitingAndReview.results.map((item, index) => {
                      return (
                        <BlockGrid
                          style={{
                            gridTemplateAreas: `'user company amount-review raiting'`,
                            gridTemplateColumns: "1fr 1fr 2fr 1fr",
                            gap: 10,
                            padding: "0 24px",
                            alignContent: "center",
                            minHeight: 42,
                            marginTop: 10,
                          }}
                          key={"review-" + index}
                          onClick={() =>
                            handlerClick(item?.user_data?.user_telegram_id)
                          }
                        >
                          <BlockGrid style={{ gridArea: "user" }}>
                            {item.user}
                          </BlockGrid>
                          <BlockGrid style={{ gridArea: "company" }}>
                            {item.telegram_username}
                          </BlockGrid>
                          <BlockGrid style={{ gridArea: "amount-review" }}>
                            {item.comments}
                          </BlockGrid>
                          <BlockGrid
                            style={{ gridArea: "raiting", justifySelf: "end" }}
                          >
                            <RaitingContainer
                              max={5}
                              gap={3}
                              value={item?.rating_int}
                              sizeStarHeight={15}
                              sizeStarWidth={15}
                              backgroundFone
                              reverse
                            />
                          </BlockGrid>
                        </BlockGrid>
                      );
                    })}
                  </React.Fragment>
                ) : !textSearchReview ? (
                  <React.Fragment>
                    {listRaitingAndReview.results.map((item, index) => {
                      return (
                        <BlockGrid
                          style={{
                            gridTemplateAreas: `'user company amount-review raiting'`,
                            gridTemplateColumns: "1fr 1fr 2fr 1fr",
                            gap: 10,
                            padding: "0 24px",
                            alignContent: "center",
                            minHeight: 42,
                            marginTop: 10,
                          }}
                          key={"review-" + index}
                          onClick={() =>
                            handlerClick(item?.user_data?.user_telegram_id)
                          }
                        >
                          <BlockGrid
                            style={{ gridArea: "user", color: "blue" }}
                          >
                            {item.user}
                          </BlockGrid>
                          <BlockGrid style={{ gridArea: "company" }}>
                            {item.telegram_username}
                          </BlockGrid>
                          <BlockGrid style={{ gridArea: "amount-review" }}>
                            {item.comments}
                          </BlockGrid>
                          <BlockGrid
                            style={{ gridArea: "raiting", justifySelf: "end" }}
                          >
                            <RaitingContainer
                              max={5}
                              gap={3}
                              value={item?.rating_int}
                              sizeStarHeight={15}
                              sizeStarWidth={15}
                              backgroundFone
                              reverse
                            />
                          </BlockGrid>
                        </BlockGrid>
                      );
                    })}
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            ) : (
              <WrapContainerPreloader>
                Загрузка ... <Preloader />
              </WrapContainerPreloader>
            )}
          </BlockGrid>
        </BlockGrid>
        <Offset mt={32} />

        <ButtonDesktop
          show={!textSearchReview && showDesktopButton}
          isActive={!textSearchReview && showDesktopButton}
          type={"button"}
          name={"Загрузить еще"}
          addClass={"button__controll-roze--full"}
          onClick={handleShowMore}
        />
      </BlockGrid>
    </WrapContainer>
  );
};

export default RaitingDesktop;
