import React from "react";
import TextTitle from "../../View/Text/TextTitle";
import { linksFeedback } from "../../helpers/config";
import BlockGrid from "../../View/Blocks/BlockGrid";
import Offset from "../../View/Offset";
import FAQContainer from "../../Components/Desktop/FAQ/FAQContainer";
import RecomandationsMarketplaceContainer from "../../Components/RecomandationsMarketplace/RecomandationsMarketplaceContainer";
import MainCatalogMPContainer from "../../Pages/MarketPlace/CatalogMarketPlace/MainCatalogMPContainer";
import WrapContainer from "../../View/WrapContainer/WrapContainer";
import ModalDesktop from "../../Components/Desktop/ModalDesktop/ModalDesktop";
import MainFeedbackComponent from "../../Pages/FeedbackPage/FeedbackPage";

function MainPageDesktop({
  listFAQ,
}) {
  return (
    <WrapContainer isDesktop>
      <Offset mt={"var(--offset-top-desktop)"} />
      
      <BlockGrid addClass="profile-desktop__self-info">
        <MainCatalogMPContainer isDesktop />
      </BlockGrid>

      <Offset mt={20} />

      <BlockGrid addClass="profile-desktop__self-info">
        <RecomandationsMarketplaceContainer slidesPerView={5} />
      </BlockGrid>

      <Offset mt={20} />

      <BlockGrid addClass="profile-desktop__self-info">
        <TextTitle type={"h5"} addClass="text__main-desktop-title">
          Вопросы и ответы
        </TextTitle>
        <Offset mt={20} />

        <FAQContainer list={listFAQ} />
      </BlockGrid>

      <MainFeedbackComponent list={linksFeedback} />
    </WrapContainer>
  );
}

export default MainPageDesktop;
