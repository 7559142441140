import React, { Component } from 'react'
import RaitingAndReviewCreateReview from './RaitingAndReviewCreateReview'
import WithRouter from '../../../HOC/WithRouter';
import { connectStoreon } from 'storeon/react';
import { setLocaleStore } from '../../../helpers/utils';
import { REITING_MENU } from '../../../helpers/config';
import { ACTION_SET_VALUES_REVIEW, ACTION_SET_VALUES_REVIEW_NULL } from '../../../store/raiting-review/raiting-review';
import CreateReviewDesktop from '../../../PagesDesktop/Raiting/CreateReviewDesktop';

class RaitingAndReviewCreateReviewComponent extends Component {
  componentDidMount() {
    this.props.controllerHeaderBand({
      currentTextHandlerBand: 'Оставить отзыв',
      pathBackButton: REITING_MENU//
    });
  }

  componentWillUnmount() {
    setLocaleStore('username', '');
    this.props.dispatch(ACTION_SET_VALUES_REVIEW_NULL)
  }

  handleSetDataValues = values => this.props.dispatch(ACTION_SET_VALUES_REVIEW, values);

  render() {
    if(this.props.isDesktop){
      return (
        <CreateReviewDesktop
          dispatch={this.props.dispatch}
          handleSetDataValues={this.handleSetDataValues}
        />
      );
    }
    return (
      <RaitingAndReviewCreateReview
        dispatch={this.props.dispatch}
        handleSetDataValues={this.handleSetDataValues}
      />
    )
  }
}

export default connectStoreon(

  WithRouter(RaitingAndReviewCreateReviewComponent)
)