import React from "react";
import WrapContainer from "../../../View/WrapContainer/WrapContainer";
import Offset from "../../../View/Offset";
import BlockGrid from "../../../View/Blocks/BlockGrid";
import WrapContainerPreloader from "../../../View/Preloaders/WrapContainerPreloader";
import Preloader from "../../../View/Preloaders/Preloader";
import Icon from "../../../View/Icon/Icon";
import { subscribe } from "../../../images";
import WrapRowGrid from "../../../View/Blocks/WrapRowGrid";
import BlockTitle from "../../../View/Blocks/BlockTitle";
import WrapTitleDescBlockOpacity from "../../../View/Blocks/WrapTitleDescBlockOpacity";
import RaitingContainer from "../../../View/Raiting/RaitingContainer";
import Line from "../../../View/Line/Line";
import CardTitleDescBlock from "../../../View/Cards/Detail/review/CardTitleDescBlock";
import NavigationReview from "../../../View/Navigation/NavigationMyApplication/NavigationReview";
import CardReviewOwn from "../../../View/Cards/Detail/review/CardReviewOwn";
import ButtonDesktop from "../../../View/ButtonDesktop/ButtonDesktop";

const MyReviewDesktop = ({
  reviews,
  infoUser,
  listScreen,
  isLeftReview,
  handleShowMore,
  statusInputForm,
  changePagination,
  showDesktopButton,
  listScreenReviews,
  handlerDeleteReview,
  textMyFeedbackStore,
  handleChangeScreen,
  handlerChangeSection,
  handlerChangeFeedback,
  handleSetDataValues,
}) => { 
  if (!infoUser?.id)
    return (
      <WrapContainerPreloader>
        Загрузка ... <Preloader />
      </WrapContainerPreloader>
    );
  return (
    <WrapContainer isDesktop>
      <Offset mt={"var(--offset-top-desktop)"} />
      <BlockGrid addClass="profile-desktop__self-info">
        {/* title */}
        
        <BlockGrid
          style={{
            gridTemplateColumns: "1fr 300px",
          }}
        >
          <BlockGrid
            style={{
              gridTemplateColumns: "50px 1fr",
              alignItems: "center",
            }}
          >
            <Icon
              addClass={"icon__bg-roze-38"}
              width={14}
              height={14}
              image={subscribe}
            />
            <WrapRowGrid>
              <BlockTitle style={{ fontWeight: 500 }}>
                {infoUser.user_data.user_profile_name}
              </BlockTitle>
              <WrapTitleDescBlockOpacity style={{ fontSize: 14 }}>
                {infoUser.user_data.user_url}
              </WrapTitleDescBlockOpacity>
            </WrapRowGrid>
          </BlockGrid>
          <BlockGrid
            style={{
              justifyItems: "end",
              alignItems: "center",
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            <CardTitleDescBlock
              style={{
                whiteSpace: "nowrap",
              }}
            >
              Рейтинг продавца:
            </CardTitleDescBlock>
            <RaitingContainer
              max={5}
              value={infoUser?.rating_int}
              sizeStarHeight={13}
              sizeStarWidth={13}
              gap={3.5}
              backgroundFone
              reverse
              styleStar={{
                marginRight: 5,
              }}
            />
          </BlockGrid>
        </BlockGrid>
        <Offset mt={20} />
        <Line />

        {/* navigation */}
        <Offset mb={13} />
        <NavigationReview
          isDesktop
          handlerChangeSection={handlerChangeSection}
          listScreen={listScreenReviews}
          style={{
            gridTemplateAreas: `'received left'`,
          }}
        />
        {/* list review */}
        {!!reviews.results?.length &&
          reviews.results.map((item, index) => {
            return (
              <WrapContainer
                isDesktop
                style={{
                  marginBottom: 14,
                }}
                key={"Own-card-" + index}
              >
                <CardReviewOwn
                  own
                  item={item}
                  index={index}
                  isLeftReview={isLeftReview}
                  statusInputForm={statusInputForm}
                  textMyFeedbackStore={textMyFeedbackStore}
                  handleChangeScreen={handleChangeScreen}
                  handlerDeleteReview={handlerDeleteReview}
                  handlerChangeFeedback={handlerChangeFeedback}
                  handleSetDataValues={handleSetDataValues}
                />
              </WrapContainer>
            );
          })}
        {showDesktopButton && <Offset mt={30} />}

        <ButtonDesktop
          show={showDesktopButton}
          isActive={showDesktopButton}
          type={"button"}
          name={"Загрузить еще"}
          addClass={"button__controll-roze--full"}
          onClick={handleShowMore}
        />
      </BlockGrid>
    </WrapContainer>
  );
};

export default MyReviewDesktop;
