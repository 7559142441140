import React from 'react';
import styles from './styles/block.module.scss';
import classNames from 'classnames';

export default function WrapTwoColumnGrid({children, style={}, className, onClick=()=>{}}) {
  return (
    <div
      className={classNames({
        [styles["block__container--two-column"]]: true,
        [className]: !!className
      })}
      style={style}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
