import React from 'react'
import WrapRowGrid from '../Blocks/WrapRowGrid';
import BlockTitle from '../Blocks/BlockTitle';
import WrapTitleDescBlockOpacity from '../Blocks/WrapTitleDescBlockOpacity';

const UserInfoLink = ({ profileInfoData, style={} }) => {
  return (
    <WrapRowGrid style={style}>
      <WrapTitleDescBlockOpacity>
        Ссылка на авито/дром:
      </WrapTitleDescBlockOpacity>
      <BlockTitle style={{ fontWeight: 500, color: "var(--text-color-red)" }}>
        {profileInfoData.avito_prom}
      </BlockTitle>
    </WrapRowGrid>
  );
};

export default UserInfoLink