import React from 'react'
import Menu from '../../../View/Menu/Menu'
import WrapContainer from '../../../View/WrapContainer/WrapContainer'
import Offset from '../../../View/Offset'
import AddLinkMarketPlace from './AddLinkMarketPlace'
import AddFileMarketPlace from './AddFileMarketPlace'

function AddFileMarkerPlaceSection({
  values,
  isMark,
  setData,
  handlerScreen,
  valuesPreview,
  loadingPreviewImage,

  tg,
  testSize,
  dispatch,
  listSubMenu,
  handlerSendData,
  handleGetDataOption,
  handlerDeleteFile,
  handlerDeleteLink,
  handlerChangeBlur,
  handlerChangeFocus,
  percentageAddFileMP,
}) {
  return (
    <>
      <WrapContainer>
        <Offset mb={17} />
        {/* {
          getLocaleStore(MARKET_CURRENT_TAB_ADD_CARDS) === 'file' ? */}
        {!values?.urlMarket && !valuesPreview?.urlMarket ? (
          <AddFileMarketPlace
            tg={tg}
            values={values}
            setData={setData}
            dispatch={dispatch}
            percentage={percentageAddFileMP}
            valuesPreview={valuesPreview}
            handleGetDataOption={handleGetDataOption}
            handlerApplyChange={handlerSendData}
            handlerDeleteFile={handlerDeleteFile}
            loadingPreviewImage={loadingPreviewImage}
          />
        ) : null}

        {!values?.file?.length && !valuesPreview?.file?.length ? (
          <AddLinkMarketPlace
            values={values}
            valuesPreview={valuesPreview}
            handleGetDataOption={handleGetDataOption}
            handlerDeleteLink={handlerDeleteLink}
            handlerChangeBlur={handlerChangeBlur}
            handlerChangeFocus={handlerChangeFocus}
            loadingPreviewImage={loadingPreviewImage}
          />
        ) : null}
      </WrapContainer>
      <Offset mb={50} />
      <WrapContainer>
        <Menu
          list={listSubMenu}
          handlerScreen={handlerScreen}
          isMark={isMark}
          isStartBigSlide
          iconSize={18}
          margin={45}
        />
        <Offset mb={28} />
      </WrapContainer>
    </>
  );
}

export default (AddFileMarkerPlaceSection);