import React, { forwardRef, useEffect, useState } from "react";
import { useStoreon } from "storeon/react";
import Button from "../Button/Button";

const ButtonDesktop = forwardRef(
  ({ show, type, name, onClick, addClass, isActive }, ref) => {
    const { controllButton } = useStoreon("controllButton");
    return (
      <div style={{}}>
        <Button
          style={{
            visibility: show ?? controllButton.show ? "visible" : "hidden",
            opacity: show ?? controllButton.show ? 1 : 0,
          }}
          ref={ref}
          // disabled={false}
          disabled={isActive !== undefined? !isActive : !controllButton.isActive}
          type={type ?? controllButton.type ?? "submit"}
          addClass={
            addClass ??
            controllButton?.addClass ??
            "button__controll-incoming--red--full"
          }
          active={show ?? controllButton.show}
          onClick={() => {
            onClick ? onClick() : controllButton.action();
          }}
        >
          {name ?? controllButton.title}
        </Button>
      </div>
    );
    // }
    // return null;
  }
);

export default ButtonDesktop;