import { useEffect, useRef, useState } from "react";
import ReactSelect from "react-select";
import { getListOption } from "../../../helpers/helpers";
import styles from "./styles/option-select.module.scss";
import "./styles/custom-style-react-select.scss";

const ReactSelectComponent = ({
  pt = 0,
  pb = 0,
  pl = 11,
  pr = 5,
  mt,
  mb,
  ml,
  mr,
  id,
  data = [],
  name = "select",
  onBlur = () => {},
  enabled = true,
  isUpTop = false,
  onChange,
  helptext = "",
  styleWrap={},
  clearValue,
  placeholder,
  stylehelptext = {},
  classNamePrefix = "",
  noOptionsMessage,
  ...props
}) => {
  let [offsetTop, setOffsetTop] = useState(false);
  const selectRef = useRef(null);

  const handlerIsOpenMenu = (status) => {
    if (status) {
      document.documentElement.style.setProperty("--opacity-fone", ".1");
      return setOffsetTop(true);
    }
    document.documentElement.style.setProperty("--opacity-fone", "1");
    setOffsetTop(false);
  };

  useEffect(() => {
    if (clearValue) {
      selectRef.current.setValue({ label: placeholder });
    }
  }, [clearValue]);

  return (
    <>
      <div
        style={{
          ...styleWrap,
        }}
        className={
          offsetTop
            ? styles["option-select__container--offset-top"]
            : styles["option-select__container"]
        }
      >
        <ReactSelect
          classNamePrefix={classNamePrefix}
          options={getListOption(data)}
          hideSelectedOptions={true} // убераем выбраные опции из списка
          isDisabled={!enabled}
          // menuPlacement={"auto"}
          name={name}
          placeholder={placeholder}
          id={id}
          onChange={({ value }) => {
            if (value === undefined) return; // ????????? пулит лишний запрос
            return onChange(value);
          }}
          ref={selectRef}
          onMenuOpen={(e) => isUpTop && handlerIsOpenMenu(true)}
          onMenuClose={(e) => isUpTop && handlerIsOpenMenu(false)}
          noOptionsMessage={() => noOptionsMessage  ?? "Текста с таким названием нет в списке"}
          // unstyled={true}
          // menuShouldBlockScroll={true}
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 9999, // Убедитесь, что вложенные меню могут быть поверх родителя
            }),
          }}
          {...props}
        />
        {helptext ? (
          <div
            style={{
              position: "absolute",
              bottom: -18,
              left: 0,
              ...stylehelptext,
            }}
          >
            {helptext}
          </div>
        ) : null}
      </div>
      {offsetTop && <div style={{ minHeight: 38 }}></div>}
    </>
  );
};

export default ReactSelectComponent;
