import React, { useState } from "react";
import WrapContainer from "../../../View/WrapContainer/WrapContainer";
import Offset from "../../../View/Offset";
import BlockGrid from "../../../View/Blocks/BlockGrid";
import WrapContainerPreloader from "../../../View/Preloaders/WrapContainerPreloader";
import Preloader from "../../../View/Preloaders/Preloader";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  EffectCoverflow,
  Pagination,
  FreeMode,
  Navigation,
  Thumbs,
} from "swiper";
import "swiper/scss";
import "swiper/scss/effect-coverflow";
import "swiper/scss/pagination";
import styles from "./styles/swiper-engine.module.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CardMPTitle from "../../../View/Cards/Detail/CardMPTitle";
import CardMPContainerRow from "../../../View/Cards/Detail/CardMPContainerRow";
import CardMPText from "../../../View/Cards/Detail/CardMPText";
import Icon from "../../../View/Icon/Icon";
import { location, user } from "../../../images";
import CardMPPrice from "../../../View/Cards/Detail/CardMPPrice";
import { getNumberSpace } from "../../../helpers/const";
import WrapContainerFilters from "../../../View/WrapContainer/WrapContainerFilters";
import Button from "../../../View/Button/Button";
import { openURl } from "../../../helpers/helpers";
import CardMPCharacteristic from "../../../View/Cards/Detail/CardMPCharacteristic";
import CardMPCharacteristicSubTitle from "../../../View/Cards/Detail/CardMPCharacteristicSubTitle";
import CardMPDescriptions from "../../../View/Cards/Detail/CardMPDescriptions";
import CardMPDate from "../../../View/Cards/Detail/CardMPDate";

const DetailChineEngineDesktop = ({ card, dispatch }) => {

  if (!card?.title)
    return (
      <WrapContainerPreloader>
        Загрузка ... <Preloader />
      </WrapContainerPreloader>
    );
  return (
    <WrapContainer isDesktop>
      <Offset mt={"var(--offset-top-desktop)"} />
      <BlockGrid addClass="profile-desktop__self-info">
        {/* images */}
        <BlockGrid>
          <Swiper
            pagination={{
              type: "fraction",
            }}
            spaceBetween={50}
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={"auto"}
            touchEventsTarget={"container"}
            // simulateTouch={true}
            // touchRatio={1}
            // touchAngle={45}
            // longSwipes={true}
            longSwipesRatio={0.5}
            followFinger={true}
            allowTouchMove={true}
            threshold={20}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            className={styles["swiper-engine"]}
            // pagination={true}
            navigation={true}

            modules={[
              EffectCoverflow,
              Pagination,
              FreeMode,
              Navigation,
              Thumbs,
            ]}
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
          >
            {(card?.image_urls_http?.length
              ? card?.image_urls_http
              : card?.image_urls?.length
              ? card?.image_urls
              : [
                  {
                    id: 123423142134,
                    type: "image",
                    url: "https://botrazbor.ru/media/new/banners/motor_baner.png",
                  },
                ]
            ).map(({ url }, index) => {
              return (
                <SwiperSlide key={index}>
                  <LazyLoadImage
                    alt={"image.alt"}
                    effect="blur"
                    src={
                      url.slice(0, 5) === "http:"
                        ? `https://razbor-market.ru/api_engines/get_image/?url=${url}`
                        : url
                    }
                    style={{
                      objectFit: "cover",
                      maxHeight: 400,
                      minHeight: 400,
                      width: "100vw",
                    }}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </BlockGrid>
        <Offset mt={"var(--offset-top-desktop)"} />
        {/* description */}
        <BlockGrid>
          {card?.title ? (
            <CardMPTitle
              style={{ fontSize: 18, fontWeight: 900, marginTop: 14 }}
            >
              {card?.title}
            </CardMPTitle>
          ) : null}
          <Offset mb={6} />
          {card?.country || card?.city || card?.address ? (
            <CardMPContainerRow>
              <Icon width={16} height={16} image={location} />
              <CardMPText
                style={{ padding: 3, color: "var(--text-color-opacity)" }}
              >
                {card?.country ? card?.country : ""}{" "}
                {card?.city ? card.city : ""}{" "}
                {card?.address ? card.address : ""}
              </CardMPText>
            </CardMPContainerRow>
          ) : null}
          {card?.price ? (
            <CardMPPrice catalog={true}>
              {getNumberSpace(card.price)} <span>₽</span>{" "}
            </CardMPPrice>
          ) : null}
          
          {!!card.user && (
            <CardMPContainerRow style={{ marginTop: 9 }}>
              <Icon width={16} height={16} image={user} />
              <CardMPText
                style={{ padding: 3, color: "var(--text-color-opacity)" }}
              >
                {card.user_data.user_profile_name}
              </CardMPText>
            </CardMPContainerRow>
          )}

          <WrapContainerFilters
            style={{
              position: "relative",
              marginTop: 14,
              marginBottom: 28,
            }}
          >
            <Button
              addClass={"button__controll--red"}
              style={{
                width: "100%",
                minHeight: 46,
                fontSize: 12,
                fontWeight: 900,
              }}
              onClick={() => {
                openURl(
                  card.user_data.user_url,
                  `Написать ${card.user_data.user_profile_name}`,
                  dispatch,
                  true
                );
              }}
            >
              <p>Написать продавцу</p>
            </Button>
          </WrapContainerFilters>

          <CardMPCharacteristic title={"Характеристики"}>
            {card.count ? (
              <CardMPCharacteristicSubTitle title={"В наличии:"}>
                {card.count}
              </CardMPCharacteristicSubTitle>
            ) : null}
            {card?.oem ? (
              <CardMPCharacteristicSubTitle title={"Номер запчасти:"}>
                {card.oem}
              </CardMPCharacteristicSubTitle>
            ) : null}
            {card?.brand || card?.model || card?.generation ? (
              <CardMPCharacteristicSubTitle title={"Применимость:"}>
                {card?.brand ? card.brand : ""}{" "}
                {card?.model ? ` | ${card.model}` : ""}{" "}
                {card.generation ? ` | ${card.generation}` : ""}
              </CardMPCharacteristicSubTitle>
            ) : null}
          </CardMPCharacteristic>

          {card?.description ? (
            <CardMPDescriptions title={"Описание товара"}>
              {card.description}
            </CardMPDescriptions>
          ) : null}
          <Offset mb={3} />
          {!!card?.date_create && (
            <CardMPCharacteristicSubTitle
              title={"Опубликовано:"}
              style={{
                color: "var(--text-color-opacity)",
                fontSize: 12,
              }}
              styleText={{
                color: "var(--text-color-opacity)",
                fontSize: 12,
              }}
            >
              <CardMPDate>{card.date_create}</CardMPDate>
            </CardMPCharacteristicSubTitle>
          )}
        </BlockGrid>
      </BlockGrid>
    </WrapContainer>
  );
};

export default DetailChineEngineDesktop;
