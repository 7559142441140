import React from "react";
import { RouterProvider } from "react-router-dom";
import { router, routersDesktop } from "../routers/routers";
import { useEffect } from "react";
import { useState } from "react";

function WithResize() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    document.documentElement.style.setProperty("--max-width-mobile", window.innerWidth > 400? '400px' : window.innerWidth -10 + 'px');
    const handleResize = () => {
      document.documentElement.style.setProperty("--max-width-mobile", window.innerWidth > 400? '400px' : window.innerWidth -10  + 'px' );
      setIsMobile(window.innerWidth < 1200);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile ? (
    <RouterProvider router={router} />
  ) : (
    <RouterProvider router={routersDesktop} />
  );
}

export default WithResize;
