import React from 'react';
import styles from './styles/block.module.scss';
import classNames from 'classnames';

export default function WrapRowGrid({children, className, style={}}) {
  return (
    <div
      className={classNames({[styles['block__container--row-grid']]:true, [className]:!!className})}
      style={style}
    >{children}</div>
  )
}
