import React, { Component } from 'react'
import CatalogchainMotors from './CatalogchainMotors'
import { ROOT } from '../../helpers/config';
import { connectStoreon } from 'storeon/react';
import WithRouter from '../../HOC/WithRouter';
import { ACTION_GET_LIST_MOTORS_CARS, ACTION_GET_LIST_MOTORS_CARS_SHOW_MORE } from '../../store/chain-motors/chain-motors';
import ChineEngineDesktop from '../../PagesDesktop/ChineEngine/ChineEngineDesktop';

export class CatalogchainMotorsContainer extends Component {
  state = {
    showDesktopButton: false,
  };
  componentDidMount() {
    this.props.dispatch(ACTION_GET_LIST_MOTORS_CARS);
    this.props.controllerHeaderBand({
      currentTextHandlerBand: "Китайские двигатели",
      pathBackButton: () => this.handleChangeScreen({ path: ROOT }),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dataMotorsChain !== this.props.dataMotorsChain) {
      if (
        this.props.dataMotorsChain?.results?.length && (this.props.dataMotorsChain?.current_page ===
        this.props.dataMotorsChain?.total_pages)
      ) {
        this.setState((state) => ({
          ...state,
          showDesktopButton: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          showDesktopButton: true,
        }));
      }
    }
  }
  handleChangeScreen = ({ path }) => {
    this.props.navigate(path);
  };
  changePagination = ({ page }) => {
    this.props.dispatch(ACTION_GET_LIST_MOTORS_CARS, { page });
  };
  handleShowMore = () => {
    return this.props.dispatch(ACTION_GET_LIST_MOTORS_CARS_SHOW_MORE, {
      page: this.props.dataMotorsChain.current_page + 1,
    });
  };
  render() {
    if (this.props.isDesktop) {
      return (
        <ChineEngineDesktop
          handleShowMore={this.handleShowMore}
          showDesktopButton={this.state.showDesktopButton}
          dataCards={this.props.dataMotorsChain}
          changePagination={this.changePagination}
        />
      );
    }
    return (
      <CatalogchainMotors
        dataCards={this.props.dataMotorsChain}
        changePagination={this.changePagination}
      />
    );
  }
}

export default connectStoreon( 
    'dataMotorsChain',

    WithRouter(CatalogchainMotorsContainer)
)