import React from "react";
import BlockFlex from "../../../View/Blocks/BlockFlex";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import HeaderButtonContainer from "../../../PagesDesktop/Header/HeaderButton/HeaderButtonContainer";

const MenuDesktop = ({ list, handlerClickMenu, buttonsSidebar }) => {
  return (
    <BlockFlex addClass="block__menu-desktop-container">
      {list &&
        list.map((item, index) => {
          return (
            <Link
              to={item.slug}
              key={item.id}
              onClick={() => handlerClickMenu(item.id)}
            >
              <BlockFlex
                addClass={
                  item.isActive
                    ? "block__menu-desktop-item--active"
                    : "block__menu-desktop-item"
                }
              >
                <ReactSVG
                  beforeInjection={(svg) => {
                    const element = svg.querySelectorAll("path");
                    element.forEach((path) => {
                      path.style.fill = item.isActive
                        ? "var(--text-color-red)"
                        : "var(--text-color)";
                    });
                  }}
                  src={item.image}
                  width={20}
                  height={20}
                />
                <span>{item.name}</span>
              </BlockFlex>
            </Link>
          );
        })}
      <BlockFlex addClass="block__menu-desktop-sidebar-button">
        <HeaderButtonContainer buttonsHeader={buttonsSidebar} />
      </BlockFlex>
    </BlockFlex>
  );
};

export default MenuDesktop;
