import React from "react";
import WrapContainer from "../../View/WrapContainer/WrapContainer";
import Offset from "../../View/Offset";
import BlockGrid from "../../View/Blocks/BlockGrid";
import MainFeedbackComponent from "../../Pages/FeedbackPage/FeedbackPage";
import { linksFeedback, ONE_CARD_CHAIN_MOTOR } from "../../helpers/config";
import CardMPTitle from "../../View/Cards/Detail/CardMPTitle";
import CardMPPrice from "../../View/Cards/Detail/CardMPPrice";
import { getNumberSpace } from "../../helpers/const";
import { Link } from "react-router-dom";
import ImageGalaryMP from "../../View/Cards/Detail/ImageGalaryMP";
import CardMPContainer from "../../View/Cards/Detail/CardMPContainer";
import Label from "../../View/Label/Label";
import WrapContainerPreloader from "../../View/Preloaders/WrapContainerPreloader";
import Preloader from "../../View/Preloaders/Preloader";
import ButtonDesktop from "../../View/ButtonDesktop/ButtonDesktop";

const ChineEngineDesktop = ({ dataCards, showDesktopButton, handleShowMore }) => {
  if (!dataCards?.results && !dataCards?.results?.length)
    return (
      <WrapContainerPreloader>
        Загрузка ... <Preloader />
      </WrapContainerPreloader>
    );
  return (
    <WrapContainer isDesktop>
      <Offset mt={"var(--offset-top-desktop)"} />
      <BlockGrid addClass="profile-desktop__self-info">
        <BlockGrid
          style={{
            gridTemplateColumns: "repeat(5,1fr)",
            gap: 10,
          }}
        >
          {dataCards.results.map((item) => {
            return (
              <CardMPContainer style={{}}>
                <ImageGalaryMP
                  urlGoToPath={ONE_CARD_CHAIN_MOTOR}
                  own
                  item={item}
                  catalog
                  // hendlerFavorite={hendlerFavorite}
                />

                <Link
                  to={ONE_CARD_CHAIN_MOTOR}
                  state={{ card: item }}
                  style={{
                    zIndex: "var(--z-index-element-link)",
                    // marginTop: 11
                  }}
                >
                  <Offset mb={14} />
                  {item?.title && (
                    <CardMPTitle
                      style={{
                        minHeight: 37, //60
                        maxWidth: `calc((100vw - 110px) / 2)`,
                        display: `-webkit-box`,
                        WebkitLineClamp: `2`,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                      }}
                    >
                      {item?.title}
                    </CardMPTitle>
                  )}
                  <Offset mb={2} />

                  {item?.count && (
                    <Label>
                      {"В наличии "} {item.count}
                    </Label>
                  )}
                  {
                    item?.price ? (
                      <CardMPPrice catalog>
                        {getNumberSpace(item.price)} <span>₽</span>{" "}
                      </CardMPPrice>
                    ) : (
                      <Offset mb={27} />
                    )
                    // <CardMPPrice style={{ minHeight: 25 }}></CardMPPrice>
                  }
                </Link>
              </CardMPContainer>
            );
          })}
        </BlockGrid>
          {showDesktopButton && <Offset mt={30} />}

          <ButtonDesktop
            show={showDesktopButton}
            isActive={showDesktopButton}
            type={"button"}
            name={"Загрузить еще"}
            addClass={"button__controll-roze--full"}
            onClick={handleShowMore}
          />
      </BlockGrid>
      <MainFeedbackComponent list={linksFeedback} />
    </WrapContainer>
  );
};

export default ChineEngineDesktop;
