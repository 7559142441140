import React, { PureComponent } from 'react'
import { connectStoreon } from 'storeon/react'
import Subscribe from './Subscribe';
import WithRouter from '../../../HOC/WithRouter';
import { LASTURL, PROFILE_MENU } from '../../../helpers/config';
import { ACTION_CHANGE_DATA_SUBSCRIBE, ACTION_GET_DATA_PROFILE, ACTION_SET_CONTROLL_BUTTON_FOR_SUBSCRIBE, ACTION_SET_DATA_FOR_SUBSCRIBE } from '../../../store/profile/profile';
import { setLocaleStore } from '../../../helpers/utils';
import SubscribeDesktop from '../../../PagesDesktop/Profile/Subscribe/SubscribeDesktop';

class SubscribeContainer extends PureComponent {
  state = {
    headerTitleSelectAccess: ["Выберите доступ"],
    headerTitlePeriodSubscribe: ["Срок подписки"],
    headerTitleMethodPayment: ["Способ оплаты"],
    headerTitleMethodCheck: ["Укажите номер телефона или почту"],
    isLoading: false,
    listDescriptionAccessPaymentCurrent: [],
    headerTitleInfoAboutProduct: ["Информация о подписке"],
    listScreen: this.props.listDescriptionAccessPayment,
  };

  componentDidMount() {
    this.props.dispatch(ACTION_GET_DATA_PROFILE);
    this.props.controllerHeaderBand({
      currentTextHandlerBand: "Подписка",
      pathBackButton: PROFILE_MENU,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.listDescriptionAccessPayment !==
      this.props.listDescriptionAccessPayment
    ) {
      this.setState((state) => ({
        ...state,
        // listDescriptionAccessPaymentCurrent: this.props.listDescriptionAccessPayment.full_access?.info.description,
        listScreen: this.props.listDescriptionAccessPayment,
      }));
    }
  }

  handlerChangeSection = (e, type) => {
    e.preventDefault();

    this.setState((state) => ({
      ...state,
      listScreen: this.state.listScreen.map((el) =>
        type === el.type ? { ...el, active: true } : { ...el, active: false }
      ),
    }));
    this.handlerSetData({ key: "access", value: type });
  };

  handleChangeScreen = () => this.props.navigate(PROFILE_MENU);

  callback = () => {
    setLocaleStore(LASTURL, PROFILE_MENU);
  };
  handlerApplyPayment = (event) => {
    event.preventDefault();
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    this.props.dispatch(ACTION_SET_CONTROLL_BUTTON_FOR_SUBSCRIBE, false);
    this.props.dispatch(ACTION_CHANGE_DATA_SUBSCRIBE, {
      callback: this.callback,
    });
    setTimeout(()=>{
this.setState((state) => ({
      ...state,
      isLoading: false,
    }));
    },1000)
  };

  handlerSetData = ({ key, value }) =>
    this.props.dispatch(ACTION_SET_DATA_FOR_SUBSCRIBE, { [key]: value });

  render() {
    if (this.props.isDesktop) {
      return (
        <SubscribeDesktop
          isLoading={this.state.isLoading}
          listScreen={this.state.listScreen}
          showDiscount={this.props.showDiscount}
          dataSubscribe={this.props.dataSubscribe}
          handlerSetData={this.handlerSetData}
          listOptionsPayment={this.props.listOptionsPayment}
          handlerApplyPayment={this.handlerApplyPayment}
          handleChangeScreen={this.handleChangeScreen}
          handlerChangeSection={this.handlerChangeSection}
          headerTitleMethodCheck={this.state.headerTitleMethodCheck}
          handlerChangeSubscribe={this.handlerChangeSubscribe}
          headerTitleSelectAccess={this.state.headerTitleSelectAccess}
          controllButtonSubscribe={this.props.controllButtonSubscribe}
          headerTitleMethodPayment={this.state.headerTitleMethodPayment}
          headerTitlePeriodSubscribe={this.state.headerTitlePeriodSubscribe}
          headerTitleInfoAboutProduct={this.state.headerTitleInfoAboutProduct}
          listDescriptionAccessPaymentCurrent={
            this.state.listDescriptionAccessPaymentCurrent
          }
        />
      );
    }
    return (
      <Subscribe
        isLoading={this.state.isLoading}
        listScreen={this.state.listScreen}
        showDiscount={this.props.showDiscount}
        dataSubscribe={this.props.dataSubscribe}
        handlerSetData={this.handlerSetData}
        listOptionsPayment={this.props.listOptionsPayment}
        handlerApplyPayment={this.handlerApplyPayment}
        handleChangeScreen={this.handleChangeScreen}
        handlerChangeSection={this.handlerChangeSection}
        headerTitleMethodCheck={this.state.headerTitleMethodCheck}
        handlerChangeSubscribe={this.handlerChangeSubscribe}
        headerTitleSelectAccess={this.state.headerTitleSelectAccess}
        controllButtonSubscribe={this.props.controllButtonSubscribe}
        headerTitleMethodPayment={this.state.headerTitleMethodPayment}
        headerTitlePeriodSubscribe={this.state.headerTitlePeriodSubscribe}
        headerTitleInfoAboutProduct={this.state.headerTitleInfoAboutProduct}
        listDescriptionAccessPaymentCurrent={
          this.state.listDescriptionAccessPaymentCurrent
        }
      />
    );
  }
}

export default connectStoreon(
  "showDiscount",
  "dataSubscribe",
  "listOptionsPayment",
  "controllButtonSubscribe",
  "listDescriptionAccessPayment",

  WithRouter(SubscribeContainer)
);